import {
    Grid,
    Button,
    TextField,
    Typography,
    Box,
    InputLabel,
    Select,
    MenuItem,
    TableContainer,
    Table,
    IconButton,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Collapse,
    Tooltip,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    CardContent,
    FormControl,
    Paper,
    Autocomplete
} from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
// import BG from '../../../assets/BW TECNOLOGIA.png';
import BG from '../../../assets/images/Backgrounds/loginBG.jpg';
import JGFLogo from '../../../assets/jgf-logo.png';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import SkeletonSimulator from 'ui-component/SkeletonSimulator';
import { useParams } from 'react-router';

const BudgetEdit = ({ open, setOpen, formData, getBudget, row, handleDelete }) => {
    const getEditBudget = async () => {
        try {
            const { data } = await api.getEditBudgetClient(row.codgo);

            setPreviousAnswer(data);
        } catch (error) {
            console.log('🚀 ~ getEditBudget ~ error:', error);
        }
    };

    useEffect(() => {
        getEditBudget();
    }, [open]);

    const { id } = useParams();
    const api = useApi();
    const [previousAnswers, setPreviousAnswer] = useState([]);
    const [formOpen, setFormOpen] = useState(false);
    const [selectedQuiz, setSelectedQuiz] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [allGroup, setAllGroup] = useState([]);
    const [allQuiz, setAllQuiz] = useState([]);
    const [respostasSelecionadas, setRespostasSelecionadas] = useState([]);
    const [perguntaAtual, setPerguntaAtual] = useState(0);
    const [mostrarErro, setMostrarErro] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState([]);
    const [currentAnswer, setCurrentAnswer] = useState([]);
    const [allBudgets, setAllBudgets] = useState([]);
    const [nome, setNome] = useState(row?.budget_Name);
    const [error, setError] = useState(false);

    const [newCurrentQuiz, setNewCurrentQuiz] = useState(null);

    //image
    const [imageUrl, setImageUrl] = useState('');
    const [stepImage, setStepImage] = useState(0);

    const [preOrc, setPreOrc] = useState([]);
    const [saveModel, setSaveModel] = useState();

    const [selectedBudget, setSelectedBudget] = useState(null);

    // const getAllBudgets = async () => {
    //     const response = await api.getAllBudgets();
    //     setAllBudgets(response.data);
    // };

    const getAllAnswers = async (quiz) => {
        try {
            const { data } = await api.getQuizById(row.quizId);
            const newArrayAnswer = data?.questionPosition.map((item) => ({
                answers: item?.question?.answers,
                question: item.question,
                imageUrl: item.imageUrl,
                openQuestionRestriction: item.openQuestionRestriction
                    ? {
                          description: item.openQuestionRestriction?.description,
                          min: item.openQuestionRestriction?.minimumValue,
                          max: item.openQuestionRestriction?.maximumValue
                      }
                    : {}
            }));

            setAnswers(newArrayAnswer);
        } catch (error) {
            console.log('🚀 ~ getAllAnswers ~ error:', error);
        }
    };

    useEffect(() => {
        if (selectedQuiz) {
            getAllAnswers(selectedQuiz);
        }
    }, [selectedQuiz, open]);

    const [openResponse, setOpenResponse] = useState(null);

    const handleRespostaSelecionada = (pergunta, resposta) => {
        setOpenResponse(resposta);

        let respostaObjeto;
        setCurrentQuestion(pergunta);
        setCurrentAnswer(resposta);
        if (pergunta.type === 'Aberta') {
            respostaObjeto = {
                question: pergunta,
                answer: {
                    value: resposta !== '' ? parseFloat(resposta) : null,
                    product: null
                },
                quizId: newCurrentQuiz.quizId
            };
        } else {
            respostaObjeto = {
                question: pergunta,
                answer: resposta,
                quizId: newCurrentQuiz.quizId
            };
        }

        setRespostasSelecionadas((prevRespostasSelecionadas) => ({
            ...prevRespostasSelecionadas,
            [pergunta.id]: respostaObjeto
        }));
    };

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const handleAvancar = async () => {
        setIsButtonDisabled(true);
        setTimeout(() => {
            setIsButtonDisabled(false);
        }, 500);

        const respostaAtual = respostasSelecionadas[answers[perguntaAtual].question.id];
        if (respostaAtual === undefined) {
            setMostrarErro(true);
            return;
        }

        if (
            openResponse < answers[perguntaAtual]?.openQuestionRestriction?.min ||
            openResponse > answers[perguntaAtual]?.openQuestionRestriction?.max
        ) {
            toast.warn(
                `Selecione um valor entre ${answers[perguntaAtual]?.openQuestionRestriction?.min} e ${answers[perguntaAtual]?.openQuestionRestriction?.max}`
            );
            return;
        }

        // ==================== LÓGICA PARA VERIFICAR A PRÓXIMA PERGUNTA ====================
        const resp = respostaAtual?.question?.id;
        const ListaPerguntas = answers;
        let proximoItem = null;
        let indexAtual = 0;
        for (let index = 0; index < ListaPerguntas.length; index++) {
            const item = ListaPerguntas[index];
            if (item?.question?.id === resp) {
                proximoItem = ListaPerguntas[index + 1];
                indexAtual = index + 1;
                break;
            }
        }

        //=================== Payload ===================================================
        const payload = Object.keys(respostasSelecionadas).map((key) => {
            const objt = respostasSelecionadas[key];

            const newObjt = {
                answer: { id: objt.answer.id },
                question: { id: objt.question.id }
            };

            return newObjt;
        });

        // ==================== LÓGICA PARA VERIFICAR SE POSSUI BODY FORMULA ====================
        // ==================== E PEGAR AS RESPOSTAS ====================

        if (proximoItem && proximoItem?.question?.formula) {
            try {
                const res = await api.checkformula(proximoItem.question.id, newCurrentQuiz.id, payload);

                if (res) {
                    const updatedAnswers = answers.map((item, index) => {
                        if (index === indexAtual) {
                            return {
                                ...item,
                                answers: res.data
                            };
                        }
                        return item;
                    });
                    setAnswers(updatedAnswers);
                }
            } catch (error) {
                console.log(error.message);
            }
        }
        setStepImage((prev) => prev + 1);
        setPerguntaAtual((prevPergunta) => prevPergunta + 1);
        setRespostasSelecionadas((prevRespostasSelecionadas) => ({
            ...prevRespostasSelecionadas,
            [answers[perguntaAtual].question.id]: respostaAtual
        }));
        setMostrarErro(false);
    };

    const handleVoltar = () => {
        if (perguntaAtual === 0) {
            setSelectedQuiz(null);
            setNome(row?.budget_Name);
            handleQuizChange();
        } else {
            const prevQuestionIndex = perguntaAtual - 1;
            const prevQuestion = answers[prevQuestionIndex].question;
            setPerguntaAtual(prevQuestionIndex);
            setStepImage((prev) => prev - 1);
        }
    };

    // loading skeleton
    const [loading, setLoading] = useState(false);
    const [currentQuizMain, setCurrentQuizMain] = useState(null);

    const handleQuizChange = async (e) => {
        const selectedOption = e.target.value;
        setCurrentQuizMain(selectedOption);
        setLoading(true);

        try {
            const { data } = await api.getQuizByIdV2(selectedOption.id);
            setSelectedQuiz(data);
            setNome(row?.budget_Name);
        } catch (error) {
            console.log('🚀 ~ file: index.js:262 ~ handleQuizChange ~ error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setSelectedQuiz([]);
        setAllGroup([]);
        setAllQuiz([]);
        setSelectedGroup([]);
        setRespostasSelecionadas([]);
        setPerguntaAtual(0);
        setCurrentQuestion([]);
        setAnswers([]);
        setMostrarErro(false);
        // setAllBudgets([]);
        setCurrentAnswer([]);
        setStepImage(0);
        setPreOrc([]);
        setOpen(false);
    };

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        const payloadForBudget = Object.keys(respostasSelecionadas).map((key) => {
            const objt = respostasSelecionadas[key];
            const newObjt = {
                answer: objt.answer,
                question: objt.question
            };

            return newObjt;
        });

        //manipulando storage
        const newArrayWithNames = payloadForBudget.map((obj) => {
            // Criar uma cópia do objeto atual
            const newObj = { ...obj };
            // Adicionar o campo "nome" ao objeto
            newObj.nome = nome; // Substitua 'Nome Exemplo' pelo valor desejado
            // Retornar o novo objeto
            return newObj;
        });
        const newQuestions = JSON.stringify(newArrayWithNames);
        localStorage.setItem('questions', newQuestions);

        const respostaAtual = respostasSelecionadas[answers[perguntaAtual].question.id];
        if (respostaAtual === undefined) {
            setMostrarErro(true);
            return;
        }

        if (
            openResponse < answers[perguntaAtual]?.openQuestionRestriction?.min ||
            openResponse > answers[perguntaAtual]?.openQuestionRestriction?.max
        ) {
            toast.warn(
                `Selecione um valor entre ${answers[perguntaAtual]?.openQuestionRestriction?.min} e ${answers[perguntaAtual]?.openQuestionRestriction?.max}`
            );
            return;
        }

        //   Verificando se todas as perguntas foram respondidas

        try {
            const response = await api.createBudgets(
                selectedQuiz,
                payloadForBudget.map((resposta) => ({
                    question: resposta.question,
                    answer: {
                        ...resposta.answer,
                        value: resposta.answer.value !== '' ? parseFloat(resposta.answer.value) : null
                    }
                }))
            );

            handleDelete();
            setPreOrc(response.data.materials);
            setSaveModel(response.data);

            await api.calcRo(selectedQuiz, formData.id, {
                answerQuizModels: payloadForBudget.map((resposta) => ({
                    question: resposta.question,
                    answer: {
                        ...resposta.answer,
                        value: resposta.answer.value !== '' ? parseFloat(resposta.answer.value) : null
                    }
                })),
                budgetsModel: { ...response.data, note: row.note ? row.note : '' }
            });

            getBudget();
            handleClose();
            setLoading(false);
        } catch (error) {
            console.log(error);
            if (error?.response?.status === 400) {
                setOpen(false);
                setLoading(false);
                return toast.error(error?.response?.data?.message);
            }
            if (error?.response?.status !== 400) {
                setOpen(false);
                setLoading(false);
                return toast.error('Um erro inesperado aconteceu, verifique o r.o');
            }
        }
    };

    const findCurrentQuiz = async () => {
        try {
            const { data } = await api.getAllQuiz();

            const findCurrent = data.find((item) => item?.product?.productName === row?.budget_Name);
            setNewCurrentQuiz(findCurrent);
        } catch (error) {
            console.log('🚀 ~ findCurrentQuiz ~ error:', error);
        }
    };

    useEffect(() => {
        setNome(row?.budget_Name);
        // setPreviousAnswer(row?.answersQuiz);
        findCurrentQuiz();
    }, [row]);

    useEffect(() => {
        setSelectedQuiz(newCurrentQuiz?.id);
    }, [newCurrentQuiz]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
            <>
                <Grid
                    item
                    sm={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        border: '1px solid white',
                        borderRadius: '30px',
                        maxHeight: '900px',
                        padding: '20px',
                        backgroundColor: 'white',
                        gap: '40px'
                    }}
                >
                    <div style={{ display: 'flex', alignContent: 'center', flexDirection: 'row-reverse' }}>
                        {!loading && (
                            <CardContent>
                                <img
                                    src={answers[stepImage]?.imageUrl ? answers[stepImage]?.imageUrl : JGFLogo}
                                    alt="Imagem 1"
                                    style={{
                                        minWidth: '300px',
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                        margin: '10px',
                                        border: '1px solid white',
                                        borderRadius: '30px',
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)'
                                    }}
                                />
                            </CardContent>
                        )}

                        <form
                            onSubmit={handleSubmit}
                            style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center' }}
                        >
                            {loading ? (
                                <SkeletonSimulator />
                            ) : (
                                <>
                                    {nome !== '' && (
                                        <Typography
                                            sx={{
                                                fontSize: '20px',
                                                fontWeight: 'bold',
                                                color: '#2F80ED',
                                                borderRadius: '10px',
                                                padding: '10px',
                                                marginTop: '10px'
                                            }}
                                        >
                                            {nome}
                                        </Typography>
                                    )}

                                    {answers.length > 0 && (
                                        <FormControl sx={{ width: '400px', mb: '15px' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <Typography variant="subtitle1">
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: '8px',
                                                            width: 'auto'
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                marginTop: '10px',
                                                                fontWeight: 'bold',
                                                                color: '#333333',
                                                                textAlign: 'center'
                                                            }}
                                                        >
                                                            {answers[perguntaAtual]?.question?.description}
                                                        </span>
                                                        <span style={{ textAlign: 'center', color: '#FF6666' }}>
                                                            {answers[perguntaAtual]?.openQuestionRestriction?.description || ''}
                                                        </span>
                                                    </div>
                                                </Typography>
                                                {answers[perguntaAtual].question.type === 'Aberta' ? (
                                                    <TextField
                                                        type="number"
                                                        placeholder="Digite sua resposta"
                                                        error={mostrarErro}
                                                        value={
                                                            respostasSelecionadas[answers[perguntaAtual].question.id]?.answer?.value || ''
                                                        }
                                                        helperText={mostrarErro ? 'Campo obrigatório' : ''}
                                                        onChange={(e) => {
                                                            setMostrarErro(false);
                                                            handleRespostaSelecionada(
                                                                answers[perguntaAtual].question,
                                                                e.target.value !== '' ? e.target.value : undefined,
                                                                answers[perguntaAtual]
                                                            );
                                                        }}
                                                        sx={{ marginTop: '8px', width: '300px' }}
                                                    />
                                                ) : (
                                                    <Select
                                                        sx={{ minWidth: '300px', justifyContent: 'center' }}
                                                        value={respostasSelecionadas[answers[perguntaAtual].question.id]?.answer?.id || ''} // Usando o ID da resposta selecionada
                                                        onChange={(event) => {
                                                            setMostrarErro(false);
                                                            const newValue = answers[perguntaAtual].answers.find(
                                                                (item) => item.id === event.target.value
                                                            );
                                                            handleRespostaSelecionada(
                                                                answers[perguntaAtual].question,
                                                                newValue ? newValue : undefined
                                                            );
                                                        }}
                                                    >
                                                        {answers[perguntaAtual].answers.map((item) => (
                                                            <MenuItem key={item.id} value={item.id}>
                                                                {item.public_description ? item.public_description : item.publicDescription}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    marginTop: '70px',
                                                    padding: '0 30px'
                                                }}
                                            >
                                                {perguntaAtual > 0 && (
                                                    <Button type="button" variant="contained" onClick={handleVoltar}>
                                                        Voltar
                                                    </Button>
                                                )}

                                                {perguntaAtual === answers.length - 1 ? (
                                                    <Button type="button" variant="contained" onClick={handleSubmit}>
                                                        Enviar
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        type="button"
                                                        variant="contained"
                                                        disabled={isButtonDisabled}
                                                        onClick={handleAvancar}
                                                    >
                                                        Avançar
                                                    </Button>
                                                )}
                                            </Box>
                                        </FormControl>
                                    )}
                                </>
                            )}
                        </form>
                    </div>
                    <div>
                        <h3 style={{ textAlign: 'center' }}>Consulta de perguntas e respostas anteriores</h3>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Perguntas</TableCell>
                                        <TableCell align="center">Respostas</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {previousAnswers?.answerFromQuiz?.map((row, index) => (
                                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" align="center" scope="row">
                                                {row.question.description}
                                            </TableCell>
                                            <TableCell align="center">
                                                {row?.answer?.public_description ? row?.answer?.public_description : row?.answer?.value}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Grid>
            </>
        </Dialog>
    );
};

export default BudgetEdit;
