import budget from './budget';
import records from './records';
import commercial from './commercial';

const roleMenus = {
    Administrador: [records, budget, commercial],
    Representante: [budget, commercial],
    Vendedor: [budget],
    Manutencao: [commercial],
    'Gestor Comercial': [budget, commercial]
};

const role = localStorage.getItem('role');
const roleParse = JSON.parse(role);
console.log('🚀 ~ file: index.js:10 ~ roleParse:', roleParse);

const menuItems = { items: roleMenus[roleParse] || [] };

export default menuItems;
