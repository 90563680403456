import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import AuthWrapper1 from '../AuthWrapper1';
import AuthCardWrapper from '../AuthCardWrapper';
import AuthLogin from '../auth-forms/AuthLogin';
import Logo from 'ui-component/Logo';
import Footer from 'layout/MainLayout/Footer';

import LoginBG from '../../../../assets/images/Backgrounds/loginBG.jpg';
import BackgroundCar from '../../../../assets/backgroun-car.jpg';

// ================================|| AUTH3 - LOGIN ||================================ //

const Login = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid container direction="column" justifyContent="center" sx={{ height: '100%' }}>
            <Grid sx={{ display: 'flex' }}>
                <Grid item xs={false} lg={4} sm={6}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 16px)' }}>
                        <Grid item>
                            <AuthCardWrapper sx={{ border: 'none' }}>
                                <AuthCardWrapper sx={{ border: 'none' }}>
                                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                                        <Grid item>
                                            <Logo width="250" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                direction={matchDownSM ? 'column-reverse' : 'row'}
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item>
                                                    <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                        <Typography color="primary" gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
                                                            Bem vindo de Volta
                                                        </Typography>
                                                        <Typography
                                                            variant="caption"
                                                            fontSize="16px"
                                                            textAlign={matchDownSM ? 'center' : 'inherit'}
                                                        >
                                                            Entre com suas credenciais para continuar
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AuthLogin />
                                        </Grid>
                                    </Grid>
                                </AuthCardWrapper>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    xs={8}
                    sx={{
                        width: '100%',
                        backgroundImage: 'url(' + BackgroundCar + ')',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                ></Grid>
            </Grid>

            <Footer />
        </Grid>
    );
};

export default Login;
