import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MenuTable from './MenuTable';

function RowComponent({ row, getBudgetsClients }) {
    console.log('🚀 ~ RowComponent ~ row:', row);
    const [open, setOpen] = React.useState(false);
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    {row?.children?.length > 0 && (
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    )}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row?.id}
                </TableCell>
                <TableCell align="center">{row?.vehicle}</TableCell>
                <TableCell align="center">{row?.color}</TableCell>
                <TableCell align="center">{row?.plate}</TableCell>
                <TableCell align="center">{row?.manualClient}</TableCell>
                <TableCell align="center">{row?.cellPhone}</TableCell>
                <TableCell align="center">
                    <div>
                        <MenuTable getBudgetsClients={getBudgetsClients} rowData={row} />
                    </div>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: '#eee'
                            }}
                        >
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Revisão</TableCell>
                                        <TableCell align="center">Emissão</TableCell>
                                        <TableCell align="center">CNPJ</TableCell>
                                        <TableCell align="center">Razão Social</TableCell>
                                        <TableCell align="center">Cliente</TableCell>
                                        <TableCell align="center">Telefone</TableCell>
                                        <TableCell align="center">Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row?.children?.map((historyRow, index) => (
                                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={historyRow?.id}>
                                            {/* <TableCell component="th" scope="row">
                                                {historyRow?.id}
                                            </TableCell> */}
                                            <TableCell align="center">{`${row?.id}.${index + 1}`}</TableCell>
                                            <TableCell align="center">{row?.vehicle}</TableCell>
                                            <TableCell align="center">{row?.color}</TableCell>
                                            <TableCell align="center">{row?.plate}</TableCell>
                                            <TableCell align="center">{row?.manualClient}</TableCell>
                                            <TableCell align="center">{row?.cellPhone}</TableCell>
                                            <TableCell align="center">
                                                <div>
                                                    <MenuTable getBudgetsClients={getBudgetsClients} rowData={historyRow} index={index} />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function CollapsibleTable({ rows, getBudgetsClients }) {
    const filteredItems = rows?.filter((item) => !item.hasOwnProperty('review_of'));

    const rowsWithChildren = filteredItems.map((item) => {
        const children = rows.filter((child) => child.review_of === item.id).reverse();
        return { ...item, children };
    });

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Orçamento</TableCell>
                        <TableCell align="center">Veiculo</TableCell>
                        <TableCell align="center">Cor</TableCell>
                        <TableCell align="center">Placa</TableCell>
                        <TableCell align="center">Cliente</TableCell>
                        <TableCell align="center">Telefone</TableCell>
                        <TableCell align="center">Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rowsWithChildren.map((row) => (
                        <RowComponent key={row.id} row={row} getBudgetsClients={getBudgetsClients} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
