import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
    useMediaQuery
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';
import InputMask from 'react-input-mask';
// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

const EditUserForm = ({ id, onClose, get }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [showPassword, setShowPassword] = useState(false);
    const [checked, setChecked] = useState(true);
    const [seller, setSeller] = useState([]);

    const [strength, setStrength] = useState(0);
    const [level, setLevel] = useState();

    const [data, setData] = useState({});

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };

    const api = useApi();

    const handleUpdateUser = async (values) => {
        const toastId = toast.loading('Atualizando usuário...');
        try {
            await api.updateUser(id, values);
            toast.update(toastId, {
                render: 'Usuário atualizado com sucesso!',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            onClose();
            get();
        } catch (error) {
            console.log('🚀 ~ handleUpdateUser ~ error:', error);
            toast.update(toastId, {
                render: 'Erro ao atualizar usuário!',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    };

    const getUserById = async () => {
        const response = await api.getUserById(id);
        setData(response.data);
        console.log('🚀 ~ file: EditUserForm.js:72 ~ getUserById ~ response.data:', response.data);
        return response;
    };

    const getSeller = async () => {
        const response = await api.getAllUsers();
        const seller = response?.data?.filter((item) => item.role === 'Vendedor');
        setSeller(seller);
    };

    useEffect(() => {
        changePassword('123456');
        getUserById();
        getSeller();
    }, []);

    return (
        <>
            {data.name !== undefined && (
                <Formik
                    initialValues={{
                        name: data?.name,
                        login: data?.login,
                        email: data?.email,
                        role: data?.role,
                        phone: data?.phone,
                        password: data?.password,
                        submit: null,
                        seller: data?.user?.id,
                        commission: data?.commission
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email('tem que ser um email valido').max(255).required('Email obrigatorio'),
                        password: Yup.string().max(255).required('Senha obrigatoria')
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            // logica para editar aqui
                            if (scriptedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);
                            }
                            handleUpdateUser(values);
                        } catch (err) {
                            console.error(err);
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={matchDownSM ? 0 : 2}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                        <InputLabel htmlFor="outlined-adornment-name-register">Nome</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-name-register"
                                            type="text"
                                            value={values.name}
                                            name="name"
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                        <InputLabel htmlFor="outlined-adornment-login-register">Login</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-login-register"
                                            type="text"
                                            value={values.login}
                                            name="login"
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl fullWidth error={Boolean(touched.phone && errors.phone)} sx={{ ...theme.typography.customInput }}>
                                <InputLabel htmlFor="outlined-adornment-phone-register">Celular</InputLabel>
                                <InputMask mask="(99) 99999-9999" value={values.phone} onBlur={handleBlur} onChange={handleChange}>
                                    {(inputProps) => (
                                        <OutlinedInput
                                            id="outlined-adornment-phone-register"
                                            type="text"
                                            name="phone"
                                            inputProps={inputProps}
                                        />
                                    )}
                                </InputMask>
                                {touched.phone && errors.phone && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.phone}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                <InputLabel htmlFor="outlined-adornment-role-register">Tipo de usuário</InputLabel>
                                <Select
                                    style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                    id="outlined-adornment-role-register"
                                    type="text"
                                    value={values.role}
                                    name="role"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={'Administrador'}>Administrador</MenuItem>
                                    <MenuItem value={'Vendedor'}>Vendedor</MenuItem>
                                </Select>
                            </FormControl>
                            {values?.role === 'Representante' && (
                                <div style={{ display: 'flex', gap: '15px' }}>
                                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                        <InputLabel htmlFor="outlined-adornment-role2-register">Vincule um vendedor</InputLabel>
                                        <Select
                                            style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                            id="outlined-adornment-role2-register"
                                            type="text"
                                            value={values.seller}
                                            name="seller"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        >
                                            <MenuItem value=""></MenuItem>
                                            {seller.map((item) => (
                                                <MenuItem value={item.id}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                        {touched.seller && errors.seller && (
                                            <FormHelperText error id="outlined-adornment-role-register">
                                                {errors.seller}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.commission && errors.commission)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-email-register">Comissão</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-email-register"
                                            type="number"
                                            value={values.commission}
                                            name="commission"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.commission && errors.commission && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.commission}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            )}

                            <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                                <InputLabel htmlFor="outlined-adornment-email-register">Email</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-email-register"
                                    type="email"
                                    value={values.email}
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                />
                                {touched.email && errors.email && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.email}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <FormControl
                                fullWidth
                                error={Boolean(touched.password && errors.password)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                <InputLabel htmlFor="outlined-adornment-password-register">Senha</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password-register"
                                    type={showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    name="password"
                                    label="Password"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changePassword(e.target.value);
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                size="large"
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    inputProps={{}}
                                />
                                {touched.password && errors.password && (
                                    <FormHelperText error id="standard-weight-helper-text-password-register">
                                        {errors.password}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            {strength !== 0 && (
                                <FormControl fullWidth>
                                    <Box sx={{ mb: 2 }}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item>
                                                <Box
                                                    style={{ backgroundColor: level?.color }}
                                                    sx={{ width: 85, height: 8, borderRadius: '7px' }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" fontSize="0.75rem">
                                                    {level?.label}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </FormControl>
                            )}

                            <Grid container alignItems="center" justifyContent="space-between"></Grid>
                            {errors.submit && (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Box>
                            )}

                            <Box sx={{ mt: 2 }}>
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Atualizar usuário
                                    </Button>
                                </AnimateButton>
                            </Box>
                        </form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default EditUserForm;
