// material-ui

import logo from '../assets/images/Logos/sulmetais.png';
import Sul from '../assets/BW TECNOLOGIA.png';
import LogoCar from '../assets/jgf-logo.png';

const Logo = (props) => {
    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */
        <>
            <img src={LogoCar} alt="logo" width={props.width} />
        </>
    );
};

export default Logo;
