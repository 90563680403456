import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, TextField, Typography, Box, InputLabel, Select, MenuItem, ButtonGroup } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import DeleteIcon from '@mui/icons-material/Delete';
import PinIcon from '@mui/icons-material/Pin';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import EditSkeleton from './component/EditSkeleton';
import { toast } from 'react-toastify';

const initialState = {
    materiaPrima: '',
    quantity: 0,
    lossPercent: 0,
    itemSubstitute: { id: null },
    formula: []
};

function EditMaterial() {
    const { id } = useParams();
    const navigate = useNavigate();
    const api = useApi();

    const [campos, setCampos] = useState([initialState]);

    // pegando todos os produtos para filtrar por matéria prima
    const [allProducts, setAllProducts] = useState([]);

    const getAllProducts = async () => {
        const response = await api.getAllProducts();
        setAllProducts(response.data);
    };
    let primaryProducts = allProducts.filter((product) => product.productPrimary === true);

    // ================= LÓGICA DO FORMULÁRIO

    const [quiz, setQuiz] = useState([]);
    const [allQuiz, setAllQuiz] = useState([]);

    const getList = async (id) => {
        const response = await api.getListById(id);

        const updatedData = { ...response.data }; // Cria uma cópia do objeto original

        // popula o campo materiaPrima de campos com o id do product
        if (updatedData) {
            updatedData.materiaPrima = updatedData.product.id;
        }

        if (updatedData.formula) {
            updatedData.formula.forEach((formula, index) => {
                if (formula.question1) {
                    if (formula.question1.id) {
                        formula.inputType = { question1: 'select', ...formula.inputType };
                    } else if (formula.question1 === null) {
                        formula.inputType = { question1: 'text', ...formula.inputType };
                    }
                } else {
                    formula.inputType = { question1: 'text', ...formula.inputType };
                }

                if (formula.question2) {
                    if (formula.question2.id) {
                        formula.inputType = { question2: 'select', ...formula.inputType };
                    } else if (formula.question2 === null) {
                        formula.inputType = { question2: 'text', ...formula.inputType };
                    }
                } else {
                    formula.inputType = { question2: 'text', ...formula.inputType };
                }
            });
        }
        setCampos([updatedData]);
    };

    const getAllQuiz = async () => {
        const { data } = await api.getAllQuiz();
        setAllQuiz(data);
        // data.map((quiz) => {
        //     return setAllQuiz((prevQuiz) => [...prevQuiz, quiz]);
        // });
        console.log('🚀 ~ getAllQuiz ~ data:', data);
    };
    const [allQuestionsGet, setAllQuestionsGet] = useState([]);
    const getallQuestions = async () => {
        try {
            const { data } = await api.getAllQuestion();
            setAllQuestionsGet(data);
        } catch (error) {
            console.log('🚀 ~ getQuestion ~ error:', error);
        }
    };

    const getCurrentQuiz = async (id) => {
        try {
            const { data } = await api.getQuizByIdV2(id);
            console.log('🚀 ~ getCurrentQuiz ~ data:', data);

            setSubistitute(data);
            return setQuiz(data);
        } catch (error) {
            console.log('🚀 ~ file: EditMaterial.js:93 ~ getCurrentQuiz ~ error:', error);
        }
    };

    // controle de seleção do quiz correto
    const [subistitute, setSubistitute] = useState([]);
    useEffect(() => {
        if (allQuiz) {
            allQuiz.map((item) => {
                if (item?.product?.id === campos[0]?.finalProduct?.id) {
                    const findCurrentQuiz = allQuiz.find((itemFind) => itemFind.product?.id === campos[0]?.finalProduct?.id);
                    console.log('🚀 ~ allQuiz.map ~ findCurrentQuiz:', findCurrentQuiz);
                    getCurrentQuiz(findCurrentQuiz.id);
                }
            });
        }
    }, [allQuiz, campos]);

    useEffect(() => {
        getAllProducts();
        getList(id);
        getAllQuiz();
        getallQuestions();
    }, [id]);

    const operacoes = [
        { id: 1, op: 'plus', symbol: '+' },
        { id: 2, op: 'minor', symbol: '-' },
        { id: 3, op: 'times', symbol: '*' },
        { id: 4, op: 'divided', symbol: '/' }
    ];

    const adicionarOutroCampo = (indiceCampo) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].formula.push({
                operation: '',
                question1: { id: '' },
                question2: { id: '' },
                inputType: { question1: 'select', question2: 'select', valor1: 'textfield', valor2: 'textfield' },
                valor1: 0,
                valor2: 0
            });
            return novosCampos;
        });
    };

    const removerOutroCampo = (indiceCampo, indiceOutroCampo) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].formula.splice(indiceOutroCampo, 1);
            return novosCampos;
        });
    };

    const handleChangeInputTypeQuestion1 = (indiceCampo, indiceOutroCampo, novoTipo) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].formula[indiceOutroCampo].inputType.question1 = novoTipo;

            if (novoTipo === 'textfield') {
                novosCampos[indiceCampo].formula[indiceOutroCampo].valor1 = '';
            } else {
                novosCampos[indiceCampo].formula[indiceOutroCampo].valor1 = 0; // Define como null quando o tipo for alterado para select
                novosCampos[indiceCampo].formula[indiceOutroCampo].question1 = { id: '' };
            }

            return novosCampos;
        });
    };

    const handleChangeInputTypeQuestion2 = (indiceCampo, indiceOutroCampo, novoTipo) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].formula[indiceOutroCampo].inputType.question2 = novoTipo;

            if (novoTipo === 'textfield') {
                novosCampos[indiceCampo].formula[indiceOutroCampo].valor2 = 0;
            } else {
                novosCampos[indiceCampo].formula[indiceOutroCampo].question2 = { id: '' };
            }

            return novosCampos;
        });
    };

    const atualizarCampo = (indiceCampo, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            if (campo === 'quantity' || campo === 'lossPercent') {
                novosCampos[indiceCampo][campo] = Number(valor);
            } else if (campo === 'itemSubstitute') {
                novosCampos[indiceCampo][campo] = { id: Number(valor) };
            } else {
                novosCampos[indiceCampo][campo] = valor;
            }
            return novosCampos;
        });
    };

    const atualizarOutroCampo = (indiceCampo, indiceOutroCampo, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            if (campo === 'operation') {
                novosCampos[indiceCampo].formula[indiceOutroCampo].operation = valor;
            } else if (
                campo === 'valor1' &&
                novosCampos[indiceCampo].formula[indiceOutroCampo].inputType &&
                novosCampos[indiceCampo].formula[indiceOutroCampo].inputType.question1 === 'textfield'
            ) {
                novosCampos[indiceCampo].formula[indiceOutroCampo].valor1 = parseFloat(valor);
            } else if (
                campo === 'valor2' &&
                novosCampos[indiceCampo].formula[indiceOutroCampo].inputType &&
                novosCampos[indiceCampo].formula[indiceOutroCampo].inputType.question2 === 'textfield'
            ) {
                novosCampos[indiceCampo].formula[indiceOutroCampo].valor2 = parseFloat(valor);
            } else if (typeof valor === 'object' && valor !== null) {
                novosCampos[indiceCampo].formula[indiceOutroCampo][campo] = { id: valor.id };
            } else {
                novosCampos[indiceCampo].formula[indiceOutroCampo][campo] = parseFloat(valor);
            }

            // Atualize o valor selecionado no estado correspondente
            if (campo === 'question1') {
                novosCampos[indiceCampo].formula[indiceOutroCampo].question1 = { id: valor };
            } else if (campo === 'question2') {
                novosCampos[indiceCampo].formula[indiceOutroCampo].question2 = { id: valor };
            }

            return novosCampos;
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Lógica para lidar com o envio do formulário
        const MATERIAL = {
            finalProductId: campos[0]?.finalProduct?.id,
            materialList: campos,
            itemSub: campos[0]?.itemSubstitute?.id
        };
        console.log('🚀 ~ handleSubmit ~ campos:', campos);

        for (let i = 0; i < MATERIAL.materialList.length; i++) {
            MATERIAL.materialList[i].formula = MATERIAL.materialList.map((item) => {
                if (item) {
                    const b = item.formula.map((teste) => {
                        const a = teste;
                        if (a.question1 && a.question2 && a.question1.id === '' && a.question2.id === '') {
                            const newTeste = {
                                valor2: parseFloat(a.valor2),
                                operation: a.operation,
                                valor1: parseFloat(a.valor1)
                            };
                            return newTeste;
                        } else if (a.question1 && a.question1.id === '') {
                            const newTeste = {
                                question2: a.question2,
                                operation: a.operation,
                                valor1: parseFloat(a.valor1),
                                valor2: parseFloat(a.valor2)
                            };
                            return newTeste;
                        } else if (a.question2 && a.question2.id === '') {
                            const newTeste = {
                                question1: a.question1,
                                operation: a.operation,
                                valor1: parseFloat(a.valor1),
                                valor2: parseFloat(a.valor2)
                            };

                            return newTeste;
                        }
                        return a;
                    });
                    return b;
                }
                return item;
            });
        }

        try {
            await api.updateList(MATERIAL, id, MATERIAL.itemSub);
            // navigate(-1);
            // window.location.reload();
            toast.success('Lista de material atualizada com sucesso!');
        } catch (error) {
            console.log('🚀 ~ handleSubmit ~ error:', error);
            toast.error('Erro ao atualizar a lista de material!');
        }
    };

    const handleNavigate = () => {
        navigate(-1);
    };

    return (
        <MainCard title="Edição de lista de material">
            <Grid container spacing={gridSpacing}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '1rem',
                        width: '100%',
                        padding: '1.6em'
                    }}
                >
                    {subistitute?.length > 0 ? (
                        <>
                            <Box sx={{ margin: '2em' }}>
                                <Typography variant="body2" sx={{ fontSize: '1.2em' }}>
                                    Editando lista de material do produto: <strong>{campos[0]?.product?.productName}</strong>
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', width: '80%', gap: '1rem', flexDirection: 'column' }}>
                                {campos.map((campo, indiceCampo) => (
                                    <Box key={indiceCampo} sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                                            <FormControl sx={{ width: '55%', mb: '15px' }}>
                                                <InputLabel id="materia prima">Matéria Prima *</InputLabel>
                                                <Select
                                                    sx={{ width: '100%' }}
                                                    labelId="materia prima"
                                                    label="materia Prima"
                                                    value={campo.materiaPrima}
                                                    onChange={(e) => atualizarCampo(indiceCampo, 'materiaPrima', e.target.value)}
                                                    required
                                                >
                                                    {primaryProducts &&
                                                        primaryProducts.map((item) => (
                                                            <MenuItem key={item.id} value={item.id}>
                                                                Id: {item.id} - {item.productName}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                label="Quantidade"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                value={campo.quantity}
                                                onChange={(e) => atualizarCampo(indiceCampo, 'quantity', e.target.value)}
                                            />
                                            <TextField
                                                label="Percentual Perda"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                value={campo.lossPercent}
                                                onChange={(e) => atualizarCampo(indiceCampo, 'lossPercent', e.target.value)}
                                            />
                                            <FormControl sx={{ width: '55%', mb: '15px' }}>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    label="Item Substituto*"
                                                    id="item-subs"
                                                    value={campo?.itemSubstitute && campo?.itemSubstitute?.id}
                                                    onChange={(e) => atualizarCampo(indiceCampo, 'itemSubstitute', e.target.value)}
                                                    required
                                                    InputLabelProps={{ shrink: true }}
                                                >
                                                    {subistitute
                                                        .filter((item) => item.type !== 'Aberta')
                                                        .map((question) => (
                                                            <MenuItem key={question.id} value={question.id}>
                                                                {question.description}
                                                            </MenuItem>
                                                        ))}
                                                </TextField>
                                            </FormControl>
                                        </Box>
                                        {campo.formula.map((formula, indiceOutroCampo) => (
                                            <Box
                                                key={indiceOutroCampo}
                                                sx={{
                                                    display: 'flex',
                                                    gap: '1rem',
                                                    width: '100%',
                                                    justifyContent: 'space-between',
                                                    marginTop: '2em'
                                                }}
                                            >
                                                {indiceOutroCampo > 0 && (
                                                    <FormControl sx={{ width: '35%', mb: '15px' }}>
                                                        <InputLabel id="operacao">Operação</InputLabel>
                                                        <Select
                                                            labelId="operacao"
                                                            label="operacao"
                                                            value={formula.operation}
                                                            onChange={(e) =>
                                                                atualizarOutroCampo(
                                                                    indiceCampo,
                                                                    indiceOutroCampo,
                                                                    'operation',
                                                                    e.target.value
                                                                )
                                                            }
                                                        >
                                                            {operacoes.map((operacao) => (
                                                                <MenuItem key={operacao.op} value={operacao.op}>
                                                                    {operacao.symbol}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                )}

                                                {formula?.inputType?.question1 === 'select' ? (
                                                    <>
                                                        <FormControl sx={{ width: '60%', mb: '15px' }}>
                                                            <InputLabel id="valor1">Valor 1</InputLabel>
                                                            <Select
                                                                labelId="valor1"
                                                                label="valor1"
                                                                value={campos[indiceCampo].formula[indiceOutroCampo].question1?.id || ''}
                                                                onChange={(e) => {
                                                                    atualizarOutroCampo(
                                                                        indiceCampo,
                                                                        indiceOutroCampo,
                                                                        'question1',
                                                                        e.target.value
                                                                    );
                                                                    console.log(e.target.value);
                                                                }}
                                                            >
                                                                {subistitute.map((question) => (
                                                                    <MenuItem key={question.id} value={question.id}>
                                                                        {question.description}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        <Button
                                                            onClick={() =>
                                                                handleChangeInputTypeQuestion1(indiceCampo, indiceOutroCampo, 'text')
                                                            }
                                                        >
                                                            <PinIcon />
                                                        </Button>
                                                        <Button
                                                            onClick={() =>
                                                                handleChangeInputTypeQuestion1(indiceCampo, indiceOutroCampo, 'select')
                                                            }
                                                        >
                                                            <QuestionAnswerIcon />
                                                        </Button>
                                                        {!indiceOutroCampo > 0 && (
                                                            <FormControl sx={{ width: '30%', mb: '15px' }}>
                                                                <InputLabel id="operacao">Operação</InputLabel>
                                                                <Select
                                                                    labelId="operacao"
                                                                    label="operacao"
                                                                    value={formula.operation}
                                                                    onChange={(e) =>
                                                                        atualizarOutroCampo(
                                                                            indiceCampo,
                                                                            indiceOutroCampo,
                                                                            'operation',
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                >
                                                                    {operacoes.map((operacao) => (
                                                                        <MenuItem key={operacao.op} value={operacao.op}>
                                                                            {operacao.symbol}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <FormControl sx={{ width: '60%', mb: '15px' }}>
                                                            <TextField
                                                                type="number"
                                                                value={campos[indiceCampo].formula[indiceOutroCampo].valor1}
                                                                placeholder="Digite o número..."
                                                                onChange={(e) =>
                                                                    atualizarOutroCampo(
                                                                        indiceCampo,
                                                                        indiceOutroCampo,
                                                                        'valor1',
                                                                        e.target.value
                                                                    )
                                                                }
                                                            />
                                                        </FormControl>

                                                        <Button
                                                            onClick={() =>
                                                                handleChangeInputTypeQuestion1(indiceCampo, indiceOutroCampo, 'text')
                                                            }
                                                        >
                                                            <PinIcon />
                                                        </Button>
                                                        <Button
                                                            onClick={() =>
                                                                handleChangeInputTypeQuestion1(indiceCampo, indiceOutroCampo, 'select')
                                                            }
                                                        >
                                                            <QuestionAnswerIcon />
                                                        </Button>
                                                        {!indiceOutroCampo > 0 && (
                                                            <FormControl sx={{ width: '25%', mb: '15px' }}>
                                                                <InputLabel id="operacao">Operação</InputLabel>
                                                                <Select
                                                                    labelId="operacao"
                                                                    label="operacao"
                                                                    value={formula.operation}
                                                                    onChange={(e) =>
                                                                        atualizarOutroCampo(
                                                                            indiceCampo,
                                                                            indiceOutroCampo,
                                                                            'operation',
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                >
                                                                    {operacoes.map((operacao) => (
                                                                        <MenuItem key={operacao.op} value={operacao.op}>
                                                                            {operacao.symbol}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                    </>
                                                )}
                                                {indiceOutroCampo === 0 ? (
                                                    // Renderizar o campo e botões para question2 apenas na primeira linha de campos.formula
                                                    <>
                                                        {formula?.inputType?.question2 === 'select' ? (
                                                            <FormControl sx={{ width: '50%', mb: '15px' }}>
                                                                <InputLabel id="valor2">Valor 2</InputLabel>
                                                                <Select
                                                                    labelId="valor2"
                                                                    label="valor2"
                                                                    value={
                                                                        campos[indiceCampo].formula[indiceOutroCampo].question2?.id || ''
                                                                    }
                                                                    onChange={(e) => {
                                                                        atualizarOutroCampo(
                                                                            indiceCampo,
                                                                            indiceOutroCampo,
                                                                            'question2',
                                                                            e.target.value
                                                                        );
                                                                        console.log(e.target.value);
                                                                    }}
                                                                >
                                                                    {subistitute.map((question) => (
                                                                        <MenuItem key={question.id} value={question.id}>
                                                                            {question.description}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        ) : (
                                                            <FormControl sx={{ width: '50%', mb: '15px' }}>
                                                                <TextField
                                                                    type="number"
                                                                    value={campos[indiceCampo].formula[indiceOutroCampo].valor2}
                                                                    placeholder="Digite o número..."
                                                                    onChange={(e) => {
                                                                        atualizarOutroCampo(
                                                                            indiceCampo,
                                                                            indiceOutroCampo,
                                                                            'valor2',
                                                                            e.target.value
                                                                        );
                                                                        console.log(e.target.value);
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        )}
                                                        {/* Botões para mudar o tipo de input para pergunta 1 e pergunta 2 */}

                                                        <Button
                                                            onClick={() =>
                                                                handleChangeInputTypeQuestion2(indiceCampo, indiceOutroCampo, 'text')
                                                            }
                                                        >
                                                            <PinIcon />
                                                        </Button>
                                                        <Button
                                                            onClick={() =>
                                                                handleChangeInputTypeQuestion2(indiceCampo, indiceOutroCampo, 'select')
                                                            }
                                                        >
                                                            <QuestionAnswerIcon />
                                                        </Button>
                                                    </>
                                                ) : null}
                                                <Button onClick={() => removerOutroCampo(indiceCampo, indiceOutroCampo)}>
                                                    <DeleteIcon sx={{ color: 'red' }} />
                                                </Button>
                                            </Box>
                                        ))}
                                        <ButtonGroup sx={{ display: 'flex' }}>
                                            <Button onClick={() => adicionarOutroCampo(indiceCampo)}>
                                                <AddIcon />
                                            </Button>
                                        </ButtonGroup>
                                    </Box>
                                ))}
                            </Box>

                            <Box
                                sx={{
                                    width: '80%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginTop: '2em'
                                }}
                            >
                                <Button variant="contained" color="primary" sx={{ width: '30%' }} onClick={handleNavigate}>
                                    Voltar
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ width: '30%' }}
                                    //disabled={inputError || productAssociateds.length === 0 || !inputs.length > 0}
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Atualizar
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <EditSkeleton />
                    )}
                </Box>
            </Grid>
        </MainCard>
    );
}

export default EditMaterial;
