import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, IconButton, TextField, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useRef } from 'react';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px'
};

export function ObservationManager({ open, setOpen }) {
    const [data, setData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [currentRow, setCurrentRow] = useState({});
    const api = useApi();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let observation = useRef(null);

    async function handleSubmit(event) {
        event.preventDefault();
        const toastId = toast.loading('Cadastrando observação...');
        try {
            await api.createCustomObservation({ observation: observation.current.value });
            toast.update(toastId, { isLoading: false, type: 'success', render: 'Observação cadastrada com sucesso!', autoClose: 2000 });
            observation.current.value = '';
            getObservations();
        } catch (error) {
            console.log('🚀 ~ handleSubmit ~ error:', error);
            toast.update(toastId, { isLoading: false, type: 'error', render: 'Erro ao cadastrar observação!', autoClose: 2000 });
        }
    }

    async function getObservations() {
        try {
            const { data } = await api.getCustomObservation();
            setData(data);
        } catch (error) {
            console.log('🚀 ~ getObservations ~ error:', error);
        }
    }

    useEffect(() => {
        if (open) {
            getObservations();
        }
    }, [open]);

    async function handleEdit(event) {
        event.preventDefault();
        const toastId = toast.loading('Editando observação...');
        try {
            await api.updateCustomObservation(currentRow.id, { observation: observation.current.value });
            getObservations();
            observation.current.value = '';
            toast.update(toastId, { isLoading: false, type: 'success', render: 'Observação editada com sucesso!', autoClose: 2000 });
        } catch (error) {
            console.log('🚀 ~ handleEdit ~ error:', error);
            toast.update(toastId, { isLoading: false, type: 'error', render: 'Erro ao editar observação!', autoClose: 2000 });
        }
    }

    async function handleDelete(id) {
        const toastId = toast.loading('Deletando observação...');
        try {
            await api.deleteCustomObservation(id);
            getObservations();
            toast.update(toastId, {
                isLoading: false,
                type: 'success',
                render: 'Observação deletada com sucesso!',
                autoClose: 2000
            });
        } catch (error) {
            console.log('🚀 ~ handleDelete ~ error:', error);
            toast.update(toastId, {
                isLoading: false,
                type: 'error',
                render: 'Erro ao deletar observação!',
                autoClose: 2000
            });
        }
    }

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <h3 style={{ textAlign: 'center' }}>Gerenciador de observações</h3>
                <form onSubmit={isEdit ? handleEdit : handleSubmit}>
                    <TextField InputLabelProps={{ shrink: true }} fullWidth rows={3} multiline label="Observação" inputRef={observation} />
                    <Box sx={{ margin: '10px auto', width: '450px' }}>
                        <Button type="submit" variant="contained" color="primary" sx={{ width: '400px' }}>
                            {isEdit ? 'Editar' : 'Cadastrar'}
                        </Button>
                    </Box>
                    <Divider variant="middle" sx={{ marginTop: '10px' }} />
                </form>
                <Box
                    sx={{
                        maxHeight: '400px',
                        overflow: 'auto'
                    }}
                >
                    {data.map((item, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '10px',
                                border: '1px solid #ccc',
                                borderRadius: '8px',
                                padding: '5px 10px'
                            }}
                        >
                            <span>{item.observation}</span>

                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                <Tooltip title="Delete" onClick={() => handleDelete(item.id)}>
                                    <IconButton>
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    title="Edite"
                                    onClick={() => {
                                        observation.current.value = item.observation;
                                        setIsEdit(true);
                                        setCurrentRow(item);
                                    }}
                                >
                                    <IconButton>
                                        <DriveFileRenameOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Modal>
    );
}
