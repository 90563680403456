import MainCard from 'ui-component/cards/MainCard';
import React from 'react';
import {
    Grid,
    Typography,
    Button,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    Select,
    TextField,
    Box,
    MenuItem,
    ButtonGroup,
    Icon
} from '@mui/material';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import QuizIcon from '@mui/icons-material/Quiz';
import { useNavigate, useParams } from 'react-router';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import ModalUrl from './components/ModalUrl';
import ModalQuestions from './components/ModalQuestions';
import ConfirmModal from './components/ConfirmModal';
import SnackBar from 'ui-component/SnakBar';
import { toast } from 'react-toastify';
import RedirectModal from './components/RedirectModal';
import { useFormula } from 'hooks/useFormula';
import EditQuizSkeleton from './components/EditQuizSkeleton';
import ObservationModal from './components/ObservationModal';

function EditView() {
    const { id, name } = useParams();
    const api = useApi();
    const [selectIds, setSelectIds] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [allQuestions, setAllQuestion] = useState([]);
    const [openUrlModal, setOpenUrlModal] = useState(false);
    const [OpenQuestionsModal, setOpenQuestionsModal] = useState(false);
    const [openConfirmModal, setConfirmModal] = useState(false);
    const [positionsDb, setPositonsDb] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [openSnack, setOpenSnack] = useState(false);
    const [titleSnack, setTItleSnack] = useState('');
    const { setPosition, setQuizIdContext } = useFormula();
    const [observation, setObservation] = useState('');

    useEffect(() => {
        setQuizIdContext(id);
    }, []);

    const updatePosition = async () => {
        const payload = allPosition.map((item, index) => ({
            id: item.id,
            position: index,
            openQuestionRestriction: item?.openQuestionRestriction,
            imageUrl: item?.imageUrl,
            haveBodyFormula: item?.haveBodyFormula,
            question: item?.description
        }));

        try {
            await api.updatePositionV2(id, payload);
            toast.success('Quiz salvo com sucesso');
            getQuizById(id);
        } catch (error) {
            toast.warn('Ocorreu um erro inesperado.');
        }
    };

    const [shouldSavingQuestion, setShouldSavingQuestion] = useState(false);

    const handlePerguntaSelect = (pergunta) => {
        const itemAlreadySelected = positionsDb.some((item) => item && item.id === pergunta.id);

        if (itemAlreadySelected) {
            toast.warn('Essa pergunta já foi escolhida!');
            return;
        }
        setShouldSavingQuestion(true);
        setSelectedOptions((prevOptions) => [...prevOptions, pergunta]);
        setPositonsDb((prevPositions) => [...prevPositions, pergunta]);
        setAllPosition((prevPositions) => [...prevPositions, pergunta]);
        setQuestions((prevQuestions) => prevQuestions.filter((item) => item.id !== pergunta.id));
    };

    const savingWhenSelecting = () => {
        setShouldSavingQuestion(false);
        updatePosition();
    };

    useEffect(() => {
        if (shouldSavingQuestion) savingWhenSelecting();
    }, [shouldSavingQuestion]);

    const handleRemoveOption = (option) => {
        const removedQuestion = option;

        setQuestions((prevQuestions) => [...prevQuestions, option]);
        setAllPosition((prevPositions) => prevPositions.filter((position) => position.id !== option.id));
        setPositonsDb((prevPositions) => prevPositions.filter((position) => position.id !== option.id));
    };

    const get = async () => {
        const reponse = await api.getAllQuestion();
        setAllQuestion(reponse.data);
    };

    const [currentAnswers, setCurrentAnswers] = useState([]);
    const [quizQuestionsPosition, setQuizQuestionPosition] = useState([]);
    const [quiz, setQuiz] = useState([]);

    const getQuizById = async (id) => {
        try {
            // const {data} = await api.getQuestionaryById(id);
            const { data } = await api.getQuizByIdV2(id);
            console.log('🚀 ~ file: index.js:135 ~ getQuizById ~ data:', data);

            setQuiz(data);

            const quizQuestions = data.map((item) => item.id);
            console.log('🚀 ~ getQuizById ~ quizQuestions:', quizQuestions);
            setPositonsDb(data);
            setQuizQuestionPosition(quizQuestions);
            setAllPosition(data);
            console.log('🚀 ~ getQuizById ~ data:', data);
        } catch (error) {
            console.log('🚀 ~ file: index.js:135 ~ getQuizById ~ error:', error);
        }
    };

    const [newQuestion, setNewQuestion] = useState(false);

    const moveCardUp = (index) => {
        if (index > 0) {
            const updatedCards = [...positionsDb];
            const cardToMove = updatedCards.splice(index, 1)[0];
            updatedCards.splice(index - 1, 0, cardToMove);

            setPositonsDb(updatedCards);
            setAllPosition(updatedCards);
        }
    };

    const moveCardDown = (index) => {
        if (index < positionsDb.length - 1) {
            const updatedCards = [...positionsDb];
            const cardToMove = updatedCards.splice(index, 1)[0];
            updatedCards.splice(index + 1, 0, cardToMove);
            console.log('🚀 ~ file: index.js:286 ~ moveCardDown ~ updatedCards:', updatedCards);

            setPositonsDb(updatedCards);
            setAllPosition(updatedCards);
        }
    };

    const [currentOption, setCurrentOption] = useState([]);
    const [currentId, setCurrentId] = useState('');
    const [currentName, setCurrentName] = useState('');
    const confirmModal = (option) => {
        setCurrentOption(option);
        setConfirmModal(true);
    };
    const [questionType, setQuestionType] = useState('');
    const [indexposition, setIndexPosition] = useState('');

    const handleOpenQuestions = (option, index) => {
        // getAnswers(option.id);
        setOpenQuestionsModal(true);
        setCurrentId(option.id);
        setCurrentName(option.description);
        setQuestionType(option.type);
        setCurrentOption(option);
        setIndexPosition(index);
    };

    const [selectedValue, setSelectedValue] = useState([]);
    const handlerClick = (data) => {
        setSelectedValue(data);
    };
    const [pos, setPos] = useState('');
    const [currentQuesiton, setCurrentQuestion] = useState([]);
    const handleUrl = (currentQuestion) => {
        setCurrentQuestion(currentQuestion);
    };

    const [allPosition, setAllPosition] = useState([]);
    const [loadingPosition, setLoadingPosition] = useState(false);

    async function getObservation() {
        try {
            const { data } = await api.getQuizNotesId(id);
            setObservation(data[0]);
        } catch (error) {
            console.log('🚀 ~ file: index.js ~ line 222 ~ getObservation ~ error', error);
        }
    }

    useEffect(() => {
        get();
        getQuizById(id);
        getObservation();
    }, [id]);

    useEffect(() => {
        const restQuestions = allQuestions.filter((question) => !quizQuestionsPosition.includes(question.id));
        setQuestions(restQuestions);
    }, [allQuestions, quizQuestionsPosition]);

    const [positionModel, setPositionModel] = useState([]);

    return (
        <>
            <ModalUrl openUrlModal={openUrlModal} setOpenUrlModal={setOpenUrlModal} currentQuesiton={currentQuesiton} pos={pos} />
            <SnackBar openSnack={openSnack} setOpenSnack={setOpenSnack} title={titleSnack} />
            <RedirectModal
                open={OpenQuestionsModal}
                setOpen={setOpenQuestionsModal}
                currentAnswers={currentAnswers}
                question={currentName}
                Selected={handlerClick}
                selectedList={positionsDb}
                currentId={currentId}
                questionType={questionType}
                currentOption={currentOption}
                indexposition={indexposition}
                quizId={id}
                positionModel={positionModel}
                savingPosition={updatePosition}
            />

            <ConfirmModal
                openUrlModal={openConfirmModal}
                setOpenUrlModal={setConfirmModal}
                handleRemoveOption={handleRemoveOption}
                currentOption={currentOption}
                allPosition={allPosition}
                setAllPosition={setAllPosition}
                savingPosition={updatePosition}
            />

            <MainCard
                title="Selecione as perguntas"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    position: 'relative'
                }}
            >
                <div style={{ position: 'absolute', right: 30, top: '30px' }}>
                    <ObservationModal questions={positionsDb} quizId={id} currentObservation={observation} />
                </div>
                {/* // cadastro do nome  */}
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <Typography sx={{ fontSize: '20px' }}>
                        Editando questionário de: <strong>{name}</strong>
                    </Typography>
                </Box>
                {/* selecione as perguntas  */}
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', gap: '1rem', mt: '1rem' }}>
                    <Box sx={{ width: '50%', backgroundColor: '#F8FAFC', borderRadius: '8px', marginBottom: '8px', padding: '1rem' }}>
                        <Typography sx={{ marginBottom: '1rem' }} variant="h6">
                            Perguntas selecionadas
                        </Typography>

                        {!loadingPosition ? (
                            positionsDb.map((option, index) => (
                                <React.Fragment key={option.id}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            marginBottom: '8px',
                                            width: '100%',
                                            padding: '0.5rem',
                                            backgroundColor: '#eef2f6',
                                            borderRadius: '8px'
                                        }}
                                    >
                                        <Box
                                            style={{
                                                marginBottom: '8px',
                                                cursor: 'pointer',
                                                backgroundColor: '#eef2f6',
                                                display: 'flex'
                                            }}
                                        >
                                            <Box style={{ marginRight: '10px', display: 'flex' }}>
                                                <span style={{ marginRight: '5px', fontWeight: 'bold' }}>ID</span>
                                                {option.id}
                                                <div style={{ border: '1px solid #999', marginLeft: '10px' }}></div>
                                            </Box>
                                            {option.description}
                                        </Box>
                                        <Box>
                                            <IconButton aria-label="move up" size="small" onClick={() => moveCardUp(index)}>
                                                <KeyboardDoubleArrowUpIcon />
                                            </IconButton>
                                            <IconButton aria-label="move up" size="small" onClick={() => moveCardDown(index)}>
                                                <KeyboardDoubleArrowDownIcon />
                                            </IconButton>
                                            <IconButton
                                                aria-label="delete"
                                                size="small"
                                                onClick={() => {
                                                    handleOpenQuestions(option, index);
                                                    setPosition(allPosition[index]);
                                                    setPositionModel(allPosition[index]);
                                                }}
                                            >
                                                <Icon>
                                                    <QuizIcon
                                                        style={{
                                                            color:
                                                                allPosition[index]?.haveBodyFormula ||
                                                                allPosition[index]?.openQuestionRestriction
                                                                    ? '#138AC4'
                                                                    : ''
                                                        }}
                                                    />
                                                </Icon>
                                            </IconButton>
                                            <IconButton
                                                aria-label="delete"
                                                size="small"
                                                onClick={() => {
                                                    setOpenUrlModal(true);
                                                    handleUrl(option);
                                                    setPosition(allPosition[index]);
                                                }}
                                            >
                                                <Icon>
                                                    <AddPhotoAlternateIcon
                                                        style={{ color: allPosition[index]?.imageUrl ? '#138AC4' : '' }}
                                                    />
                                                </Icon>
                                            </IconButton>
                                            <IconButton aria-label="delete" size="small" onClick={() => confirmModal(option)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </React.Fragment>
                            ))
                        ) : (
                            <EditQuizSkeleton />
                        )}
                    </Box>
                    <Box sx={{ width: '50%' }}>
                        <Grid item xs={12}>
                            <Card style={{ borderRadius: '8px', marginBottom: '8px', backgroundColor: '#F8FAFC' }}>
                                <CardContent>
                                    <Typography sx={{ marginBottom: '1rem' }} variant="h6">
                                        Selecione as perguntas:
                                    </Typography>
                                    {questions.map((pergunta) => (
                                        <Card
                                            key={pergunta.id}
                                            onClick={() => handlePerguntaSelect(pergunta)}
                                            role="button"
                                            tabIndex="-1"
                                            style={{ marginBottom: '8px', cursor: 'pointer', backgroundColor: '#eef2f6' }}
                                        >
                                            <CardContent style={{ display: 'flex' }}>
                                                <Box style={{ marginRight: '10px', display: 'flex' }}>
                                                    <span style={{ marginRight: '5px', fontWeight: 'bold' }}>ID</span>
                                                    {pergunta.id}
                                                    <div style={{ border: '1px solid #999', marginLeft: '10px' }}></div>
                                                </Box>
                                                <Typography variant="body1">{pergunta.description}</Typography>
                                            </CardContent>
                                        </Card>
                                    ))}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem', width: '100%' }}>
                    {!newQuestion && (
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={allPosition.length === 0 && positionsDb.length === 0}
                            onClick={() => {
                                // handleCreateQuestionary();
                                updatePosition();
                            }}
                        >
                            Salvar Questionário
                        </Button>
                    )}
                    {!newQuestion && (
                        <Link to={'/main/questionnaires'}>
                            <Button variant="contained" color="primary">
                                Voltar
                            </Button>
                        </Link>
                    )}
                </Box>
                {/* {newQuestion && renderEtapaAtual()} */}
            </MainCard>
        </>
    );
}

export default EditView;
