import { Button, Grid, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { IconPencil } from '@tabler/icons';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import AddNewCloseAnswer from './components/AddNewCloseAnswer';
import AddNewOpenQuestion from './components/AddNewOpenQuestion';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import ConfirmModal from 'ui-component/ConfirmModal';
import EditOpenQuestion from './components/EditOpenQuestion';

const QuestionPage = () => {
    const api = useApi();
    const navigate = useNavigate();

    const [rows, setRows] = useState([]);
    const [currentId, setCurrentId] = useState(null);
    const [currentOpenQuestion, setCurrentOpenQuestion] = useState(null);

    //bollean do modal de add
    const [openModalCloseAnswer, setOpenModalCloseAnswer] = useState(false);
    const [openModalOpenAnswer, setOpenModalOpenAnswer] = useState(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [openEditOpenQuestion, setOpenEditOpenQuestion] = useState(false);

    const getAllQuestion = async () => {
        try {
            const { data } = await api.getAllQuestion();
            const rows = data.map((item) => ({
                ...item,
                id: item.id,
                description: item.description,
                type: item.type
            }));
            setRows(rows);
        } catch (error) {
            console.log('🚀 ~ file: index.js:12 ~ getAllQuestion ~ error:', error);
        }
    };

    useEffect(() => {
        getAllQuestion();
    }, []);

    const handleDelete = async () => {
        try {
            await api.deleteQuestion(currentId);
            toast.success('Pergunta deletada com sucesso!');
            getAllQuestion();
        } catch (error) {
            console.log('🚀 ~ file: index.js:48 ~ handleDelete ~ error:', error);
            toast.error('Lembre-se de deletar todas as restrições e/ou respostas atreladas a essa pergunta!!');
        }
    };

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'description', headerName: 'Descrição', flex: 1 },
        { field: 'type', headerName: 'Tipo', flex: 1 },
        {
            field: 'actions',
            headerName: 'Ações',
            sortable: false,
            flex: 0.5,
            renderCell: (params) => (
                <div>
                    <Tooltip title="Editar">
                        <IconButton
                            onClick={() => {
                                if (params.row.type === 'Fechada') {
                                    navigate(`/main/questions/${params.row.id}`);
                                }

                                if (params.row.type === 'Aberta') {
                                    setOpenEditOpenQuestion(true);
                                    setCurrentOpenQuestion(params.row);
                                }
                            }}
                        >
                            <IconPencil />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title="Deletar"
                        onClick={() => {
                            setCurrentId(params.row.id);
                            setOpenConfirmModal(true);
                        }}
                    >
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        }
    ];

    return (
        <MainCard title="Perguntas">
            <Grid container spacing={gridSpacing}>
                <AddNewCloseAnswer open={openModalCloseAnswer} setOpen={setOpenModalCloseAnswer} get={getAllQuestion} />
                <AddNewOpenQuestion open={openModalOpenAnswer} setOpen={setOpenModalOpenAnswer} get={getAllQuestion} />
                <EditOpenQuestion
                    open={openEditOpenQuestion}
                    setOpen={setOpenEditOpenQuestion}
                    get={getAllQuestion}
                    currentQuestion={currentOpenQuestion}
                />
                <ConfirmModal
                    open={openConfirmModal}
                    setOpen={setOpenConfirmModal}
                    button1={'Cancelar'}
                    button2={'Deletar'}
                    firstMessage={'Deseja realmente deletar essa pergunta ?'}
                    secondMessage={'Lembre-se de deletar todas as restrições e/ou respostas atreladas a essa pergunta!!'}
                    func={handleDelete}
                />
                <div style={{ margin: '20px 10px' }}>
                    <Button variant="contained" onClick={() => setOpenModalCloseAnswer(true)}>
                        Cadastrar pergunta fechada
                    </Button>
                    <Button sx={{ marginLeft: '20px' }} onClick={() => setOpenModalOpenAnswer(true)} variant="contained">
                        Cadastrar pergunta aberta
                    </Button>
                </div>

                <div
                    style={{
                        maxHeight: 500,
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%'
                    }}
                >
                    <DataGrid rows={rows} columns={columns} hideFooter checkboxSelection={false} />
                </div>
            </Grid>
        </MainCard>
    );
};

export default QuestionPage;
