import { Box, Divider, Fab } from '@mui/material';
import { useEffect } from 'react';
import { useBudgetPdf } from 'store/BudgetPdfContext';
import Logo from '../../../assets/jgf-logo.png';
import { IconArrowBack, IconBrandWhatsapp } from '@tabler/icons';
import QRCode from '../../../assets/jgf-qr-code.png';
import { DinamicTable } from './components/DinamicTable';
import { useState } from 'react';
import { formatarValorIntl } from 'utils/formatValueIntl';
import { display } from '@mui/system';
import { SpeedDialComponent } from './components/SpeedDialComponent';
import { useNavigate } from 'react-router';

export function PdfPage() {
    const { currentDataContext, sumContext, observationArrayContext, currentQuestionsContext } = useBudgetPdf();
    const [openSpeedDial, setOpenSpeedDial] = useState(true);
    const [service, setService] = useState([]);
    const [currentUser, setCurrentUser] = useState({});

    const [totalService, setTotalService] = useState(0); // Definir totalService como state
    const navigate = useNavigate();
    useEffect(() => {
        const isService = currentDataContext?.budgets?.filter((item) => item?.service === true);
        const userInfo = localStorage.getItem('userInfo');
        const userInfoObject = JSON.parse(userInfo);
        setCurrentUser(userInfoObject);

        // Área dos Serviços
        const serviceItens = isService?.map((item) => ({
            id: item?.id,
            desc: item?.budget_Name,
            un: item?.unitSize,
            qtd: item?.quantity,
            valorU: item?.unitPrice,
            valorT: item?.totalPrice,
            note: item?.note
        }));

        // Verifique se serviceItens tem dados válidos
        if (serviceItens && serviceItens.length > 0) {
            const sumServiceValues = serviceItens.reduce((acumulador, item) => {
                // Certifique-se de que item.valorT seja um número válido
                const valorTotal = parseFloat(item.valorT?.toString().replace(',', '.'));
                return acumulador + (isNaN(valorTotal) ? 0 : valorTotal);
            }, 0);

            // Atualiza o estado de totalService
            setTotalService(sumServiceValues);
        } else {
            setTotalService(0);
        }

        setService(serviceItens);
    }, [currentDataContext]);

    return (
        <Box sx={{ width: '100%', height: '100%', background: 'white', borderRadius: 4, padding: 4, margin: 'auto' }}>
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <Box>
                    <img src={Logo} alt="logo" style={{ width: '250px', height: 'auto' }} />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <span style={{ fontSize: '12px' }}>11</span> <p style={{ fontSize: '18px' }}>2255-8621</p>{' '}
                            <IconBrandWhatsapp style={{ color: 'green' }} />
                        </Box>
                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '-25px' }}>
                            <span style={{ fontSize: '12px' }}>11</span> <p style={{ fontSize: '18px' }}>94788-1056</p>{' '}
                            <IconBrandWhatsapp style={{ color: 'green' }} />
                        </Box>
                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '-25px' }}>
                            <span style={{ fontSize: '12px' }}>11</span> <p style={{ fontSize: '18px' }}>94746-6061</p>{' '}
                            <IconBrandWhatsapp style={{ color: 'green' }} />
                        </Box>
                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '-25px' }}>
                            <span style={{ fontSize: '12px' }}>11</span> <p style={{ fontSize: '18px' }}>94746-6058</p>{' '}
                            <IconBrandWhatsapp style={{ color: 'green' }} />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={QRCode} alt="Qr code" width={110} height={110} />
                        <p style={{ margin: 0 }}>@jgfesteticacaroficial</p>
                        <p style={{ margin: 0 }}>centroautomotivojgf@gmail.com</p>
                    </Box>
                </Box>
            </Box>

            <Divider sx={{ margin: '20px 0' }} />

            <Box sx={{ border: '1px solid #eee', padding: '10px', borderRadius: '10px' }}>
                <p style={{ margin: 0 }}>
                    CLIENTE: <span style={{ fontWeight: 'bold' }}>{currentDataContext?.manualClient}</span>
                </p>

                {/* <p style={{ margin: 0 }}>
                    ENDEREÇO: <span style={{ fontWeight: 'bold' }}>{currentDataContext?.neighborhood}</span>
                </p> */}
                <p style={{ margin: 0 }}>
                    TELEFONE: <span style={{ fontWeight: 'bold' }}>{currentDataContext?.cellPhone}</span>
                </p>
                <p style={{ margin: 0 }}>
                    VEICULO: <span style={{ fontWeight: 'bold' }}>{currentDataContext?.vehicle}</span>
                </p>
                <p style={{ margin: 0 }}>
                    COR: <span style={{ fontWeight: 'bold' }}>{currentDataContext?.color}</span>
                </p>
                <p style={{ margin: 0 }}>
                    PLACA: <span style={{ fontWeight: 'bold' }}>{currentDataContext?.plate}</span>
                </p>
            </Box>

            <Box sx={{ width: '100%' }}>
                <Box
                    sx={{
                        padding: '5px',
                        border: '1px solid #ccc',
                        width: '100%',
                        textAlign: 'center',
                        background: '#ccc',
                        borderRadius: '5px',
                        marginTop: '10px'
                    }}
                >
                    SERVIÇOS OFERECIDOS
                </Box>
                <Box sx={{ width: '100%' }}>
                    <DinamicTable dados={service} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box
                        sx={{
                            padding: '5px',
                            border: '1px solid #ccc',
                            width: '300px',
                            textAlign: 'center',
                            background: '#ccc',
                            borderRadius: '5px',
                            marginTop: '10px'
                        }}
                    >
                        Total dos serviços: {formatarValorIntl(totalService)}
                    </Box>
                </Box>
            </Box>

            <Box>
                {(observationArrayContext.length > 0 || currentDataContext?.observation?.length > 0) && (
                    <>
                        <Box
                            sx={{
                                padding: '5px',
                                border: '1px solid #ccc',
                                width: '100%',
                                textAlign: 'center',
                                background: '#ccc',
                                borderRadius: '5px',
                                marginTop: '10px'
                            }}
                        >
                            Premissas e Observações
                        </Box>
                        <Box style={{ padding: '10px' }}>
                            <p style={{ marginBottom: '4px' }}>{currentDataContext?.observation}</p>

                            {observationArrayContext.map((item, index) => (
                                <Box key={index} style={{ marginBottom: '4px' }}>
                                    <p style={{ margin: 0 }}>{item?.observation}</p>
                                </Box>
                            ))}
                        </Box>
                    </>
                )}
            </Box>
            {openSpeedDial && (
                <Box sx={{ position: 'fixed', bottom: '-240px', right: 10, display: openSpeedDial ? 'block' : 'none' }}>
                    <SpeedDialComponent open={openSpeedDial} setOpen={setOpenSpeedDial} />
                </Box>
            )}
            {openSpeedDial && (
                <Box sx={{ position: 'fixed', bottom: '40px', left: 10, display: openSpeedDial ? 'block' : 'none' }}>
                    <Fab variant="extended" onClick={() => navigate(-1)}>
                        <IconArrowBack />
                        Voltar
                    </Fab>
                </Box>
            )}

            <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', marginTop: '20px' }}>
                <Box>
                    <p>Atenciosamente,</p>
                    <p>{currentUser?.name}</p>
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                    <p>Aprovação</p>
                    <Box sx={{ borderTop: '1px solid #ccc', width: '300px', marginTop: '130px' }}>Assinatura / Carimbo</Box>
                </Box>
            </Box>
        </Box>
    );
}
