import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    TextField,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    IconButton,
    FormControl,
    InputLabel,
    TablePagination,
    TableSortLabel
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import CardColor from 'ui-component/CardColors';
import ModalError from 'views/pages/modal';
import { IconPencil } from '@tabler/icons';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import NewMpForm from './Form';
import { Link } from 'react-router-dom';
import Row from './Edit/tabela';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { formatarValorIntl } from 'utils/formatValueIntl';
import ConfirmModal from 'ui-component/ConfirmModal';

function ColorView() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formOpen, setFormOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [openSnakBarCancelado, setOpenSnackBarCancelado] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const api = useApi();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const [rows, setRows] = useState([]);
    const get = async () => {
        try {
            const { data } = await api.getAllPrimaryProducts();
            setLoading(false);
            setData(data);
            const row = data.map((item) => ({
                ...item,
                id: item.id,
                productName: item.productName,
                productPrice: formatarValorIntl(item.productPrice),
                gpColor: item?.groupColor?.name
            }));
            setRows(row);
        } catch (error) {
            console.log('🚀 ~ get ~ error:', error);
        }
    };

    const deleteMp = async () => {
        try {
            await api.deleteProduct(currentRowId);
            toast.success('Matéria prima deletada com sucesso!');
            get();
        } catch (error) {
            console.log(error.message);
            toast.warn('Esta matéria prima ainda está associada.');
        } finally {
            setOpen(false);
        }
    };

    useEffect(() => {
        get();
    }, []);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 1,
            sortable: true
        },
        {
            field: 'productName',
            headerName: 'Descrição',
            flex: 2,
            sortable: true
        },
        {
            field: 'productPrice',
            headerName: 'Preço',
            flex: 1,
            sortable: true
        },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 1,
            sortable: false,
            renderCell: ({ row }) => (
                <div>
                    <Link to={`/main/editMp/${row?.id}`}>
                        <Tooltip title="Editar">
                            <IconButton>
                                <IconPencil />
                            </IconButton>
                        </Tooltip>
                    </Link>
                    <Tooltip
                        title="Excluir"
                        onClick={() => {
                            setCurrentRowId(row.id);
                            setOpenConfirmModal(true);
                        }}
                    >
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        }
    ];
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [currentRowId, setCurrentRowId] = useState(null);
    return (
        <MainCard title="Matéria Prima">
            <Grid container spacing={gridSpacing}>
                <ConfirmModal
                    button1={'Cancelar'}
                    button2={'Confirmar'}
                    firstMessage={'Deseja mesmo deletar essa matéria prima?'}
                    secondMessage={'Após essa ação, o item será excluido permanentemente'}
                    open={openConfirmModal}
                    setOpen={setOpenConfirmModal}
                    func={deleteMp}
                />
                {!formOpen && (
                    <Grid item container sm={12} columns={12} spacing={2} sx={{ justifyContent: 'center', position: 'relative' }}>
                        {loading && <CircularProgress sx={{ marginTop: '2em', marginLeft: '2em' }} aria-label="progress" />}
                        <Box
                            sx={{
                                width: '100%',
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '5px'
                            }}
                        >
                            <Button
                                onClick={() => {
                                    setFormOpen(!formOpen);
                                }}
                                variant="contained"
                                sx={{ textTransform: 'none', position: 'absolute', top: '-40px', right: '20px' }}
                            >
                                Cadastrar nova MP
                            </Button>
                        </Box>
                        {!loading && data.length > 0 && (
                            <DataGrid rows={rows} columns={columns} pageSize={5} rowsPerPageOptions={[25, 50, 100]} pagination />
                        )}
                    </Grid>
                )}
                {formOpen && <NewMpForm />}
            </Grid>
        </MainCard>
    );
}

export default ColorView;
