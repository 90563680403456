import { useContext } from 'react';
import { createContext, useState } from 'react';

export const BudgetPdfContext = createContext();

export const BudgetPdfProvider = ({ children }) => {
    const [currentDataContext, setCurrentDataContext] = useState({});
    const [sumContext, setSumContext] = useState('');
    const [observationArrayContext, setObservationArrayContext] = useState([]);
    const [currentQuestionsContext, setCurrentQuestionsContext] = useState([]);

    return (
        <BudgetPdfContext.Provider
            value={{
                currentDataContext,
                setCurrentDataContext,
                sumContext,
                setSumContext,
                observationArrayContext,
                setObservationArrayContext,
                currentQuestionsContext,
                setCurrentQuestionsContext
            }}
        >
            {children}
        </BudgetPdfContext.Provider>
    );
};

const useBudgetPdf = () => {
    const context = useContext(BudgetPdfContext);

    // Garantia de que o hook está sendo utilizado dentro do Provider
    if (!context) {
        throw new Error('useBudgetPdf deve ser usado dentro do BudgetPdfProvider');
    }

    return context;
};

export { useBudgetPdf };
