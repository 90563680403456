import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

import AuthWrapper1 from '../../pages/authentication/AuthWrapper1';
import AuthCardWrapper from '../../pages/authentication/AuthCardWrapper';
import EditUserForm from './EditUserForm';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { useState } from 'react';
const EditUserFormPrincipal = (props) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    //posso pegar id aqui

    return (
        <AuthWrapper1>
            <Grid container direction="column" justifyContent="flex-end">
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction={matchDownSM ? 'column-reverse' : 'row'}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item>
                                                <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                    <Typography color="primary" gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
                                                        Editar usuário
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <EditUserForm id={props.id} onClose={props.onClose} get={props.get} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AuthWrapper1>
    );
};

export default EditUserFormPrincipal;
