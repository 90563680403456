import { formatarValorIntl } from 'utils/formatValueIntl';

export function DinamicTable({ dados }) {
    const styles = {
        table: { width: '100%', borderCollapse: 'collapse', margin: '10px' },
        cell: { borderBottom: '1px solid #999', padding: '5px', wordWrap: 'break-word', display: 'flex', justifyContent: 'flex-start' }
    };

    return (
        <div>
            <div style={styles.table}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ ...styles.cell, width: '5%' }}>
                        <p style={{ margin: 0 }}>Item</p>
                    </div>

                    <div style={{ ...styles.cell, width: '77%' }}>
                        <p style={{ margin: 0 }}>Desc.Produto</p>
                    </div>
                    {/* <div style={{ ...styles.cell, width: '12%' }}>
                        <p style={{ margin: 0 }}>Unitário</p>
                    </div> */}
                    <div style={{ ...styles.cell, width: '12%' }}>
                        <p style={{ margin: 0 }}>Total</p>
                    </div>
                </div>

                {dados?.map((item, index) => (
                    <div
                        key={index}
                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-end' }}
                    >
                        <div style={{ ...styles.cell, width: '5%' }}>
                            <p style={{ margin: 0 }}>{index + 1}</p>
                        </div>

                        <div style={{ ...styles.cell, width: '77%' }}>
                            <div>
                                <p style={{ fontWeight: 'bold', margin: 0 }}>{item.desc}</p>
                                <p style={{ fontSize: '14px', color: '#343634', margin: 0 }}>{item?.note}</p>
                            </div>
                        </div>
                        {/* <div style={{ ...styles.cell, width: '12%' }}>
                            <p style={{ margin: 0 }}>{formatarValorIntl(item.valorU)}</p>
                        </div> */}
                        <div style={{ ...styles.cell, width: '12%' }}>
                            <p style={{ margin: 0 }}>{formatarValorIntl(item.valorT)}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
