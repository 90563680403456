import React, { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import {
    Box,
    CircularProgress,
    Grid,
    Button,
    Tooltip,
    IconButton,
    Dialog,
    DialogActions,
    Snackbar,
    Alert,
    DialogContent,
    DialogContentText,
    Typography,
    TextField,
    Switch
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconPencil } from '@tabler/icons';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import { useApi } from 'Service/axios';
import Autocomplete from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';

function QuestionnairesView() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedQuiz, setSelectedQuiz] = useState(null);
    const api = useApi();

    const get = async () => {
        try {
            const response = await api.getAllQuiz();
            setLoading(false);
            setData(response.data);
        } catch (err) {
            console.log(err.message);
        }
    };

    useEffect(() => {
        get();
    }, []);

    const handleDeleteQuiz = async (event, id) => {
        event.preventDefault();
        try {
            await api.deleteQuestionary(id);
        } catch (error) {
            window.alert('O quiz ainda possui perguntas cadastradas');
        }
        get();
    };

    async function handlwSwitch(row) {
        console.log(row);
        const toastId = toast.info('Atualizando...');
        try {
            await api.changeEnabledQuiz(row?.id, !row?.enabled);
            get();
            toast.update(toastId, {
                render: 'Atualizado com sucesso!',
                type: 'success',
                isLoading: false,
                autoClose: 2000
            });
        } catch (error) {
            console.log('🚀 ~ handlwSwitch ~ error:', error);
            toast.update(toastId, {
                render: 'Erro ao atualizar',
                type: 'error',
                isLoading: false,
                autoClose: 2000
            });
        }
    }

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        {
            field: 'product',
            headerName: 'Produto Final',
            flex: 1,
            valueGetter: (params) => params.row.product?.productName
        },
        {
            field: 'groupDescription',
            headerName: 'Grupo',
            flex: 1,
            valueGetter: (params) => params?.row?.product?.productGroups[0]?.groupDescription
        },
        {
            field: 'habilitado',
            headerName: 'Habilitado',
            flex: 1,
            renderCell: (params) => <Switch checked={params?.row?.enabled} onClick={() => handlwSwitch(params.row)} />
        },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 0.5,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Link to={`/main/questionaires/edit/${params.row.id}/${params.row.product?.productName}`}>
                        <Tooltip title="Editar">
                            <IconButton>
                                <IconPencil />
                            </IconButton>
                        </Tooltip>
                    </Link>
                    <Tooltip title="Excluir" onClick={(event) => handleClickOpen(event, params.row.id)}>
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            )
        }
    ];

    const [open, setOpen] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [openSnackBarCancelado, setOpenSnackBarCancelado] = useState(false);
    const [quizToDelete, setQuizToDelete] = useState(null);

    const handleClickOpen = (event, id) => {
        event.preventDefault();
        setQuizToDelete(id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenSnackBarCancelado(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
        setOpenSnackBarCancelado(false);
    };

    const handleConfirmDelete = async (e) => {
        await handleDeleteQuiz(e, quizToDelete);
        setOpen(false);
        setOpenSnackBar(true);
    };

    return (
        <MainCard title="Questionários">
            <Grid container spacing={gridSpacing}>
                {loading && <CircularProgress sx={{ marginTop: '2em', marginLeft: '2em' }} aria-label="progress" />}

                <Box sx={{ maxHeight: 500, marginTop: '40px', width: '100%' }}>
                    <DataGrid
                        rows={
                            selectedQuiz
                                ? data.filter((quiz) => quiz?.product?.productName.includes(selectedQuiz?.product?.productName))
                                : data
                        }
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                    />
                </Box>
            </Grid>
            <Dialog open={open} onClose={handleClose} aria-describedby="alert-dialog-slide-description">
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">Tem certeza que quer deletar esse quiz?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button variant="contained" onClick={handleConfirmDelete}>
                        Deletar
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={openSnackBar} autoHideDuration={1000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                    Deletado com sucesso!
                </Alert>
            </Snackbar>
            <Snackbar open={openSnackBarCancelado} autoHideDuration={1000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                    Cancelado
                </Alert>
            </Snackbar>
        </MainCard>
    );
}

export default QuestionnairesView;
