import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Logo from '../../../assets/images/Logos/sulmetais.png';
import { Paper, TableContainer } from '@mui/material';
import { formatCNPJ, formatHora, formatarData, formatarValor } from 'utils/regex';
import { useEffect } from 'react';
import { useState } from 'react';
import { formatarMoeda } from 'utils/formatMoeda';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

let total;

function DinamicTable({ dados, asnwerMaterial, answerPerf }) {
    const styles = {
        table: { width: '100%', borderCollapse: 'collapse', margin: '10px' },
        cell: { borderBottom: '1px solid #eee', padding: '5px', wordWrap: 'break-word' }
    };

    return (
        <View>
            <View style={styles.table}>
                {dados?.map((item, index) => (
                    <>
                        <View key={index} style={{ flexDirection: 'row', backgroundColor: '#ccc', borderRadius: '5px' }}>
                            <View style={{ ...styles.cell, width: '20%' }}>
                                <Text>ID: {item?.id}</Text>
                            </View>
                            <View style={{ ...styles.cell, width: '20%' }}>
                                <Text>R.O: {item?.operationalResult} %</Text>
                            </View>
                            <View style={{ ...styles.cell, width: '20%' }}>
                                <Text>GRUPO DE COR: {item?.groupColorName}</Text>
                            </View>
                            <View style={{ ...styles.cell, width: '20%' }}>
                                <Text>METROAGEM INICIAL: {item?.initialMeter} [m²]</Text>
                            </View>
                            <View style={{ ...styles.cell, width: '20%' }}>
                                <Text>METROAGEM FINAL: {item?.finalMeter} [m²]</Text>
                            </View>
                        </View>
                        <View
                            style={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'column',
                                gap: '10px'
                            }}
                        >
                            <View
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    marginTop: '5px',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Text style={{ backgroundColor: '#ccc', padding: '5px' }}>MATERIAL:</Text>
                                <Text style={{ fontWeight: 'bold', textTransform: 'uppercase', border: '1px solid #ccc', padding: '4px' }}>
                                    {item?.questionMaterial}
                                </Text>
                            </View>

                            <View style={{ display: 'flex', width: '100%', flexDirection: 'row', flexWrap: 'wrap', gap: '5px' }}>
                                {item.questionsMaterial?.map((_, i, array) => (
                                    <Text key={i} style={{ width: '30%' }}>
                                        {asnwerMaterial[index][i]?.description}
                                    </Text>
                                ))}
                            </View>
                            <View style={{ borderBottom: '1px solid #eee' }}></View>
                            {item?.questionsPerfuracao && item?.questionsPerfuracao.length > 0 ? (
                                <>
                                    <View
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                            marginTop: '5px',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Text style={{ backgroundColor: '#ccc', padding: '5px' }}>PERFURAÇÃO:</Text>
                                        <Text
                                            style={{
                                                fontWeight: 'bold',
                                                textTransform: 'uppercase',
                                                border: '1px solid #ccc',
                                                padding: '4px'
                                            }}
                                        >
                                            {item?.questionPerfuracao}
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                            gap: '5px',
                                            marginBottom: '10px'
                                        }}
                                    >
                                        {item.questionsPerfuracao?.map((_, i, array) => (
                                            <Text style={{ width: '30%' }} key={i}>
                                                {answerPerf[index][i]?.description}
                                            </Text>
                                        ))}
                                    </View>
                                </>
                            ) : null}
                        </View>
                    </>
                ))}
            </View>
        </View>
    );
}

const styles = {
    page: {
        backgroundColor: '#fff',
        fontSize: 10,
        fontWeight: 'normal',
        paddingTop: 15,
        paddingBottom: 15,
        paddingHorizontal: 15,
        rap: 'wrap'
    },
    header: {
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    image: {
        width: 200,
        backgroundSize: 'cover'
    },
    text1: {
        fontSize: '12px'
    },
    box: {
        border: '1px solid #ddd',
        padding: 10,
        margin: 2,
        borderRadius: 5
    },
    between: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    labelArea: {
        backgroundColor: '#ccc',
        padding: '8px',
        borderRadius: 5
    },
    table: { margin: 10 },
    row: { flexDirection: 'row', borderBottom: '1 solid #000' },
    cell: { flexGrow: 1, padding: 5 }
};

const PdfRecord = ({ currentRow }) => {
    const [roData, setRoData] = useState({});
    const [roFormula, setRoFormula] = useState([]);
    const [roQuestion, setRoQuestion] = useState({});
    const [asnwerMaterial, setAnswerMaterial] = useState([]);
    const [answerPerf, serAnswerPerf] = useState([]);
    const api = useApi();

    async function getAllAnswersFunc(ro) {
        const toastId = toast.loading('Carregando relatório...');
        try {
            const { data } = await api.getAllAnswers();

            const answersMaterial = ro.map((formula) => {
                return formula.questionsMaterial.map((item) => {
                    const finds = data.find((answer) => answer.id === item.id);
                    return { ...item, ...finds };
                });
            });

            const answerPerf = ro.map((formula) => {
                return formula.questionsPerfuracao.map((item) => {
                    const finds = data.find((answer) => answer.id === item.id);
                    return { ...item, ...finds };
                });
            });
            serAnswerPerf(answerPerf);

            setAnswerMaterial(answersMaterial);
            toast.update(toastId, {
                render: 'Relatório carregado com sucesso!',
                type: 'success',
                isLoading: false,
                autoClose: 2000
            });
        } catch (error) {
            console.log('🚀 ~ getAllAnswers ~ error:', error);
            toast.update(toastId, {
                render: 'Erro ao carregar relatório!',
                type: 'error',
                isLoading: false,
                autoClose: 2000
            });
        }
    }

    async function getReportById() {
        try {
            const { data } = await api.getReportRo(currentRow?.id);

            setRoData(data.ros[0]);
            const orderByInitialMeter = data.roFormulas?.sort((a, b) => a.initialMeter - b.initialMeter);
            setRoFormula(orderByInitialMeter);
            setRoQuestion(data.questions[0]);
            getAllAnswersFunc(orderByInitialMeter);
        } catch (error) {
            console.log('🚀 ~ getReportById ~ error:', error);
        }
    }

    useEffect(() => {
        getReportById();
    }, [currentRow]);

    return (
        <Document>
            <Page size="A4" orientation="landscape" style={styles.page}>
                {answerPerf.length && asnwerMaterial.length ? (
                    <>
                        <View style={styles.header}>
                            <Image src={Logo} style={styles.image} alt="logo" />
                        </View>

                        <View
                            style={{
                                display: 'flex',
                                gap: '2px',
                                textAlign: 'center',
                                marginTop: '18px',
                                border: '1px solid #ddd',
                                padding: 5,
                                borderRadius: 5,
                                fontSize: '13px',
                                backgroundColor: '#ccc'
                            }}
                        >
                            <Text style={{ textTransform: 'uppercase' }}>Resultado operacional do {currentRow.quiz}</Text>
                        </View>

                        <View style={{ marginTop: '15px', marginBottom: '15px' }}>
                            <View>
                                <Text style={{ fontSize: '13px' }}>
                                    CUSTO FIXO: <Text style={{ textTransform: 'uppercase' }}>{roData?.fixed_cost}</Text>
                                </Text>
                                <Text style={{ fontSize: '13px' }}>
                                    GRUPO: <Text style={{ textTransform: 'uppercase' }}> {roData?.groupDescription}</Text>
                                </Text>
                                <Text style={{ fontSize: '13px' }}>
                                    FÓRMULA PARA CÁLCULO:{' '}
                                    <Text style={{ textTransform: 'uppercase' }}>
                                        {' '}
                                        {roData?.question1Id !== 0 ? roQuestion.description : roData.value1} x{' '}
                                        {roData?.question2Id !== 0 ? roQuestion.description : roData.value2}
                                    </Text>
                                </Text>
                            </View>
                        </View>

                        <View
                            style={{
                                display: 'flex',
                                gap: '2px',
                                textAlign: 'center',
                                border: '1px solid #ddd',
                                padding: 5,
                                borderRadius: 5,
                                backgroundColor: '#ccc'
                            }}
                        >
                            <Text style={{ fontSize: '13px' }}>FÓRMULAS</Text>
                        </View>
                        <View style={{ marginTop: '10px' }}>
                            {answerPerf.length && asnwerMaterial.length && (
                                <DinamicTable dados={roFormula} answerPerf={answerPerf} asnwerMaterial={asnwerMaterial} />
                            )}
                        </View>
                    </>
                ) : (
                    <View style={styles.header}>
                        <Image src={Logo} style={styles.image} alt="logo" />
                    </View>
                )}
            </Page>
        </Document>
    );
};

export default PdfRecord;
