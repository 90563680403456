import { Autocomplete, Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useEffect } from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import ActionButtons from './ActionsButtons';
import { useNavigate } from 'react-router';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import { useParams } from 'react-router';
import InsertRo from './InsertRo';
import EditRo from './DrawerEditRo';
import LoadingModal from 'ui-component/LoadingModal';
import { toast } from 'react-toastify';
import SkeletonRo from './SkeletonRo';

const RoData = () => {
    const { id, quizId } = useParams();
    const navigate = useNavigate();
    const [dataQuiz, setDataQuiz] = useState([]);
    const [currentRo, setCurrentRo] = useState([]);
    const [openInsert, setOpenInsert] = useState(false);
    const [row, setRow] = useState([]);
    const [currentRow, setCurrentRow] = useState('');
    const [currentIndex, setCurrentIndex] = useState('');

    const [openEditRo, setOpenEditRo] = useState(false);

    const [value1, setValue1] = useState('');
    const [value2, setValue2] = useState('');
    const [question1Id, setQuestion1Id] = useState('');
    const [question2Id, setQuestion2Id] = useState('');

    const [valueFixed, setValueFixed] = useState('');
    const [allQuestions, setAllQuestions] = useState([]);

    const [currentQuizData, setCurrentQuizData] = useState({});
    const [currentRoFormula, setCurrentRoFormula] = useState([]);
    const [loading, setLoading] = useState(false);
    const [closeQuestion, setCloseQuestion] = useState([]);
    const api = useApi();
    const getQuiz = async (id) => {
        setLoading(true);
        try {
            const { data } = await api.questionCloseById(id);
            const filterQuestion = data.filter((item) => item.type === 'Fechada');
            setCloseQuestion(filterQuestion);
            setDataQuiz(data);
            setAllQuestions(data);
            setLoading(false);
            setCurrentQuizData(data);
        } catch (err) {
            console.log(err.message);
        }
    };

    const getCurrentRo = async () => {
        try {
            const { data } = await api.getNewRoById(id);
            console.log('🚀 ~ file: RoData.js:66 ~ getCurrentRo ~ data:', data);
            setCurrentRo(data);
            getQuiz(data.quizId);
        } catch (error) {
            console.log('🚀 ~ file: RoData.js:35 ~ getCurrentRo ~ error:', error);
        }
    };

    const getCurrentFormula = async () => {
        try {
            const { data } = await api.getNewRoFormula(id);
            console.log('🚀 ~ file: RoData.js:117 ~ getCurrentFormula ~ data:', data);
            const orderRoFormula = data.sort((a, b) => a.id - b.id);
            const row = orderRoFormula?.map((item, index) => {
                return {
                    ...item,
                    id: item.id,
                    cor: item?.groupColorName,
                    MInicial: item.initialMeter,
                    Mfinal: item.finalMeter,
                    ro: item.operationalResult + '%',
                    gp: item?.groupColor?.name,
                    index: index,
                    gpColor: item.groupColorName,
                    material: item.questionMaterial,
                    perfuracao: item.questionPerfuracao ?? '--',
                    type: item.questionsPerfuracao && item.questionsPerfuracao.length > 0 ? 'PERFURADO' : 'LISO'
                };
            });

            setRow(row);
        } catch (error) {
            console.log('🚀 ~ file: RoData.js:118 ~ getCurrentFormula ~ error:', error);
        }
    };

    const [typeInputStart, setTypeInputStart] = useState('number');
    const [typeInputEnd, setTypeInputEnd] = useState('number');

    useEffect(() => {
        setTypeInputStart(currentRo?.value1 === 0 ? 'select' : 'number');
        setTypeInputEnd(currentRo?.value2 === 0 ? 'select' : 'number');
        setValue1(currentRo.value1 || '');
        setValue2(currentRo.value2 || '');
        setQuestion1Id(Number(currentRo.question1Id) || '');
        setQuestion2Id(Number(currentRo.question2Id) || '');
        setValueFixed(currentRo?.fixed_cost || '');
    }, [currentRo]);

    useEffect(() => {
        getCurrentRo();
        // getQuiz();
        getCurrentFormula();
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5, type: 'number', align: 'start', headerAlign: 'start' },
        { field: 'cor', headerName: 'Grupo de cor', flex: 0.5 },
        { field: 'MInicial', headerName: 'Metragem Inicial [m²]', flex: 1, align: 'center', headerAlign: 'center' },
        { field: 'Mfinal', headerName: 'Metragem Final [m²]', flex: 1, align: 'center', headerAlign: 'center' },
        { field: 'ro', headerName: 'R.O', flex: 1, type: 'number', align: 'center', headerAlign: 'center' },
        {
            field: 'acao',
            headerName: 'Ações',
            flex: 0.5,
            sortable: false,
            renderCell: (params) => (
                <ActionButtons
                    row={params.row}
                    currentRo={currentRo}
                    getRo={getCurrentRo}
                    setCurrentRow={setCurrentRow}
                    setOpenEditRo={setOpenEditRo}
                    setCurrentIndex={setCurrentIndex}
                    setCurrentRoFormula={setCurrentRoFormula}
                    getCurrentRoFormula={getCurrentFormula}
                />
            )
        }
    ];

    const handleInputChange = (e, inputType) => {
        const value = e.target.value;
        if (inputType === 'start') {
            setValue1(value);
        } else if (inputType === 'end') {
            setValue2(value);
        }
    };

    const handleSelectChange = (e, inputType) => {
        const value = e.target.value;
        if (inputType === 'start') {
            setQuestion1Id(value);
        } else if (inputType === 'end') {
            setQuestion2Id(value);
        }
    };

    const handleSubmit = async () => {
        // const payload = {

        //     quiz: currentRo.quiz,
        //     roFormula: currentRo.roFormula
        // };

        const payload = {
            id: 25,
            fixed_cost: valueFixed,
            value1: typeInputStart !== 'select' ? parseInt(value1) : 0,
            value2: typeInputEnd !== 'select' ? parseInt(value2) : 0,
            question1Id: typeInputStart === 'select' ? question1Id : null,
            question2Id: typeInputEnd === 'select' ? question2Id : null
        };
        console.log(payload);

        try {
            await api.editNewRo(currentRo.id, payload);
            toast.success('Atualizado com sucesso');
        } catch (error) {
            console.log('🚀 ~ file: RoData.js:185 ~ handleSubmit ~ error:', error);
            toast.error('Erro ao atualizar');
        }
    };

    return (
        <MainCard title={currentRo?.quiz?.product?.productName}>
            <InsertRo
                open={openInsert}
                setOpen={setOpenInsert}
                currentRo={currentRo}
                closeQuestions={closeQuestion}
                getCurrentRo={getCurrentFormula}
                currentQuizData={currentQuizData}
            />
            <EditRo
                currentRow={currentRow}
                open={openEditRo}
                setOpen={setOpenEditRo}
                currentRo={currentRo}
                closeQuestions={closeQuestion}
                getCurrentRo={getCurrentFormula}
                currentIndex={currentIndex}
                currentRoFormula={currentRoFormula}
            />
            <Grid container spacing={gridSpacing} sx={{ padding: '10px', display: 'flex', flexDirection: 'column' }}>
                {loading ? (
                    <SkeletonRo />
                ) : (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '20px',
                                marginBottom: '10px',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                                <TextField
                                    label="Custo fixo"
                                    value={valueFixed}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>
                                    }}
                                    onChange={(e) => setValueFixed(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <Button variant="contained" onClick={handleSubmit}>
                                    Salvar
                                </Button>
                            </Box>
                            <Box>
                                <Button variant="contained" sx={{ width: '200px' }} onClick={() => setOpenInsert(true)}>
                                    Inserir
                                </Button>
                            </Box>
                        </Box>
                        <Box>
                            <span>Fórmula para calcular o R.O</span>
                        </Box>
                        <Box>
                            <Box sx={{ display: 'flex', gap: '20px', marginBottom: '40px', marginTop: '10px' }}>
                                <FormControl>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {typeInputStart === 'select' ? (
                                            <>
                                                <InputLabel id="Minitial" InputLabelProps={{ shrink: true }}>
                                                    Opção 01
                                                </InputLabel>
                                                <Select
                                                    sx={{ width: '250px' }}
                                                    value={question1Id}
                                                    labelId="Minitial"
                                                    label=" Opção 01"
                                                    onChange={(e) => handleSelectChange(e, 'start')}
                                                >
                                                    <MenuItem value="">Selecione uma opção</MenuItem>
                                                    {allQuestions?.map((item) => (
                                                        <MenuItem key={item?.id} value={item?.id}>
                                                            {item?.description}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </>
                                        ) : (
                                            <TextField
                                                sx={{ width: '250px' }}
                                                value={value1}
                                                type="number"
                                                label="Opção 01"
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(e) => handleInputChange(e, 'start')}
                                            />
                                        )}
                                        <div style={{ display: 'flex', gap: '10px', cursor: 'pointer', marginLeft: '20px' }}>
                                            <PinOutlinedIcon
                                                onClick={() => setTypeInputStart('number')}
                                                color={typeInputStart === 'number' ? 'primary' : ''}
                                            />
                                            <FormatListBulletedIcon
                                                onClick={() => setTypeInputStart('select')}
                                                color={typeInputStart === 'select' ? 'primary' : ''}
                                            />
                                        </div>
                                    </div>
                                </FormControl>
                                <h4>x</h4>
                                <FormControl>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {typeInputEnd === 'select' ? (
                                            <>
                                                <InputLabel id="MEnd" InputLabelProps={{ shrink: true }}>
                                                    Opção 02
                                                </InputLabel>
                                                <Select
                                                    sx={{ width: '250px' }}
                                                    value={question2Id}
                                                    labelId="MEnd"
                                                    label="Opção 02"
                                                    onChange={(e) => handleSelectChange(e, 'end')}
                                                >
                                                    <MenuItem value="">Selecione uma opção</MenuItem>
                                                    {allQuestions?.map((item) => (
                                                        <MenuItem key={item?.id} value={item?.id}>
                                                            {item?.description}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </>
                                        ) : (
                                            <TextField
                                                sx={{ width: '250px' }}
                                                value={value2}
                                                type="number"
                                                label="Opção 02"
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(e) => handleInputChange(e, 'end')}
                                            />
                                        )}
                                        <div style={{ display: 'flex', gap: '10px', cursor: 'pointer', marginLeft: '20px' }}>
                                            <PinOutlinedIcon
                                                onClick={() => setTypeInputEnd('number')}
                                                color={typeInputEnd === 'number' ? 'primary' : ''}
                                            />
                                            <FormatListBulletedIcon
                                                onClick={() => setTypeInputEnd('select')}
                                                color={typeInputEnd === 'select' ? 'primary' : ''}
                                            />
                                        </div>
                                    </div>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box style={{ height: 500, width: '100%' }}>
                            <DataGrid rows={row} columns={columns} hideFooter={true} />
                        </Box>
                    </>
                )}
            </Grid>
        </MainCard>
    );
};

export default RoData;
