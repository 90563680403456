import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';

import LoginRoutes from './LoginRoute';
import RegisterRoute from './RegisterRoute';
import RecoveryPasswordRoutes from './RecoverPassword';
import PdfRoute from './PdfRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([MainRoutes, LoginRoutes, RegisterRoute, RecoveryPasswordRoutes, PdfRoute]);
}
