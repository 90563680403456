import { Button, CircularProgress, Grid, IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import HoverSocialCard from './components/HoverCard';
import { Box, display } from '@mui/system';
import GridOnIcon from '@mui/icons-material/GridOn';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { numberFormatCompact } from 'utils/regex';
import { DataGrid } from '@mui/x-data-grid';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useNavigate } from 'react-router';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import StatusModal from './components/StatusModal';
import { toast } from 'react-toastify';
import { adjustDateTimeForBrazil } from 'utils/dataFusoBr';
import CardDark from './components/CardDark';

const ProjectMo = () => {
    const navigate = useNavigate();
    const api = useApi();
    const [row, setRow] = useState([]);
    const [openStatus, setOpenStatus] = useState(false);
    const [currentRow, setCurrentRow] = useState({});
    const [loading, setLoading] = useState(false);

    const firstRequest = async () => {
        setLoading(true);
        try {
            const res = await api.postFirstRequest();
            console.log('🚀 ~ getAllPcpProjects ~ data:', res);
            if (res.status === 200) {
                secondRequest();
            }
        } catch (error) {
            console.log('🚀 ~ getAllPcpProjects ~ error:', error);
        }
    };

    const secondRequest = async () => {
        try {
            const res = await api.postSecondRequest();
            if (res.status === 200) {
                thirdRequest();
            }
        } catch (error) {
            console.log('🚀 ~ secondRequest ~ error:', error);
        }
    };

    const thirdRequest = async () => {
        try {
            await api.postThirdRequest();
            toast.success('Atualizado com sucesso!');
            setLoading(false);
            getData();
        } catch (error) {
            console.log('🚀 ~ thirdRequest ~ error:', error);
        }
    };

    const getData = async () => {
        try {
            const { data } = await api.getAllPcpProducts();
            const rows = data.map((item) => ({
                ...item,
                id: item?.id,
                cod: item?.comInCodigo,
                alt: item?.comStAlternativo,
                desc: item?.comStDescricao,
                un: item?.comStUnidade,
                qtd: item?.comQtdCalculada,
                material: item?.material,
                esp: item?.espessura,
                larg: item?.largura ? item?.largura : '0',
                color: item?.cor,
                status: item?.status ? item?.status : '-'
            }));
            setRow(rows);
        } catch (error) {
            console.log('🚀 ~ getData ~ error:', error);
        }
    };
    useEffect(() => {
        getData();
    }, []);

    const columns = [
        { field: 'cod', headerName: 'COD', width: 90 },
        { field: 'alt', headerName: 'Alternativo', width: 180 },
        { field: 'desc', headerName: 'Descrição', width: 500 },
        { field: 'un', headerName: 'UN', width: 70 },
        {
            field: 'qtd',
            headerName: 'QTD Calc.',
            width: 90,
            renderCell: (params) => {
                return <p>{params.row.qtd.toFixed(2)}</p>;
            }
        },
        { field: 'material', headerName: 'Material', width: 70 },
        { field: 'esp', headerName: 'Espessura', width: 70 },
        { field: 'larg', headerName: 'Largura', width: 70 },
        { field: 'color', headerName: 'Cor', width: 70 },
        { field: 'status', headerName: 'Status', width: 90 },
        {
            field: 'actions',
            headerName: 'Ações',
            width: 120,
            renderCell: (params) => {
                return (
                    <Box>
                        <Tooltip title="Editar">
                            <IconButton
                                onClick={() => {
                                    setOpenStatus(true);
                                    setCurrentRow(params.row);
                                }}
                            >
                                <DriveFileRenameOutlineIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Pedidos Vinculados">
                            <IconButton onClick={() => navigate(`/main/pcp/mp-em-projetos/${params.row.cod}`)}>
                                <FormatListBulletedIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                );
            }
        }
    ];

    return (
        <MainCard title={'Matéria prima em Projetos'}>
            <Grid container spacing={gridSpacing} sx={{ position: 'relative' }}>
                <Box sx={{ display: 'flex', gap: '20px', position: 'absolute', top: '-80px', right: '20px', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px' }}>
                        <Button variant="contained" onClick={firstRequest}>
                            {loading ? 'Carregando ...' : 'Atualizar dados'}
                        </Button>
                        {row.length > 0 && <p>{adjustDateTimeForBrazil(row[0]?.updatedAt)}</p>}
                    </div>
                    <CardDark
                        icon={<GridOnIcon />}
                        name={'Abertas'}
                        value={row.filter((item) => item.status === 'ABERTO').length}
                        title="card"
                    />
                    <CardDark
                        icon={<GridOnIcon />}
                        name={'Prévia'}
                        value={row.filter((item) => item.status === 'PR').length}
                        title="card"
                    />
                </Box>
                <StatusModal open={openStatus} setOpen={setOpenStatus} currentRow={currentRow} get={getData} />

                <Box sx={{ width: '100%', margin: '20px auto' }}>
                    {row.length === 0 ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <div style={{ marginTop: '1.25rem', height: '500px', width: '100%' }}>
                            <DataGrid rows={row} getRowHeight={() => 'auto'} columns={columns} hideFooter />
                        </div>
                    )}
                </Box>
            </Grid>
        </MainCard>
    );
};

export default ProjectMo;
