import { Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { IconPencil } from '@tabler/icons';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import DrawerAdd from './components/DrawerAdd';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import ConfirmModal from 'ui-component/ConfirmModal';
import { toast } from 'react-toastify';
import DrawerEdit from './components/DrawerEdit';
import { DataGrid } from '@mui/x-data-grid';

const Payments = () => {
    const api = useApi();
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [currentItem, setCurrentItem] = useState([]);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [row, setRow] = useState([]);

    const getPayments = async () => {
        try {
            const { data } = await api.getPaymentsTerms();
            const row = data.map((item) => ({
                id: item.id,
                type: item.name,
                percent: item.percentage
            }));
            console.log('🚀 ~ file: index.js:34 ~ row ~ row:', row);
            setRow(row);
        } catch (error) {
            console.log('🚀 ~ file: index.js:29 ~ getPayments ~ error:', error);
        }
    };

    const deleteItem = async () => {
        try {
            await api.deletePaymentsTerms(currentItem.id);
            getPayments();
            toast.success('Item deletado com sucesso');
        } catch (error) {
            console.log('🚀 ~ file: index.js:31 ~ deleteItem ~ error:', error);
        }
    };

    useEffect(() => {
        getPayments();
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'type', headerName: 'Tipo', flex: 1 },
        { field: 'percent', headerName: 'Porcentagem', flex: 1 },
        {
            field: 'actions',
            headerName: 'Ações',
            sortable: false,
            flex: 1,
            renderCell: (params) => (
                <div>
                    <Tooltip title="Editar">
                        <IconButton
                            onClick={() => {
                                console.log(params);
                                setCurrentItem(params.row);
                                setOpenEditModal(true);
                            }}
                        >
                            <IconPencil />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Excluir">
                        <IconButton
                            onClick={() => {
                                setCurrentItem(params.row);
                                setShowConfirmDelete(true);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        }
    ];

    return (
        <div>
            <DrawerEdit open={openEditModal} setOpen={setOpenEditModal} currentItem={currentItem} get={getPayments} />
            <ConfirmModal
                firstMessage={'Deseja mesmo deletar esse item ?'}
                secondMessage={'Os dados não poderão ser recuperados'}
                button1={'Cancelar'}
                button2={'Deletar'}
                func={deleteItem}
                open={showConfirmDelete}
                setOpen={setShowConfirmDelete}
            />
            <DrawerAdd open={openAddModal} setOpen={setOpenAddModal} get={getPayments} />
            <MainCard title="Condições de pagamento">
                <Grid container spacing={gridSpacing} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ margin: '20px' }}>
                        <Button variant="contained" sx={{ textTransform: 'none' }} onClick={() => setOpenAddModal(true)}>
                            Cadastrar nova condição
                        </Button>
                    </div>
                    {row.length > 0 && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <DataGrid rows={row} columns={columns} sx={{ maxWidth: '1500px' }} hideFooter checkboxSelection={false} />
                        </div>
                    )}
                </Grid>
            </MainCard>
        </div>
    );
};

export default Payments;
