import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import ActionsButton from './Components/ActionsButton';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import DrawerInsert from './Components/DrawerInsert';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { Table, Column, HeaderCell, Cell, ColumnGroup } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';
import { Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { formatarData, formatarValor } from 'utils/regex';
import { PDFDownloadLink } from '@react-pdf/renderer';
import GeneretePdf from './Components/GeneretePdf';
import LoopIcon from '@mui/icons-material/Loop';
import { toast } from 'react-toastify';
import MenuTable from './Components/MenuTable';
import SkeletonTable from 'ui-component/skeletonTable';
import { ModalLoadAnimated } from 'ui-component/LoadBw';
import { ObservationManager } from './Components/ObservationManager';
import CollapsibleTable from './Components/Collapsible';

const SalesBudget = () => {
    const api = useApi();
    const navigate = useNavigate();
    const [openDrawerInsert, setOpenDrawerInsert] = useState(false);
    const [data, setData] = useState([]);
    const [currentClientModel, setCurrentClientModel] = useState([]);
    const [allcustomer, setAllCustomer] = useState([]);
    const [currentClientData, setCurrentClientData] = useState([]);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openObservation, setOpenObservation] = useState(false);

    const [selectedField, setSelectedField] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);

    //column datadrid

    const role = localStorage.getItem('role');
    const roleParse = JSON.parse(role);
    const name = localStorage.getItem('name');
    const nameParse = JSON.parse(name);

    const getBudgetsClients = async () => {
        setLoading(true);
        try {
            const { data } = await api.getBudgetClient();

            const row = data.map((item, index) => ({
                ...item,
                id: item?.id,
                vehicle: item?.vehicle,
                color: item?.color,
                plate: item?.plate,
                manualClient: item?.manualClient,
                cellPhone: item?.cellPhone
            }));
            console.log('🚀 ~ row ~ row:', row);
            // colocar do maior para o menor

            setRows(row?.sort((a, b) => b?.id - a?.id));
            // setRows(row);
            setFilteredResults(row);

            setData(data);

            setAllCustomer(data);
            setLoading(false);
        } catch (error) {
            console.log('🚀 ~ file: index.js:52 ~ getBudgetsClients ~ error:', error.message);
            setLoading(false);
        }
    };

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.2 },
        { field: 'vehicle', headerName: 'VEICULO', flex: 1 },
        { field: 'color', headerName: 'COR', flex: 1 },
        { field: 'plate', headerName: 'PLACA', flex: 1 },
        { field: 'manualClient', headerName: 'CLIENTE', flex: 1 },
        { field: 'cellPhone', headerName: 'TELEFONE', flex: 1 },
        {
            field: 'actions',
            headerName: 'AÇÕES',
            sortable: false,
            flex: 0.3,
            renderCell: (params) => <MenuTable getBudgetsClients={getBudgetsClients} rowData={params.row} />
        }
    ];

    useEffect(() => {
        getBudgetsClients();
    }, []);

    useEffect(() => {
        const findCurrentCostumer = allcustomer.find((item) => item.id === currentClientModel.id);

        setCurrentClientData(findCurrentCostumer);
    }, [currentClientModel]);

    const selectTypes = ['Orçamento', 'Razão Social', 'Obra', 'Vendedor'];

    const handleFieldChange = (event) => {
        const newSelectedField = event.target.value;
        setSelectedField(newSelectedField);
        filterResults(newSelectedField, searchTerm);
    };

    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
        filterResults(selectedField, newSearchTerm);
    };

    const filterResults = (field, term) => {
        if (!field || !term) {
            setFilteredResults(rows);
            return;
        }

        const fieldKey = {
            Orçamento: 'id',
            'Razão Social': 'social',
            Obra: 'construction',
            Vendedor: 'seller'
        }[field];

        const results = rows.filter((item) => {
            const fieldValue = item[fieldKey]?.toString().toLowerCase();
            return fieldValue?.includes(term.toLowerCase());
        });

        setFilteredResults(results);
    };

    return (
        <MainCard title="Orçamento de venda">
            <>
                <DrawerInsert Insert open={openDrawerInsert} setOpen={setOpenDrawerInsert} />
                {loading && <ModalLoadAnimated open={loading} />}
                <ObservationManager open={openObservation} setOpen={setOpenObservation} />
                <Grid container spacing={gridSpacing} sx={{ padding: '10px 20px', position: 'relative' }}>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '20px' }}>
                        {(roleParse === 'Administrador' || roleParse === 'Gestor Comercial') && (
                            <Button variant="contained" onClick={() => setOpenObservation(true)}>
                                <p style={{ margin: 0 }}>
                                    Cadastro <span style={{ textTransform: 'lowercase' }}>de</span>{' '}
                                    <span style={{ textTransform: 'lowercase' }}>observações</span>
                                </p>
                            </Button>
                        )}

                        <Button variant="contained" sx={{ width: '200px' }} onClick={() => setOpenDrawerInsert(true)}>
                            Novo orçamento
                        </Button>
                    </Box>
                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {/* <DataGrid style={{ marginTop: '20px' }} rows={rows} columns={columns} hideFooter /> */}
                        <CollapsibleTable rows={filteredResults} getBudgetsClients={getBudgetsClients} />
                    </Box>
                </Grid>
            </>
        </MainCard>
    );
};

export default SalesBudget;
