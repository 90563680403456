import { Button, Modal, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useState, useDeferredValue } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '6px'
};

const AddNote = ({ open, setOpen, row, getBudget }) => {
    console.log('🚀 ~ AddNote ~ row:', row);
    const api = useApi();
    const [observationText, setObservationText] = useState(row.note ? row.note : '');
    const [additionalText, setAdditionalText] = useState(row.note ? row.note : '');
    const deferredObservationText = useDeferredValue(observationText);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            note: additionalText
        };

        try {
            await api.addNote(row.codgo, payload);
            setObservationText('');
            setAdditionalText('');
            setOpen(false);
            getBudget();
            toast.success('Observação adicionada com sucesso!');
        } catch (error) {
            console.log('🚀 ~ handleSubmit ~ error:', error);
        }
    };

    const handleDelete = async (e) => {
        e.preventDefault();

        const payload = {
            note: ''
        };

        try {
            await api.addNote(row.codgo, payload);
            setObservationText('');
            setAdditionalText('');
            setOpen(false);
            getBudget();
            toast.success('Observação deletada com sucesso');
        } catch (error) {
            console.log('🚀 ~ handleSubmit ~ error:', error);
        }
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <h2 style={{ textAlign: 'center' }}>Adicione uma observação ao {row.desc}</h2>
                <form onSubmit={(e) => handleSubmit(e)}>
                    {/* <TextField
                        sx={{ width: '100%', marginBottom: '10px' }}
                        value={observationText}
                        id="outlined-multiline-flexible"
                        label={'Observação'}
                        multiline
                        rows={3}
                        onChange={(e) => setObservationText(e.target.value)}
                        disabled={!!row.note}
                    /> */}

                    <TextField
                        sx={{ width: '100%', marginBottom: '10px' }}
                        value={additionalText}
                        id="outlined-multiline-flexible"
                        label={'Adicionar Observação'}
                        multiline
                        rows={3}
                        onChange={(e) => setAdditionalText(e.target.value)}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                        <Button type="submit" variant="contained">
                            Adicionar observação
                        </Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
};

export default AddNote;
