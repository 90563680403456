import MainCard from 'ui-component/cards/MainCard';
import {
    Grid,
    Button,
    TextField,
    Typography,
    Box,
    InputLabel,
    Select,
    MenuItem,
    TableContainer,
    Table,
    IconButton,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Collapse,
    Tooltip,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Paper
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SubCard from 'ui-component/cards/SubCard';
import { gridSpacing } from 'store/constant';
import { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { useApi } from 'Service/axios';
import { CardContent } from '@mui/material';
// import BG from '../../assets/images/Backgrounds/loginBG.jpg';
import BG from '../../assets/BW TECNOLOGIA.png';
import { useTheme } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import SkeletonSimulator from 'ui-component/SkeletonSimulator';
import SkeletonBudget from 'ui-component/SkeletonBudget';
import InfiniteScroll from 'react-infinite-scroll-component';

function BudgetView() {
    const api = useApi();
    const [formOpen, setFormOpen] = useState(false);
    const [selectedQuiz, setSelectedQuiz] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [allGroup, setAllGroup] = useState([]);
    const [allQuiz, setAllQuiz] = useState([]);
    const [respostasSelecionadas, setRespostasSelecionadas] = useState([]);
    const [perguntaAtual, setPerguntaAtual] = useState(0);
    const [mostrarErro, setMostrarErro] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState([]);
    const [currentAnswer, setCurrentAnswer] = useState([]);
    const [allBudgets, setAllBudgets] = useState([]);
    const [currentQuiz, setCurrentQuiz] = useState([]);

    const [error, setError] = useState(false);

    //image
    const [imageUrl, setImageUrl] = useState('');
    const [stepImage, setStepImage] = useState(0);

    const [preOrc, setPreOrc] = useState([]);
    const [saveModel, setSaveModel] = useState();

    const [selectedBudget, setSelectedBudget] = useState(null);

    //scroll infinito
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const getAllBudgets = async () => {
        try {
            const { data } = await api.getAllBudgetsv2(page);
            console.log('🚀 ~ file: index.js:70 ~ getAllBudgets ~ response:', data);
            setAllBudgets((prev) => [...prev, ...data.payload]);

            if (data.length === 0) {
                setHasMore(false);
            } else {
                setPage((prevPage) => prevPage + 1);
            }
        } catch (error) {
            console.log('🚀 ~ file: index.js:74 ~ getAllBudgets ~ error:', error);
        }
    };

    useEffect(() => {
        getAllBudgets();
    }, []);

    const getAllAnswers = async (quiz) => {
        const promises = selectedQuiz?.map(async (item) => {
            const { data } = await api.getAnswerByCloseQuestion(item.id);
            return data;
        });
        const allAnswers = await Promise.all(promises);
        console.log('🚀 ~ getAllAnswers ~ allAnswers:', allAnswers);

        const newArrayAnswer = selectedQuiz?.map((item, index) => ({
            answers: allAnswers[index],
            question: item,
            imageUrl: item?.imageUrl,
            openQuestionRestriction: item.openQuestionRestriction
                ? {
                      description: item.openQuestionRestriction?.description,
                      min: item.openQuestionRestriction?.minimumValue,
                      max: item.openQuestionRestriction?.maximumValue
                  }
                : {}
        }));
        console.log('🚀 ~ newArrayAnswer ~ newArrayAnswer:', newArrayAnswer);

        setAnswers(newArrayAnswer);
    };
    const [groupIsSelected, setGroupIsSelected] = useState(false);
    const [showAllGroups, setShowAllGroups] = useState(true);
    const getAllGroups = async () => {
        try {
            const res = await api.getAllGroups();
            setAllGroup(res.data);
        } catch (error) {
            console.log(error.message);
        }
    };

    const getGroupRelated = async (groupID) => {
        try {
            const res = await api.getGroupRelatedQuiz(groupID);
            setSelectedGroup(res.data);
            setAllQuiz(res.data);
            setGroupIsSelected(true);
            setShowAllGroups(false);
        } catch (error) {
            console.log(error.message);
        }
    };

    // const getAllquiz = async () => {
    //     const response = await api.getAllQuiz();
    //     setAllQuiz(response.data);
    // };

    useEffect(() => {
        // getAllquiz();
        getAllGroups();
        if (selectedQuiz) {
            getAllAnswers(selectedQuiz);
        }
    }, [selectedQuiz]);

    const [openResponse, setOpenResponse] = useState(null);

    const handleRespostaSelecionada = (pergunta, resposta) => {
        setOpenResponse(resposta);

        let respostaObjeto;
        setCurrentQuestion(pergunta);
        setCurrentAnswer(resposta);
        if (pergunta.type === 'Aberta') {
            respostaObjeto = {
                question: pergunta,
                answer: {
                    value: resposta !== '' ? parseFloat(resposta) : null,
                    product: null
                },
                quizId: currentQuizMain.id
            };
        } else {
            respostaObjeto = {
                question: pergunta,
                answer: resposta,
                quizId: currentQuizMain.id
            };
        }

        setRespostasSelecionadas((prevRespostasSelecionadas) => ({
            ...prevRespostasSelecionadas,
            [pergunta.id]: respostaObjeto
        }));
    };
    //previnir doubleclick

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const handleAvancar = async () => {
        setIsButtonDisabled(true);

        setTimeout(() => {
            setIsButtonDisabled(false);
        }, 500);
        const respostaAtual = respostasSelecionadas[answers[perguntaAtual].question.id];
        if (respostaAtual === undefined) {
            setMostrarErro(true);
            return;
        }

        console.log('min', openResponse);

        if (
            openResponse < answers[perguntaAtual]?.openQuestionRestriction?.min ||
            openResponse > answers[perguntaAtual]?.openQuestionRestriction?.max
        ) {
            toast.warn(
                `Selecione um valor entre ${answers[perguntaAtual]?.openQuestionRestriction?.min} e ${answers[perguntaAtual]?.openQuestionRestriction?.max}`
            );
            return;
        }

        // ==================== LÓGICA PARA VERIFICAR A PRÓXIMA PERGUNTA ====================
        const resp = respostaAtual?.question?.id;
        const ListaPerguntas = answers;
        let proximoItem = null;
        let indexAtual = 0;
        for (let index = 0; index < ListaPerguntas.length; index++) {
            const item = ListaPerguntas[index];
            if (item?.question?.id === resp) {
                proximoItem = ListaPerguntas[index + 1];
                indexAtual = index + 1;
                break;
            }
        }

        //=================== Payload ===================================================
        const payload = Object.keys(respostasSelecionadas).map((key) => {
            const objt = respostasSelecionadas[key];
            const newObjt = {
                answer: { id: objt.answer.id },
                question: { id: objt.question.id }
            };

            return newObjt;
        });

        // ==================== LÓGICA PARA VERIFICAR SE POSSUI BODY FORMULA ====================
        // ==================== E PEGAR AS RESPOSTAS ====================
        if (proximoItem && proximoItem?.question?.haveBodyFormula) {
            try {
                const res = await api.checkformula(proximoItem.question.id, currentQuizMain.id, payload);

                if (res) {
                    const updatedAnswers = answers.map((item, index) => {
                        if (index === indexAtual) {
                            return {
                                ...item,
                                answers: res.data
                            };
                        }
                        return item;
                    });
                    setAnswers(updatedAnswers);
                }
            } catch (error) {
                console.log(error.message);
            }
        }
        setStepImage((prev) => prev + 1);
        setPerguntaAtual((prevPergunta) => prevPergunta + 1);
        setRespostasSelecionadas((prevRespostasSelecionadas) => ({
            ...prevRespostasSelecionadas,
            [answers[perguntaAtual].question.id]: respostaAtual
        }));
        setMostrarErro(false);
    };

    const handleVoltar = () => {
        if (perguntaAtual === 0) {
            setSelectedQuiz(null);
            setNome('');
            handleQuizChange();
        } else {
            const prevQuestionIndex = perguntaAtual - 1;
            const prevQuestion = answers[prevQuestionIndex].question;
            setPerguntaAtual(prevQuestionIndex);
            setStepImage((prev) => prev - 1);
        }
    };
    // loading skeleton
    const [loading, setLoading] = useState(false);
    const [loadingBudget, setLoadingBudget] = useState(false);
    const [currentQuizMain, setCurrentQuizMain] = useState(null);

    const handleQuizChange = async (e) => {
        const selectedOption = e.target.value;
        setCurrentQuizMain(selectedOption);
        console.log('🚀 ~ file: index.js:260 ~ handleQuizChange ~ selectedOption:', selectedOption);
        setLoading(true);

        try {
            const { data } = await api.getQuizByIdV2(selectedOption.id);
            console.log('🚀 ~ file: index.js:265 ~ handleQuizChange ~ data:', data);
            setSelectedQuiz(data);
            setNome(selectedOption?.product?.productName);
        } catch (error) {
            console.log('🚀 ~ file: index.js:262 ~ handleQuizChange ~ error:', error);
        } finally {
            setLoading(false);
        }
    };

    const [questionAndAnswerBudget, setQuestionAndAnswerBudget] = useState([]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoadingBudget(true);
        const payloadForBudget = Object.keys(respostasSelecionadas).map((key) => {
            const objt = respostasSelecionadas[key];
            const newObjt = {
                answer: objt.answer,
                question: objt.question
            };

            return newObjt;
        });

        const respostaAtual = respostasSelecionadas[answers[perguntaAtual].question.id];
        if (respostaAtual === undefined) {
            setMostrarErro(true);
            return;
        }

        if (
            openResponse < answers[perguntaAtual]?.openQuestionRestriction?.min ||
            openResponse > answers[perguntaAtual]?.openQuestionRestriction?.max
        ) {
            setLoadingBudget(false);
            return toast.warn(
                `Selecione um valor entre ${answers[perguntaAtual]?.openQuestionRestriction?.min} e ${answers[perguntaAtual]?.openQuestionRestriction?.max}`
            );
        }

        // //   Verificando se todas as perguntas foram respondidas
        const todasRespondidas = payloadForBudget.every((resposta) => resposta.answer !== undefined);
        console.log('todasRespondidas', todasRespondidas);

        try {
            console.log('Entrou no try');
            const response = await api.createBudgets(
                currentQuizMain.id,
                payloadForBudget.map((resposta) => ({
                    question: resposta.question,
                    answer: {
                        ...resposta.answer,
                        value: resposta.answer.value !== '' ? parseFloat(resposta.answer.value) : null
                    }
                }))
            );

            const materialFilter = response.data.materials.filter((item) => item.quantity !== 0);
            console.log('🚀 ~ file: index.js:331 ~ handleSubmit ~ response.data.materials:', response.data.materials);
            setPreOrc(materialFilter);
            setCurrentQuiz(response.data);
            const allResponse = payloadForBudget.map((resposta) => ({
                question: resposta.question,
                answer: {
                    ...resposta.answer,
                    value: resposta.answer.value !== '' ? parseFloat(resposta.answer.value) : null
                }
            }));
            setQuestionAndAnswerBudget(allResponse);
            setLoadingBudget(false);
            console.log('🚀 ~ file: index.js:313 ~ handleSubmit ~ allResponse:', allResponse);
            setSaveModel(response.data);
        } catch (error) {
            if (error?.response?.status === 400) {
                return toast.error(error?.response?.data?.message);
            }

            if (error?.response?.status !== 400) {
                return toast.error('Erro inesperado, verifique se existe uma lista de material atralado ao quiz');
            }
        }
    };

    const saveSubmit = async (event) => {
        event.preventDefault();
        try {
            await api.saveBudgets(currentQuizMain.id, saveModel);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };

    const [nome, setNome] = useState('');

    function Row({ budget }) {
        const theme = useTheme();
        const [openTable, setOpenTable] = useState(false);
        const [open, setOpen] = useState(false);
        const [openEdit, setOpenEdit] = useState(false);
        const [openSnakBar, setOpenSnackBar] = useState(false);
        const [openSnakBarCancelado, setOpenSnackBarCancelado] = useState(false);

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
            setOpenSnackBarCancelado(true);
        };

        const handleCloseEdit = () => {
            setOpenEdit(false);
            setOpenSnackBarCancelado(true);
        };

        const handleCloseSnackBar = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }

            setOpenSnackBar(false);
            setOpenSnackBarCancelado(false);
        };

        const handleDeleteList = async (id) => {
            console.log(id);
            try {
                const response = await api.deleteBugets(id);
                window.location.reload();
            } catch (error) {
                console.log(error.message);
            }
        };

        return (
            <>
                <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell sx={{ pl: 3 }}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpenTable(!openTable)}>
                            {openTable ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row" align="left">
                        {budget?.budget_Name}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                        Finalizado
                    </TableCell>
                    {/* AÇÕES */}
                    <TableCell align="right">
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Box sx={{ alignSelf: 'flex-end' }}>
                                    <Tooltip title="Excluir" onClick={handleClickOpen}>
                                        <IconButton>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-slide-description">
                                                tem certeza que quer deletar esse orçamento?
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleClose}>cancelar</Button>
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    handleDeleteList(budget?.budget_Id);
                                                }}
                                            >
                                                Deletar
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Box>
                            </Box>
                        </Box>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={openTable} timeout="auto" unmountOnExit>
                            {openTable && (
                                <Box sx={{ margin: 1 }}>
                                    <TableContainer>
                                        <SubCard
                                            sx={{ bgcolor: theme.palette.mode === 'dark' ? 'dark.800' : 'grey.50', mb: 2 }}
                                            title="Materiais do orçamento"
                                            content={false}
                                        >
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Matéria Prima</TableCell>
                                                        <TableCell>Quantidade</TableCell>
                                                        <TableCell>Unidade</TableCell>
                                                        <TableCell>Custo UN</TableCell>
                                                        <TableCell>Preço Total</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {budget?.materials.map((item, index) => (
                                                        <TableRow hover key={index}>
                                                            <TableCell component="th" scope="row">
                                                                <Typography sx={{ marginRight: '.4em' }}>
                                                                    {item?.name.toUpperCase()}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                <Typography sx={{ marginRight: '.4em' }}>{item?.quantity}</Typography>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                <Typography sx={{ marginRight: '.4em' }}>{item?.unity}</Typography>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                <Typography sx={{ marginRight: '.4em' }}>
                                                                    {item?.price.toLocaleString('pt-BR', {
                                                                        style: 'currency',
                                                                        currency: 'BRL'
                                                                    })}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                <Typography sx={{ marginRight: '.4em' }}>
                                                                    {item?.total_Price.toLocaleString('pt-BR', {
                                                                        style: 'currency',
                                                                        currency: 'BRL'
                                                                    })}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </SubCard>
                                    </TableContainer>
                                </Box>
                            )}
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    }

    return (
        <MainCard title="Orçamento">
            <Grid container spacing={gridSpacing}>
                <Grid item container sm={12} columns={12} spacing={2} sx={{ justifyContent: 'center' }}>
                    <Box
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        {!formOpen && (
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: '2em',
                                    padding: '1em'
                                }}
                            >
                                <Button variant="contained" onClick={() => setFormOpen(true)}>
                                    Novo Orçamento
                                </Button>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={allBudgets}
                                    getOptionLabel={(budget) => budget?.budget_Name}
                                    value={selectedBudget}
                                    onChange={(event, newValue) => {
                                        setSelectedBudget(newValue);
                                    }}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Pesquisar Orçamento" />}
                                />
                            </Box>
                        )}
                    </Box>
                    {!formOpen && (
                        <InfiniteScroll
                            dataLength={allBudgets.length}
                            next={getAllBudgets}
                            hasMore={hasMore}
                            loader={
                                <h4
                                    style={{
                                        textAlign: 'center'
                                    }}
                                >
                                    Carregando...
                                </h4>
                            }
                        >
                            {allBudgets.length > 0 && !formOpen && (
                                <TableContainer style={{ minWidth: '1200px' }}>
                                    <Table size="small" aria-label="collapsible table" style={{ width: '100%' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ pl: 3 }} />
                                                <TableCell align="left">Produto</TableCell>
                                                <TableCell align="right">Status</TableCell>

                                                <TableCell sx={{ pr: 3 }} align="right">
                                                    Ações
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {allBudgets.length > 0 &&
                                                allBudgets
                                                    .filter((budget) =>
                                                        selectedBudget
                                                            ? budget?.quizId?.product?.productName.includes(
                                                                  selectedBudget?.quizId?.product?.productName
                                                              )
                                                            : true
                                                    )
                                                    .map((budget, index) => <Row key={budget.budget_Id} budget={budget} />)}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </InfiniteScroll>
                    )}

                    {formOpen && (
                        <>
                            {preOrc.length <= 0 && (
                                <>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setFormOpen(false);
                                            window.location.reload();
                                        }}
                                        disabled={allBudgets.length === 0}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            backgroundColor: allBudgets.length === 0 ? 'grey' : '',
                                            marginBottom: '2em'
                                        }}
                                    >
                                        Ver Orçamentos
                                    </Button>
                                    <Grid
                                        item
                                        sm={12}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            border: '1px solid white',
                                            borderRadius: '30px',
                                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
                                            width: '800px',
                                            maxHeight: '400px',
                                            padding: '20px',
                                            backgroundColor: 'white',
                                            flexDirection: 'row-reverse',
                                            gap: '40px'
                                        }}
                                    >
                                        {!loading && !loadingBudget && (
                                            <CardContent>
                                                <img
                                                    src={answers[stepImage]?.imageUrl ? answers[stepImage]?.imageUrl : BG}
                                                    alt="Imagem 1"
                                                    style={{
                                                        minWidth: '300px',
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                        margin: '10px',
                                                        border: '1px solid white',
                                                        borderRadius: '30px',
                                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)'
                                                    }}
                                                />
                                            </CardContent>
                                        )}

                                        <form
                                            onSubmit={handleSubmit}
                                            style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center' }}
                                        >
                                            {loading ? (
                                                <SkeletonSimulator />
                                            ) : (
                                                <>
                                                    {nome === '' && (
                                                        <>
                                                            <FormControl sx={{ width: '100%', marginBottom: '15px', marginTop: '40px' }}>
                                                                <InputLabel>Selecione um grupo</InputLabel>
                                                                <Select
                                                                    label="Selecione um grupo"
                                                                    value={selectedGroup.groupDescription}
                                                                    onChange={(e) => {
                                                                        getGroupRelated(e.target.value.id);
                                                                    }}
                                                                    required
                                                                    sx={{ minWidth: '400px', justifyContent: 'center' }}
                                                                >
                                                                    {allGroup.map((quiz) => (
                                                                        <MenuItem key={quiz.id} value={quiz}>
                                                                            {quiz?.groupDescription}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>

                                                            {groupIsSelected && (
                                                                <FormControl sx={{ width: '100%', marginBottom: '15px' }}>
                                                                    <InputLabel>Seleção de questionário</InputLabel>
                                                                    <Select
                                                                        label="Seleção de questionário"
                                                                        value={selectedQuiz}
                                                                        onChange={handleQuizChange}
                                                                        required
                                                                        sx={{ minWidth: '400px', justifyContent: 'center' }}
                                                                    >
                                                                        {allQuiz.map((quiz) => (
                                                                            <MenuItem key={quiz.id} value={quiz}>
                                                                                {quiz?.product?.productName}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}

                                            {nome !== '' ||
                                                (!loadingBudget && (
                                                    <Typography
                                                        sx={{
                                                            fontSize: '20px',
                                                            fontWeight: 'bold',
                                                            color: '#2F80ED',
                                                            borderRadius: '10px',
                                                            padding: '10px',
                                                            marginTop: '10px'
                                                        }}
                                                    >
                                                        {nome}
                                                    </Typography>
                                                ))}
                                            {!loadingBudget ? (
                                                <>
                                                    {answers && answers[perguntaAtual] && (
                                                        <FormControl sx={{ width: '400px', mb: '15px' }}>
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                <Typography variant="subtitle1">
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            gap: '8px',
                                                                            width: 'auto'
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                marginTop: '10px',
                                                                                fontWeight: 'bold',
                                                                                color: '#333333',
                                                                                textAlign: 'center'
                                                                            }}
                                                                        >
                                                                            {answers[perguntaAtual]?.question?.description}
                                                                        </span>
                                                                        <span style={{ textAlign: 'center', color: '#FF6666' }}>
                                                                            {answers[perguntaAtual]?.openQuestionRestriction?.description ||
                                                                                ''}
                                                                        </span>
                                                                    </div>
                                                                </Typography>
                                                                {answers[perguntaAtual].question.type === 'Aberta' ? (
                                                                    <TextField
                                                                        type="number"
                                                                        placeholder="Digite sua resposta"
                                                                        error={mostrarErro}
                                                                        value={
                                                                            respostasSelecionadas[answers[perguntaAtual].question.id]
                                                                                ?.answer?.value || ''
                                                                        }
                                                                        helperText={mostrarErro ? 'Campo obrigatório' : ''}
                                                                        onChange={(e) => {
                                                                            setMostrarErro(false);
                                                                            handleRespostaSelecionada(
                                                                                answers[perguntaAtual].question,
                                                                                e.target.value !== '' ? e.target.value : undefined,
                                                                                answers[perguntaAtual]
                                                                            );
                                                                        }}
                                                                        sx={{ marginTop: '8px', width: '300px' }}
                                                                    />
                                                                ) : (
                                                                    <Select
                                                                        error={mostrarErro}
                                                                        value={
                                                                            respostasSelecionadas[answers[perguntaAtual].question.id]
                                                                                ?.answer || ''
                                                                        }
                                                                        helperText={mostrarErro ? 'Campo obrigatório' : ''}
                                                                        onChange={(e) => {
                                                                            setMostrarErro(false);
                                                                            handleRespostaSelecionada(
                                                                                answers[perguntaAtual].question,
                                                                                e.target.value !== '' ? e.target.value : undefined
                                                                            );
                                                                        }}
                                                                        required
                                                                        sx={{ marginTop: '20px', width: '300px' }}
                                                                    >
                                                                        <MenuItem disabled value="">
                                                                            Selecione uma resposta
                                                                        </MenuItem>
                                                                        {answers[perguntaAtual].answers.map((item) => (
                                                                            <MenuItem key={item.id} value={item}>
                                                                                {item.public_description
                                                                                    ? item.public_description
                                                                                    : item.publicDescription}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                )}
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    marginTop: '70px',
                                                                    padding: '0 30px'
                                                                }}
                                                            >
                                                                {perguntaAtual > 0 && (
                                                                    <Button type="button" variant="contained" onClick={handleVoltar}>
                                                                        Voltar
                                                                    </Button>
                                                                )}

                                                                {perguntaAtual === answers.length - 1 ? (
                                                                    <Button type="button" variant="contained" onClick={handleSubmit}>
                                                                        Enviar
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        type="button"
                                                                        variant="contained"
                                                                        disabled={isButtonDisabled}
                                                                        onClick={handleAvancar}
                                                                    >
                                                                        Avançar
                                                                    </Button>
                                                                )}
                                                            </Box>
                                                        </FormControl>
                                                    )}
                                                </>
                                            ) : (
                                                <SkeletonBudget />
                                            )}
                                        </form>
                                    </Grid>
                                </>
                            )}

                            {preOrc.length > 0 && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginTop: '4em',
                                        border: '1px solid white',
                                        borderRadius: '30px',
                                        width: '800px',
                                        boxShadow: '2px 4px 8px rgba(0, 0, 0, 0.7)',
                                        padding: '20px',
                                        backgroundColor: 'white'
                                    }}
                                >
                                    <Box sx={{ marginBottom: '2em' }}>
                                        <Typography sx={{ marginRight: '.4em', fontWeight: 'bold' }}>
                                            Resumo do Orçamento - {nome}
                                        </Typography>
                                    </Box>

                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Produto</TableCell>
                                                    <TableCell align="right">Material</TableCell>
                                                    <TableCell align="right">Preço Un</TableCell>
                                                    <TableCell align="right">Qtd</TableCell>
                                                    <TableCell align="right">UN</TableCell>
                                                    <TableCell align="right">Total</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {preOrc.map((info, index) => (
                                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell component="th" scope="row">
                                                            {info?.finalProduct?.productName}
                                                        </TableCell>
                                                        <TableCell align="right">{info?.materialName}</TableCell>
                                                        <TableCell align="right">
                                                            {' '}
                                                            {info?.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                        </TableCell>
                                                        <TableCell align="right">{info?.quantity}</TableCell>
                                                        <TableCell align="right">{info?.unity}</TableCell>
                                                        <TableCell align="right">
                                                            {info?.totalPrice.toLocaleString('pt-BR', {
                                                                style: 'currency',
                                                                currency: 'BRL'
                                                            })}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <h4 style={{ marginTop: '20px' }}>Lista de perguntas e respostas</h4>
                                    <Box>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Perguntas</TableCell>
                                                        <TableCell align="center">Respostas</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {questionAndAnswerBudget.map((item, index) => (
                                                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell component="th" scope="row">
                                                                {item.question.description}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {item.answer.description ? item.answer.description : item.answer.value}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                    <Button variant="contained" onClick={saveSubmit} sx={{ marginTop: '2em' }}>
                                        Finalizar
                                    </Button>
                                </Box>
                            )}
                        </>
                    )}
                </Grid>
            </Grid>
        </MainCard>
    );
}

export default BudgetView;
