import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router';
import { Tooltip } from '@mui/material';
import { useState } from 'react';
import ConfirmModal from 'ui-component/ConfirmModal';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AnalysisModal from './AnalysisModal';
import AddNote from './AddNote';
import EditBudgetFormation from './EditBudgetFormation';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import BudgetEdit from './BudgetEdit';
import { useEffect } from 'react';

const ActionButtons = ({ row, formData, getBudget, currentIcms, currentQuizId, setCurrentQuizId, newAnswers }) => {
    const role = localStorage.getItem('role');
    const roleParse = JSON.parse(role);
    const api = useApi();
    const [openEditBudget, setOpenEditBudget] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openAddNote, setOpenAddNote] = useState(false);

    const [openModalEye, setOpenModalEye] = useState(false);

    const handleDelete = async () => {
        try {
            await api.deleteBudgetListClient(row.codgo, formData.id);
            toast.success('Orçamento deletado com sucesso!');
            getBudget();
        } catch (error) {
            toast.error('Erro ao deletar orçamento!');
        }
    };
    const handleEditBudgetdelete = async () => {
        try {
            await api.deleteBudgetListClient(row.codgo, formData.id);
            toast.success('Orçamento atualizado com sucesso!');
            getBudget();
        } catch (error) {
            toast.error('Erro ao deletar orçamento!');
        }
    };

    const handleEditBudget = () => {
        setOpenEditBudget(true);
    };

    return (
        <div style={{ display: 'flex', gap: '10px', cursor: 'pointer' }}>
            <AddNote getBudget={getBudget} open={openAddNote} setOpen={setOpenAddNote} row={row} />
            <ConfirmModal
                button1={'Cancelar'}
                button2={'Confirmar'}
                firstMessage={'Deseja realmente deletar esse orçamento ?'}
                secondMessage={'Após a confirmaçao os dados não poderao ser recuperados.'}
                open={openConfirm}
                func={handleDelete}
                setOpen={setOpenConfirm}
            />
            {/* <AnalysisModal open={openModalEye} setOpen={setOpenModalEye} data={row} currentIcms={currentIcms} /> */}
            <BudgetEdit
                handleDelete={handleEditBudgetdelete}
                getBudget={getBudget}
                formData={formData}
                open={openEditBudget}
                setOpen={setOpenEditBudget}
                row={row}
            />
            {formData?.finished ? (
                <p>-</p>
            ) : (
                <>
                    <Tooltip title="Editar" onClick={() => handleEditBudget()}>
                        <EditIcon />
                    </Tooltip>
                    {/* {roleParse !== 'Representante' && (
                        <Tooltip title="Análisar" onClick={() => setOpenModalEye(true)}>
                            <VisibilityIcon />
                        </Tooltip>
                    )} */}

                    <Tooltip title="Adicionar observação" onClick={() => setOpenAddNote(true)}>
                        <FormatListBulletedIcon color={row?.note && row?.note?.length > 0 ? 'primary' : 'inherit'} />
                    </Tooltip>
                    <Tooltip title="Deletar" onClick={() => setOpenConfirm(true)}>
                        <DeleteIcon />
                    </Tooltip>
                </>
            )}
        </div>
    );
};

export default ActionButtons;
