import { create } from '@mui/material/styles/createTransitions';
import axios from 'axios';
import group from 'menu-items/group';
import unit from 'menu-items/unit';

// const api = axios.create({
//     baseURL: 'http://23.22.23.102:9000/'
// });

// const api = axios.create({
//     baseURL: 'http://localhost:9000'
// });

// deploy 07-10

const api = axios.create({
    baseURL: 'https://jgfcarbackend-production.up.railway.app'
});

export const useApi = () => ({
    //singin and singout
    signin: async (login, password) => {
        const response = await api.post('/api/login', { login, password });
        return response;
    },

    //  users
    getAllUsers: async () => {
        const response = await api.get('/api/users');
        return response;
    },
    getUserById: async (id) => {
        const response = await api.get(`/api/users/${id}`);
        return response;
    },
    createUser: async (payload) => {
        const response = await api.post('/api/users', payload);
        return response;
    },
    deleteUser: async (id) => {
        const response = await api.delete(`/api/users/${id}`);
        return response;
    },
    updateUser: async (id, values) => {
        const response = await api.put(`/api/users/${id}`, {
            name: values.name,
            email: values.email,
            login: values.login,
            phone: values.phone,
            role: values.role,
            password: values.password,
            commission: values.commission,
            user: values.seller ? { id: values.seller } : null
        });
        return response;
    },

    //groups
    getAllGroups: async () => {
        const response = await api.get('/api/groups');
        return response;
    },
    createGroup: async (groupName) => {
        const response = await api.post('/api/groups', {
            groupDescription: groupName
        });
        return response;
    },
    deleteGroup: async (id) => {
        const response = await api.delete(`/api/groups/${id}`);
        return response;
    },
    updateGroup: async (id, newGroup) => {
        const response = await api.put(`/api/groups/${id}`, {
            groupDescription: newGroup
        });
        return response;
    },
    getGroupRelatedQuiz: async (id) => {
        const response = await api.get(`/api/groups/find_quizes/${id}`);
        return response;
    },
    changeEnabledQuiz: async (id, isEnabled) => {
        const response = await api.put(`/api/quiz/${id}/enabled?enabled=${isEnabled}`);
        return response;
    },
    //unit
    createUnit: async (unitName) => {
        const response = await api.post('/api/units', {
            unitSize: unitName
        });
        return response;
    },
    getAllUnits: async () => {
        const response = await api.get('/api/units');
        return response;
    },

    deleteUnit: async (id) => {
        const response = await api.delete(`/api/units/${id}`);
        return response;
    },
    updateUnit: async (id, newUnit) => {
        const response = await api.put(`/api/units/${id}`, {
            unitSize: newUnit
        });
        return response;
    },

    // Grupos de cores
    createGroupColor: async (values) => {
        const response = await api.post('/api/groupcolors', {
            name: values.name,
            colors: values.colors
        });
        return response;
    },
    deleteGroupColor: async (id) => {
        const response = await api.delete(`/api/groupcolors/${id}`);
        return response;
    },
    updateGroupColor: async (id, values) => {
        const response = await api.put(`/api/groupcolors/${id}`, {
            name: values
        });
        return response;
    },
    getAllColorsGroup: async () => {
        const response = await api.get('/api/groupcolors');
        return response;
    },
    getColorGroupById: async (id) => {
        const response = await api.get(`/api/groupcolors/${id}`);
        return response;
    },
    //Perguntas e repostas

    //pergunta
    createQuestion: async (type, description) => {
        const response = await api.post('/api/questions', {
            type,
            description
        });
        return response;
    },
    getAllQuestion: async () => {
        const response = await api.get('/api/questions');
        return response;
    },
    getQuestionAndAnswers: async (id) => {
        const response = await api.get(`/api/questions/${id}/answers`);
        return response;
    },
    deleteQuestion: async (id) => {
        const response = await api.delete(`/api/questions/${id}`);
        return response;
    },
    updateQuestion: async (id, newQuestionName, type, bodyFormula, answers) => {
        const response = await api.put(`/api/questions/${id}`, {
            description: newQuestionName,
            type,
            bodyFormula,
            answers
        });
        return response;
    },
    positionQuestionary: async (id, values) => {
        const response = await api.post(`/api/questions/position?quizId=${id}`, values);
        return response;
    },
    getPositionQuestionary: async (id) => {
        const response = await api.get(`/api/questions/positionmodel?quizId=${id}`);
        return response;
    },
    editBodyFormula: async (id, values) => {
        const response = await api.put(`/api/question/bodyformula/${id}`, values);
        return response;
    },
    editPositionModel: async (id, values) => {
        const response = await api.post(`/api/questions/position?quizId=${id}`, values);
        return response;
    },
    addNewFormula: async (Quizid, questionId, payload) => {
        const response = await api.post(`api/questions/${questionId}/formula?quizId=${Quizid}`, payload);
        return response;
    },
    checkformula: async (questionID, quizId, payload) => {
        const response = await api.post(`/api/questions/${questionID}/formula/calculator?quizId=${quizId}`, payload);
        return response;
    },
    updateImageAndOpenQuestion: async (id, payload) => {
        const response = await api.put(`/api/question/position/${id}`, payload);
        return response;
    },
    updateBodyFormula: async (bodyFormulaID, payload) => {
        const response = await api.put(`/api/question/bodyformula/${bodyFormulaID}`, payload);
        return response;
    },
    getCurrentBodyFormula: async (quizId, questionId) => {
        const response = await api.get(`/api/question/bodyformula?quizId=${quizId}&questionId=${questionId}`);
        return response;
    },
    deleteBodyFormula: async (id) => {
        const response = await api.delete(`/api/question/bodyformula/${id}`);
        return response;
    },
    getBodyFormulav2: async (questionId, quizId) => {
        const response = await api.get(`/api/questions/${questionId}/bodyformula/quiz/${quizId}`);
        return response;
    },
    //resposta

    // createAnswer: async (formula, description, product, value, id) => {
    //     const response = await api.post(`/api/answers?questionId=${id}`, {
    //         formula,
    //         description,
    //         value,
    //         product
    //     });
    //     return response;
    // },
    createAnswer: async (formula, description, value, id, product, publicDescription) => {
        const response = await api.post(`/api/answers?questionId=${id}`, {
            formula,
            description,
            value,
            product: product,
            publicDescription
        });
        return response;
    },
    getAllAnswers: async () => {
        const response = await api.get('/api/answers');
        return response;
    },

    deleteAnswer: async (id) => {
        const response = await api.delete(`/api/answers/${id}`);
        return response;
    },
    updateAnswer: async (id, value) => {
        const response = await api.put(`/api/answers/${id}`, {
            formula: value.formula,
            description: value.description,
            value: value.value,
            product: value.product,
            publicDescription: value.publicDescription
        });
        return response;
    },

    // product
    createProduct: async (values) => {
        const response = await api.post(`/api/products`, {
            productName: values.productName,
            productBuy: values.productBuy,
            productFabricated: values.productFabricated,
            productGeneric: values.productGeneric,
            productPrimary: values.productPrimary,
            productIntermediary: values.productIntermediary,
            productFinal: values.service ? true : values.productFinal,
            productPrice: values.productPrice,
            unit: {
                id: values.unit
            },

            productGroups: values.productGroup,
            groupColor: values.groupColors,
            service: values.service
        });
        return response;
    },
    getAllPrimaryProducts: async () => {
        const response = await api.get('/api/products/primary');
        return response;
    },
    getAllProducts: async () => {
        const response = await api.get('/api/products');
        return response;
    },
    getProductById: async (id) => {
        const response = await api.get(`/api/products/${id}`);
        return response;
    },
    deleteProduct: async (id) => {
        const response = await api.delete(`/api/products/${id}`);
        return response;
    },
    updateProduct: async (id, values, groups) => {
        const newPrice = parseFloat(values.productPrice);

        const response = await api.put(`/api/products/${id}?unitId=${values.unit}`, {
            productName: values.productName,
            productBuy: values.productBuy,
            productFabricated: values.productFabricated,
            productGeneric: values.productGeneric,
            productPrimary: values.productPrimary,
            productIntermediary: values.productIntermediary,
            productFinal: values.productFinal,
            productPrice: newPrice,
            groupColor: values.groupColor,
            service: values.service,
            productGroups: groups
        });

        return response;
    },

    // questionario

    createQuestionary: async (pd, questions) => {
        const response = await api.post(`/api/quiz`, {
            product: {
                id: pd
            },
            questionPosition: []
        });
        return response;
    },

    deleteQuestionary: async (id) => {
        const response = await api.delete(`/api/quiz/${id}`);
        return response;
    },
    getQuestionaryById: async (id) => {
        const response = await api.get(`/api/quiz/${id}`);
        return response;
    },
    upadteQuestionary: async (id, pd, questions) => {
        const response = await api.put(`api/quiz/${id}`, {
            product: {
                id: pd
            },
            questions: questions
        });
        return response;
    },

    // quiz
    getAllQuiz: async () => {
        const response = await api.get('/api/quiz');
        return response;
    },
    getQuizById: async (id) => {
        const response = await api.get(`/api/quiz/${id}`);
        return response;
    },
    getQuizByIdSimple: async (id) => {
        const response = await api.get(`/api/quiz/${id}/simple`);
        return response;
    },

    // material List
    getAllList: async () => {
        const response = await api.get('/api/material');
        return response;
    },
    getListById: async (id) => {
        const response = await api.get(`/api/material/${id}`);
        return response;
    },
    createList: async (quiz, list) => {
        console.log(list);
        const response = await list.materialList.map(async (item, index) => {
            const obj = {
                formula: index === 0 ? item.formula[0] : item.formula[0][index],
                quantity: item.quantidade,
                lossPercent: item.percentual
            };
            // console.log(obj);
            const a = await api.post(`/api/material?quizId=${quiz}&productId=${item.materiaPrima?.id}&itemSubId=${item.itemSubstituto}`, {
                formula: obj.formula,
                quantity: obj.quantity,
                lossPercent: obj.lossPercent,
                finalProduct: { id: list.finalProductId.id }
            });
            return a;
        });
        return response;
    },
    updateList: async (list, id, itemSub) => {
        console.log('🚀 ~ updateList: ~ itemSub:', itemSub);
        console.log('🚀 ~ updateList: ~ id:', id);
        console.log('🚀 ~ updateList: ~ list:', list);
        const response = await list.materialList.map(async (item, index) => {
            const obj = {
                formula: index === 0 ? item.formula[0] : item.formula[0][index],
                quantity: item.quantity,
                lossPercent: item.lossPercent
            };
            // console.log(obj);
            const a = await api.put(`/api/material/${id}?&productId=${item.materiaPrima}&itemSubId=${itemSub}`, {
                formula: obj.formula,
                quantity: obj.quantity,
                lossPercent: obj.lossPercent,
                finalProduct: { id: list.finalProductId }
            });
            return a;
        });
        return response;
    },
    deleteList: async (id) => {
        const response = await api.delete(`/api/material/${id}`);
        return response;
    },

    // orçamento
    createBudgets: async (quizId, respostas) => {
        const response = await api.post(`/api/budgets?quizId=${quizId}`, [...respostas]);
        return response;
    },
    saveBudgets: async (quizId, object) => {
        const response = await api.post(`/api/budgets/save?quizId=${quizId}`, object);
        return response;
    },
    getAllBudgets: async () => {
        const response = await api.get('/api/budgets');
        return response;
    },
    getAllBudgetsv2: async (page) => {
        const response = await api.get(`/api/v2/budget?pageNumber=${page}`);
        return response;
    },
    deleteBugets: async (id) => {
        const response = await api.delete(`/api/budgets/${id}`);
        return response;
    },
    //parametros do comercial
    registerIcms: async (payload) => {
        const response = await api.post(`/api/parameters/icms`, payload);
        return response;
    },
    getIcms: async () => {
        const response = await api.get('/api/parameters/icms');
        return response;
    },
    deleteIcms: async (id) => {
        const response = await api.delete(`/api/parameters/icms/${id}`);
        return response;
    },
    editIcms: async (id, payload) => {
        const response = await api.put(`/api/parameters/icms/${id}`, payload);
        return payload;
    },
    //clients
    addnewCostumer: async (payload) => {
        const response = await api.post(`/api/client`, payload);
        return response;
    },
    addNote: async (id, payload) => {
        const response = await api.put(`api/v2/budget/${id}/note`, payload);
        return response;
    },
    deleteBudgetClient: async (id) => {
        const res = await api.delete(`/api/budgets/client/${id}`);
        return res;
    },
    getEditBudgetClient: async (id) => {
        const res = await api.get(`/api/v2/budget/${id}/quizAnswers`);
        return res;
    },
    getAllCostumers: async () => {
        const response = await api.get('api/client');
        return response;
    },
    editCostumer: async (id, payload) => {
        const response = await api.put(`/api/client/${id}`, payload);
        return response;
    },
    deleteCostumer: async (id) => {
        const response = await api.delete(`/api/client/${id}`);
        return response;
    },
    //pagamentos
    addPaymentTerms: async (payload) => {
        const response = await api.post('api/parameters/payment', payload);
        return response;
    },
    getPaymentsTerms: async () => {
        const response = await api.get('/api/parameters/payment');
        return response;
    },
    deletePaymentsTerms: async (id) => {
        const response = await api.delete(`/api/parameters/payment/${id}`);
        return response;
    },
    editePaymentsTerms: async (id, payload) => {
        const response = await api.put(`/api/parameters/payment/${id}`, payload);
        return response;
    },

    /////////////////////// PCP ////////////////////////////////////
    getOrderFP: async () => {
        const response = await api.get('/api/pcp');
        return response;
    },
    getDetails: async (codigo) => {
        const response = await api.get(`/api/pcp/details?ordInCodigo=${parseInt(codigo)}`);
        return response;
    },
    getStock: async (codigo) => {
        const response = await api.get(`/api/pcp/stock?ord_in_codigo=${codigo}`);
        return response;
    },
    getSimilarItens: async (codigo, cod2) => {
        const response = await api.get(`/api/pcp/similarItens?ord_in_codigo=${codigo}&pro_in_codigo=${cod2}`);
        return response;
    },
    getOrderLB: async () => {
        const response = await api.get('/api/pcp/lb');
        return response;
    },
    getnv2Pcp: async (cod) => {
        const response = await api.get(`/api/pcp/stock/nv2?lis_pro_in_codigo=${cod}`);
        return response;
    },
    getSimilarnv2Pcp: async (cod) => {
        const response = await api.get(`/api/pcp/similarItens/nv2?lts_pro_in_codigo=${cod}`);
        return response;
    },
    getNv3pcp: async (cod) => {
        const response = await api.get(`/api/pcp/stock/nv3?lts_pro_in_codigo=${cod}`);
        return response;
    },
    getPcpAb: async () => {
        const response = await api.get(`/api/pcp/ab`);
        return response;
    },
    // Resultado operacional
    createRo: async (quizId, payload) => {
        const response = await api.post(`/api/ro?quizId=${quizId}`, payload);
        return response;
    },
    getRo: async () => {
        const response = await api.get('/api/ro');
        return response;
    },
    getRoId: async (id) => {
        const response = await api.get(`/api/ro/${id}`);
        return response;
    },
    putRo: async (id, payload) => {
        const response = await api.put(`/api/ro/${id}`, payload);
        return response;
    },
    deleteRo: async (id) => {
        const response = await api.delete(`/api/ro/${id}`);
        return response;
    },
    //staticos
    getStatic: async () => {
        const response = await api.get('/api/parameters/static');
        return response;
    }, //RO
    calcRo: async (quizId, budgetID, payload) => {
        const response = await api.post(`/api/budgets/full?quizId=${quizId}&budgetClientId=${budgetID}`, payload);
        return response;
    },
    insertBudgetClient: async (patmentID, payload) => {
        const response = await api.post(
            `/api/budgets/client?clientId=${1}&sellerId=${3}&agentId=&paymentConditionId=${patmentID}`,
            payload
        );
        return response;
    },
    getBudgetClient: async () => {
        const response = await api.get('/api/budgets/clients', { packaging: 1.0 }, {});
        return response;
    },
    getBudgetOneClient: async (id) => {
        const response = await api.get(`/api/budgets/client/${id}`);
        return response;
    },
    editBudgetClient: async (id, clientID, sellerID, agentID, patmentID, packaging, icms, payload) => {
        const response = await api.put(
            `/api/budgets/client/${id}?clientId=${clientID}&sellerId=${sellerID}&agentId=${agentID}&paymentConditionId=${patmentID}&packaging=${packaging}&icmsId=${icms}`,
            payload
        );
        return response;
    },
    deleteBudgetListClient: async (idList, idClient) => {
        const response = await api.delete(`/api/budgets/client/list/${idList}?budgetId=${idClient}`);
        return response;
    },
    ////// refactor r.o new routes ///////
    getNewAllRo: async () => {
        const response = await api.get('/api/v2/ro?page=1');
        return response;
    },
    getNewRoById: async (id) => {
        const response = await api.get(`/api/v2/ro/${id}`);
        return response;
    },
    getNewRoFormula: async (id) => {
        const response = await api.get(`/api/v2/ro/${id}/formula`);
        return response;
    },
    insertNewRoFormula: async (id, payload) => {
        const response = await api.post(`/api/v2/ro/${id}/formula`, payload);
        return response;
    },
    editNewRoFormula: async (id, payload) => {
        const response = await api.put(`/api/v2/ro/formula/${id}`, payload);
        return response;
    },
    deleteNewRoFormula: async (id) => {
        const response = await api.delete(`/api/v2/ro/formula/${id}`);
        return response;
    },
    editNewRo: async (id, payload) => {
        const response = await api.put(`/api/v2/ro/${id}`, payload);
        return response;
    },
    //////// question and answers leves //////////
    simpleQuestionByQuiz: async (quizId) => {
        const response = await api.get(`/api/v2/question/quiz/${quizId}`);
        return response;
    },

    ///////// new rout question ///////
    updateQuestionV2: async (id, payload) => {
        const response = await api.put(`/api/v2/question/${id}`, payload);
        return response;
    },
    /////// new route quiz (r.o) /////////
    questionCloseById: async (id) => {
        const response = await api.get(`/api/v2/question/quiz/${id}`);
        return response;
    },
    getAnswerByCloseQuestion: async (id) => {
        const response = await api.get(`/api/v2/answer/question/${id}`);
        return response;
    },
    getAnswerByColorGroupt: async (questionId, groupColorId) => {
        const response = await api.get(`/api/v2/answer/question/${questionId}/color-group/${groupColorId}`);
        return response;
    },
    // custos
    updateCost: async () => {
        const response = await api.post('/custos/atualizar');
        return response;
    },
    getCost: async () => {
        const response = await api.get('/custos');
        return response;
    },
    filterCost: async (initData, finalData, project, status) => {
        let url = `/custos/filtro?`;

        // Adicionar as datas à URL se elas forem fornecidas
        if (initData) {
            url += `dtInicio=${initData}&`;
        }
        if (finalData) {
            url += `dtFim=${finalData}&`;
        }

        // Adicionar o projeto à URL se fornecido
        if (project) {
            url += `projeto=${project}&`;
        }

        if (status) {
            url += `status=${status}&`;
        }

        // Remover o último caractere '&' da URL, se existir
        url = url.replace(/&$/, '');

        // Fazer a chamada à API
        const response = await api.get(url);
        return response;
    },
    getConsumtionFiltred: async (initData, finalData, id) => {
        let url = `/custos/consumo?`;

        // Adicionar as datas à URL se elas forem fornecidas
        if (initData) {
            url += `dtInicio=${initData}&`;
        }
        if (finalData) {
            url += `dtFim=${finalData}&`;
        }

        // Adicionar o projeto à URL se fornecido
        if (id) {
            url += `projetoId=${id}&`;
        }

        // Remover o último caractere '&' da URL, se existir
        url = url.replace(/&$/, '');

        // Fazer a chamada à API
        const response = await api.get(url);
        return response;
    },
    invoicingForDate: async (initialData, finalData) => {
        const response = await api.get(`/faturamento/projeto?dtInicio=${initialData}&dtFim=${finalData}`);
        return response;
    },
    getConsumption: async (id) => {
        const response = await api.get(`/custos/consumo?projetoId=${id}`);
        return response;
    },
    getExpenses: async (id) => {
        const response = await api.get(`/custos/despesas?projetoId=${id}`);
        return response;
    },
    getFixes: async (id) => {
        const response = await api.get(`/custos/impostos?projetoId=${id}`);
        return response;
    },
    getMo: async (id) => {
        const response = await api.get(`/custos/mo?projetoId=${id}`);
        return response;
    },
    // novas rodas de quiz v2
    getQuizByIdV2: async (id) => {
        const response = await api.get(`/api/v2/quiz/${id}/question`);
        return response;
    },
    updatePositionV2: async (id, payload) => {
        const response = await api.post(`/api/v2/quiz/question_position/${id}`, payload);
        return response;
    },
    insertBodyFormulaV2: async (quiz, question, payload) => {
        const response = await api.post(`/api/question/bodyformula/question/${question}/quiz/${quiz}`, payload);
        return response;
    },
    insertImageV2: async (id, image) => {
        const response = await api.put(`/api/v2/quiz/question_position/${position}/image`, image);
        return response;
    },
    // new budget
    getBudgetsOneV2: async (id) => {
        const response = await api.get(`/api/v2/budget/client/${id}`);
        return response;
    },
    getRov2: async (page) => {
        const response = await api.get(`api/v2/ro?page=${page}`);
        return response;
    },
    getQuizForRo: async () => {
        const response = await api.get('/api/v2/quiz/ro');
        return response;
    },
    insertFormulaV2: async (id, payload) => {
        const response = await api.post(`/api/questions/${id}/formula`, payload);
        return response;
    },
    addNewFormula: async (Quizid, questionId, payload) => {
        const response = await api.post(`api/questions/${questionId}/formula?quizId=${Quizid}`, payload);
        return response;
    }, //faturamento
    getFaturamento: async (initialData, finalData) => {
        // 27/09/2001
        const response = await api.get(`/faturamento?dtInicio=${initialData}&dtFim=${finalData}`);
        return response;
    },
    getFaturamentoProject: async (dtfim) => {
        const response = await api.get(`/faturamento/projeto?dtFim=${dtfim}`);
        return response;
    },
    postGoals: async (payload) => {
        const response = await api.post('/faturamento/meta', payload);
        return response;
    },
    getCurrentGoals: async (date) => {
        const response = await api.get(`/faturamento/meta?data=${date}`);
        return response;
    },
    getPercentege: async (initialData, finalData) => {
        // 27/09/2001
        const response = await api.get(`/faturamento/prod?dtInicio=${initialData}&dtFim=${finalData}`);
        return response;
    },
    getRequestMonth: async (dtInicio, dtFim) => {
        const response = await api.get(`/faturamento/pedidos?dtInicio=${dtInicio}&dtFim=${dtFim}`);
        return response;
    },
    getProducedAndPrev: async (month, year) => {
        const response = await api.get(`/faturamento/prev-prod?mes=${month}&ano=${year}`);
        return response;
    },
    getChartExpedition: async (dtFim) => {
        const response = await api.get(`/faturamento/expedicao?dtFim=${dtFim}`);
        return response;
    },
    getAllGoals: async (year) => {
        const response = await api.get(`/faturamento/allMetasByAno?ano=${year}`);
        return response;
    },
    deleteGoals: async (id) => {
        const response = await api.delete(`/faturamento/meta/${id}`);
        return response;
    },
    getProducedAndPrevByFilter: async (initialData, finalData, year) => {
        const response = await api.get(`/faturamento/prev-prod?mes1=${initialData}&mes2=${finalData}&ano=${year}`);
        return response;
    },
    getAllGoalsWithoutFilter: async () => {
        const response = await api.get(`/faturamento/allMetas`);
        return response;
    },
    getCurrentFaturamento: async (initialData, finalData) => {
        const response = await api.get(`/faturamento/faturamento?dtInicio=${initialData}&dtFim=${finalData}`);
        return response;
    }, // pcp projetos
    postFirstRequest: async () => {
        const response = await api.post(`/mpProj/atualizar`);
        return response;
    },
    postSecondRequest: async () => {
        const response = await api.post(`/mpProj/atualizarTela1`);
        return response;
    },
    postThirdRequest: async () => {
        const response = await api.post(`/mpProj/atualizarPed`);
        return response;
    },
    getAllPcpProducts: async () => {
        const response = await api.get(`/mpProj/All`);
        return response;
    },
    getOnePcpProduct: async (cod) => {
        const response = await api.get(`/mpProj/pedido?COM_IN_CODIGO=${cod}`);
        return response;
    },
    changeStatusPcpProduct: async (id, status) => {
        const response = await api.put(`/mpProj/updateStatus?id=${id}&status=${status}`);
        return response;
    },
    //obs: enviar payload sem body
    addNotePcpProduct: async (cod, ped, note) => {
        const response = await api.put(`/mpProj/updateObservacao?COM_IN_CODIGO=${cod}&PED_IN_CODIGO=${ped}`, note, {
            headers: {
                'Content-Type': 'text/plain'
            }
        });
        return response;
    },
    getPcdPcpProjects: async (project) => {
        const response = await api.get(`/mpProj/pdc?PED_IN_CODIGO=${project}`);
        return response;
    },
    getProjCost: async () => {
        const response = await api.get('/custos-proj');
        return response;
    },
    filterProjCost: async (initData, finalData) => {
        const response = await api.get(`/custos-proj/filtro?dtInicio=${initData}&dtFim=${finalData}`);
        return response;
    },
    getProjCostConsumition: async (initial, final) => {
        const response = await api.get(`/custos-proj/consumo?dtInicio=${initial}&dtFim=${final}`);
        return response;
    },
    getProjCostExpenses: async (initial, final) => {
        const response = await api.get(`/custos-proj/despesas?dtInicio=${initial}&dtFim=${final}`);
        return response;
    },
    getProjTaxes: async (initial, final) => {
        const response = await api.get(`/custos-proj/impostos?dtInicio=${initial}&dtFim=${final}`);
        return response;
    },
    getProjLabor: async (initial, final) => {
        const response = await api.get(`/custos-proj/mo?dtInicio=${initial}&dtFim=${final}`);
        return response;
    }, // excel
    getExcelSheet: async () => {
        const response = await api.get(`/api/excel/export`, {
            headers: {
                Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        });
        return response;
    },
    sendExcelSheet: async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        const response = await api.post(`/api/excel/import`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response;
    }, // observações persnalizadas
    createCustomObservation: async (payload) => {
        const response = await api.post(`/api/standard-notes`, payload);
        return response;
    },
    getCustomObservation: async () => {
        const response = await api.get(`/api/standard-notes`);
        return response;
    },
    updateCustomObservation: async (id, payload) => {
        const response = await api.put(`/api/standard-notes/${id}`, payload);
        return response;
    },
    deleteCustomObservation: async (id) => {
        const response = await api.delete(`/api/standard-notes/${id}`);
        return response;
    },
    createAssociations: async (payload) => {
        const response = await api.post(`/api/standard-note-associations`, payload);
        return response;
    },
    getObservationsByBudget: async (id) => {
        const response = await api.get(`/api/standard-note-associations/by-budget/${id}`);
        return response;
    },
    finishedBudget: async (id) => {
        const response = await api.put(`/api/v2/budget/client/${id}/finish`);
        return response;
    },
    deleteObservationsAssosiation: async (id) => {
        const response = await api.delete(`/api/standard-note-associations/by-budget/${id}`);
        return response;
    },
    // notas do quiz
    createQuizNotes: async (payload) => {
        const response = await api.post(`/api/noteForQuiz`, payload);
        return response;
    },
    getQuizNotesId: async (id) => {
        const response = await api.get(`/api/noteForQuiz/quiz/${id}`);
        return response;
    },
    editQuizNotesId: async (id, payload) => {
        const response = await api.put(`/api/noteForQuiz/${id}`, payload);
        return response;
    },
    duplicateBudget: async (id, payload, idFather) => {
        const response = await api.post(`/api/duplicate/${id}?idBudgetFather=${idFather}`, payload);
        return response;
    }, // relatorio de R.objectFit
    getReportRo: async (id) => {
        const response = await api.get(`/api/v2/ro/combined/${id}`);
        return response;
    },
    changeEnabledGroup: async (id, isEnabled) => {
        const response = await api.put(`/api/groups/${id}/finish?finished=${isEnabled}`);
        return response;
    }
});
