import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PrintIcon from '@mui/icons-material/Print';
import { IconDownload } from '@tabler/icons';
import { pdf } from '@react-pdf/renderer';
import { formatarData } from 'utils/regex';
import GeneretePdf from 'views/CreateNewBudget/components/GeneretePdf';
import { Button } from '@mui/material';

export function SpeedDialComponent({ setOpen }) {
    const actions = [
        // { icon: <IconDownload />, name: 'Download', click: handleDownload },
        { icon: <PrintIcon />, name: 'Print', click: handlePrintScreen }
    ];

    function handlePrintScreen() {
        setOpen(false);
        setTimeout(() => {
            window.print();
        }, 100);

        setTimeout(() => {
            setOpen(true);
        }, 4000);
    }

    return (
        <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }}>
            <Button variant="contained" onClick={handlePrintScreen}>
                <PrintIcon />
            </Button>
        </Box>
    );
}
