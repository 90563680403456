import {
    Autocomplete,
    Button,
    Checkbox,
    Divider,
    Fab,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField
} from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import Box from '@mui/material/Box';
import { useMemo, useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SnackBar from 'ui-component/SnakBar';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import SearchIcon from '@mui/icons-material/Search';
import NewFormulaModal from '../newFormulaModal';
import { toast } from 'react-toastify';
import { useFormula } from 'hooks/useFormula';
import ConfirmModal from 'ui-component/ConfirmModal';
import { useNavigate } from 'react-router';

const style = {
    width: '100%',
    overflowY: 'auto'
};

export default function Formula() {
    const navigate = useNavigate();

    const { question, currentIdContext, selectedList, currentQuestionContext, quizIdContext } = useFormula();

    const api = useApi();
    const [currentBodyFormula, setCurrentBodyFormula] = useState([]);
    console.log('🚀 ~ Formula ~ currentBodyFormula:', currentBodyFormula);

    const [allFormula, setAllFormula] = useState([]);
    const [allAnsweriftrue, setAllAnsweriftrue] = useState([]);
    const [currentItemSelected, setCurrentItemSelected] = useState([]);

    const getBodyFormulaV2 = async (id) => {
        try {
            const { data } = await api.getBodyFormulav2(id, quizIdContext);
            console.log('🚀 ~ getBodyFormulaV2 ~ data:', data);

            setCurrentBodyFormula(data);
            setAllFormula(data);
            setAllAnsweriftrue(data.map((item) => item.answersIfTrue));
            console.log('🚀 ~ getBodyFormulaV2 ~ data:', data);
        } catch (error) {
            console.log('🚀 ~ getBodyFormulaV2 ~ error:', error);
        }
    };

    useEffect(() => {
        getBodyFormulaV2(currentQuestionContext.id);
    }, [currentQuestionContext, quizIdContext]);

    // useEffect(() => {
    //     getCurrentBodyFormula();
    // }, []);

    const initialState = {
        pergunta: '',
        resposta: '',
        op: ''
    };

    const [newAnswer, setNewAnswer] = useState(currentQuestionContext);

    useEffect(() => {
        const newAnswer = currentQuestionContext?.answers;

        setNewAnswer((prevState) => ({
            ...prevState,
            bodyFormula: prevState?.bodyFormula?.map((item) => ({
                ...item,
                newAnswer
            }))
        }));
    }, []);

    useEffect(() => {
        console.log('newAnswer atual', newAnswer);
    }, [newAnswer]);

    const [fields, setFields] = useState([initialState]);

    const [currentAnswers, setCurrentAnswers] = useState([]);

    const [selectAll, setSelectAll] = useState(false);
    const [answer, setAnswer] = useState(currentAnswers);

    const selectedOptions = useMemo(() => {
        const selectedIndex = selectedList.findIndex((item) => item.id === currentIdContext);
        return selectedIndex !== -1 ? selectedList.slice(0, selectedIndex) : [];
    }, [currentIdContext, selectedList]);

    const handleSelectChange = (value, index, name, indexgeral) => {
        setAllFormula((prevFields) => {
            const updatedFields = [...prevFields];
            const groupToUpdate = updatedFields[indexgeral].formulas;
            console.log('🚀 ~ setAllFormula ~ groupToUpdate:', groupToUpdate);

            const fieldToUpdate = groupToUpdate[index];
            console.log('🚀 ~ setAllFormula ~ fieldToUpdate:', fieldToUpdate);

            if (name === 'pergunta') {
                fieldToUpdate.question.id = value;
            }

            if (name === 'resposta') {
                fieldToUpdate.answer.id = value.id;
                fieldToUpdate.answer.description = value.description;
            }

            if (name === 'type') {
                fieldToUpdate.type = value === 1 ? 'or' : 'and';
            }

            return updatedFields;
        });

        const newAnswers = combinedQuestionsAndAnswers.filter((item) => item.question === value);
        if (newAnswers.length > 0) {
            const answers = newAnswers.map((item) => item.answer);
            setAnswer(answers);
        }
    };
    const [combinedQuestionsAndAnswers, setCombinedQuestionsAndAnswers] = useState([]);

    const [allAnswers, setAllAnswers] = useState([]);

    const getCurrentSelectAnwser = async () => {
        try {
            const promises = selectedOptions.map(async (item) => {
                const { data } = await api.getQuestionAndAnswers(item.id);

                const answer = data.answer;

                return {
                    question: item.id,
                    answer: answer
                };
            });

            const results = await Promise.all(promises);

            setCombinedQuestionsAndAnswers(results);

            const extractedAnswers = results.reduce((acc, result) => {
                return acc.concat(result.answer);
            }, []);

            setAllAnswers(extractedAnswers);
        } catch (error) {
            console.error('Ocorreu um erro ao obter as respostas:', error);
        }
    };

    useEffect(() => {
        getCurrentSelectAnwser();
    }, []);

    const operations = [
        { id: 1, op: 'or', symbol: 'Ou' },
        { id: 2, op: 'end', symbol: 'E' }
    ];

    const addNewField = (index) => {
        const newArray = [...allFormula[index].formulas];

        // Adicione o novo objeto ao array
        newArray.push({
            answer: {
                id: ''
            },
            question: {
                id: ''
            },
            type: ''
        });

        setAllFormula((prevFormula) => {
            console.log('🚀 ~ setAllFormula ~ prevFormula:', prevFormula);
            // Crie uma cópia do array socorroDeus para evitar mutação direta
            const newFormula = [...prevFormula];

            // Atualize o array no índice específico com o novo array
            newFormula[index].formulas = newArray;

            return newFormula;
        });
    };

    //
    //
    //  respostas
    //
    //

    const [sortOrder, setSortOrder] = useState('desc');
    const [currentAnswerQuestion, setCurrentAnswerQuestion] = useState([]);
    const [answerQuestion, setAnswerQuestion] = useState(currentAnswerQuestion);
    const [selectedItems, setSelectedItems] = useState([]);

    const handleSortOrderChange = () => {
        const sortedAnswers = [...currentAnswerQuestion].sort((a, b) => {
            if (sortOrder === 'desc') {
                return b.id - a.id;
            } else {
                return a.id - b.id;
            }
        });
        setCurrentAnswerQuestion(sortedAnswers);
        setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
    };

    const getCurrentAnwser = async (currentIdContext) => {
        try {
            const response = await api.getQuestionAndAnswers(currentIdContext);
            setCurrentAnswerQuestion(response.data.answer);
        } catch (error) {
            console.error('Ocorreu um erro ao obter as respostas:', error);
        }
    };

    useEffect(() => {
        const updatedSelectedFullItems = currentAnswers.filter((item) => selectedItems.includes(item.id));
        setSelectedFullItems(updatedSelectedFullItems);
    }, []);

    //effect do item selecionado na primeira pagina
    useEffect(() => {
        getCurrentAnwser(currentIdContext);
    }, []);

    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(0);

    const [selectedFullItems, setSelectedFullItems] = useState([]);
    //tras os itens selecionados

    const [nameSelected, setNameSelected] = useState('');

    //seleciona 1 checkbox
    const handleSelectionChange = (event, id, index) => {
        if (event.target.checked) {
            setAllAnsweriftrue((prev) => {
                const updateAllAnswerIfTrue = [...prev];
                if (!updateAllAnswerIfTrue[index]) {
                    updateAllAnswerIfTrue[index] = [];
                }
                updateAllAnswerIfTrue[index].push({ id: id });
                return updateAllAnswerIfTrue;
            });
        } else {
            setAllAnsweriftrue((prev) => {
                const updateAllAnswerIfTrue = [...prev];
                if (updateAllAnswerIfTrue[index]) {
                    updateAllAnswerIfTrue[index] = updateAllAnswerIfTrue[index].filter((item) => item.id !== id);
                }
                return updateAllAnswerIfTrue;
            });
        }
    };
    //seleciona todos os checkbox
    const handleSelectAllChange = (event, index) => {
        setSelectAll(event.target.checked);
        setAllAnsweriftrue((prevAwnserIfTrue) => {
            if (event.target.checked) {
                const allIds = currentAnswerQuestion.map((row) => ({ id: row.id }));
                const updateAllAnswerIfTrue = [...prevAwnserIfTrue];
                updateAllAnswerIfTrue[index] = allIds;
                return updateAllAnswerIfTrue;
            } else {
                const updateAllAnswerIfTrue = [...prevAwnserIfTrue];
                updateAllAnswerIfTrue[index] = [];
                return updateAllAnswerIfTrue;
            }
        });
    };

    //
    //
    //requisições e clean
    //
    //
    const [requestFormulas, setRequestFormulas] = useState([]);
    const [formulaAnswers, setformulaAnswers] = useState([]);

    const savingFormula = async (index, answerifTrueParameter, formula) => {
        console.log(formula[index]);
        const mappedFields = formula[index].formulas.map((field) => ({
            question: { id: field.question.id },
            answer: { id: field.answer.id },
            type: field.type
        }));

        const updatedItem = {
            answersIfTrue: answerifTrueParameter[index].map((item) => ({ id: item.id })),
            formulas: mappedFields
        };

        const payload = {
            formulas: updatedItem.formulas,
            answersIfTrue: updatedItem.answersIfTrue
        };

        try {
            const { data } = await api.updateBodyFormula(currentBodyFormula[index].id, payload);
            toast.success('Formula salva com sucesso!');
            console.log('response', data);
        } catch (error) {
            console.log('error savingFormula', error.message);
        }
    };

    //
    // Impedir reload
    //

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = '';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    //
    //deletar formula
    //

    const [restriction, setRestriction] = useState([]);
    const getCurrentAnswer = async () => {
        try {
            const { data } = await api.getQuestionAndAnswers(currentQuestionContext.id);

            setRestriction(data.answers);
        } catch (error) {
            console.log('🚀 ~ file: index.js:363 ~ getCurrentAnswer ~ error:', error);
        }
    };

    useEffect(() => {
        getCurrentAnswer();
    }, [newAnswer]);

    const deletFormula = async () => {
        const toastId = toast.loading('Deletando restrição...');
        try {
            await api.deleteBodyFormula(currentItemSelected.id);
            getBodyFormulaV2(currentQuestionContext.id);
            toast.update(toastId, { render: 'Restrição removida com sucesso', type: 'success', isLoading: false, autoClose: 3000 });
        } catch (error) {
            console.log(error.message);
            toast.update(toastId, { render: 'Erro ao deletar restrição', type: 'error', isLoading: false, autoClose: 3000 });
        }
    };

    // remove field

    const removeField = (indexGeral, index) => {
        setAllFormula((prevCampos) => {
            // Create a deep copy of the nested array
            const novosCampos = prevCampos?.map((campo, i) => (i === indexGeral ? [...campo] : campo));

            novosCampos[indexGeral].splice(index, 1);
            toast.success('Formula removida com sucesso');

            return novosCampos;
        });
    };

    const [opeConfirm, setOpenConfirm] = useState(false);

    return (
        <>
            <ConfirmModal
                open={opeConfirm}
                setOpen={setOpenConfirm}
                firstMessage={'Deseja mesmo deletar a restrição ?'}
                secondMessage={'Os dados serão deletados permanentemente'}
                button1={'Voltar'}
                button2={'Deletar'}
                func={deletFormula}
            />
            <Fab
                onClick={() => navigate(-1)}
                variant="extended"
                sx={{ position: 'fixed', bottom: '50px', display: 'flex', alignItems: 'center', gap: '5px' }}
            >
                <ReplyIcon />
                Questionário
            </Fab>
            {currentBodyFormula?.map((item, index) => (
                <Box sx={style} key={index}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3>Pergunta atual:</h3>
                        <p style={{ marginLeft: '10px' }}>{question}</p>
                    </div>

                    {item.formulas?.map((field, indexField) => (
                        <Box key={indexField} style={{ display: 'flex', justifyContent: 'center' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    width: '100%',
                                    justifyContent: 'center',
                                    maxWidth: '900px'
                                }}
                            >
                                <h4 style={{ fontWeight: 'normal' }}>Selecione uma pergunta anterior</h4>
                                <Box>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <h5 style={{ fontWeight: 'normal' }}>Se pergunta: </h5>
                                        <div>
                                            <FormControl sx={{ m: 1, width: '300px' }}>
                                                <InputLabel id="perguntas-anteriores-label">Perguntas Anteriores</InputLabel>
                                                <Select
                                                    labelId="perguntas-anteriores-label"
                                                    id="perguntas-anteriores-select"
                                                    autoWidth
                                                    label="Perguntas Anteriores"
                                                    value={field.question.id || ''}
                                                    onChange={(event) => {
                                                        handleSelectChange(event.target.value, indexField, 'pergunta', index);
                                                    }}
                                                >
                                                    {selectedOptions.map((item) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {`Id-${item.id}  ${item.description}`}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <h1 style={{ fontWeight: 'normal' }}>=</h1>

                                        <div>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={answer.length > 0 ? answer[0].map((item) => item) : []}
                                                sx={{ width: 300, marginLeft: '10px', marginRight: '10px' }}
                                                value={field.answer.description || ''}
                                                onChange={(event, value) => {
                                                    handleSelectChange(value, indexField, 'resposta', index);
                                                }}
                                                getOptionLabel={(option) => option} // Exibe o ID e a descrição no campo
                                                renderOption={(props, option) => (
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                        ID - {option.id} {option.description}
                                                    </Box>
                                                )}
                                                renderInput={(params) => <TextField {...params} label="Respostas" />}
                                                clearOnEscape={false}
                                                // Add the clearIcon prop to remove the X icon
                                                clearIcon={null}
                                            />
                                        </div>
                                        <div>
                                            <FormControl sx={{ width: '100px' }}>
                                                <InputLabel id="operacao">Operação</InputLabel>
                                                <Select
                                                    labelId="operacao"
                                                    id="operacao"
                                                    autoWidth
                                                    label="Operação"
                                                    value={(field.type === 'or' && 1) || (field.type === 'and' && 2)}
                                                    onChange={(event) => {
                                                        handleSelectChange(event.target.value, indexField, 'type', index);
                                                    }}
                                                >
                                                    {operations.map((item) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.symbol}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <IconButton
                                                size="large"
                                                sx={{
                                                    marginLeft: '20px',
                                                    '&:hover': {
                                                        backgroundColor: '#ff6961'
                                                    }
                                                }}
                                                onClick={() => removeField(index, indexField)}
                                            >
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <div>
                                        <Button variant="contained" onClick={() => addNewField(index)}>
                                            <AddIcon />
                                        </Button>
                                    </div>
                                </Box>
                            </div>
                        </Box>
                    ))}
                    <>
                        {restriction && (
                            <TableContainer component={Paper} sx={{ maxHeight: '500px', marginBottom: '20px', background: '#e7f4f4' }}>
                                <Table sx={{ width: 650, margin: '0 auto' }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <TableSortLabel onClick={handleSortOrderChange}>
                                                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>Id</div>
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell align="left">Respostas</TableCell>
                                            <TableCell align="center" sx={{ width: '200px' }}>
                                                Selecionar todos
                                                <Checkbox
                                                    onChange={(e) => handleSelectAllChange(e, index)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {restriction?.map((row) => (
                                            <TableRow key={row.description} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    {row.id}
                                                </TableCell>
                                                <TableCell align="left">{row.description}</TableCell>
                                                <TableCell align="center">
                                                    <Checkbox
                                                        key={row.id}
                                                        checked={
                                                            Array.isArray(allAnsweriftrue[index]) &&
                                                            allAnsweriftrue[index].some((item) => item.id === row.id)
                                                        }
                                                        onChange={(event) => handleSelectionChange(event, row.id, index)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            style={{ marginLeft: '20px', marginBottom: '20px', width: '300px' }}
                            onClick={() => savingFormula(index, allAnsweriftrue, allFormula, restriction[index])}
                        >
                            Salvar restrição
                        </Button>
                        <Button
                            variant="contained"
                            style={{ marginLeft: '20px', marginBottom: '20px', width: '300px' }}
                            onClick={() => {
                                setOpenConfirm(true);
                                setCurrentItemSelected(item);
                            }}
                        >
                            Deletar restrição
                        </Button>
                    </div>
                    <Divider />
                </Box>
            ))}
        </>
    );
}
