import { Box } from '@mui/system';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import {
    Button,
    Divider,
    Drawer,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    TextField,
    Autocomplete
} from '@mui/material';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import axios from 'axios';
import Logo from '../../../assets/jgf-logo.png';
import InputMask from 'react-input-mask';

const DrawerInsert = ({ open, setOpen }) => {
    const navigate = useNavigate();
    const api = useApi();
    const userInfo = localStorage.getItem('userInfo');
    const userInfoObject = JSON.parse(userInfo);
    //stats
    const [paymentTerms, setPaymentTerms] = useState([]);

    const getPaymentTerms = async () => {
        try {
            const res = await api.getPaymentsTerms();
            setPaymentTerms(res?.data);
        } catch (error) {
            console.log('🚀 ~ file: index.js:81 ~ getPaymentTerms ~ error:', error.message);
        }
    };

    useEffect(() => {
        getPaymentTerms();
    }, []);

    // funcao para enviar os dados do usuario para a simulação
    const handleData = async (values) => {
        const toastId = toast.loading('Cadastrando orçamento...');
        try {
            const data = {
                comission: '0',
                delivery: '0',
                observation: values?.description?.toUpperCase(),
                packaging: 0,
                construction: 'Não informado',
                manualClient: values.manualClient?.toUpperCase(),
                vehicle: values.vehicle?.toUpperCase(),
                color: values.color?.toUpperCase(),
                plate: values.plate?.toUpperCase(),
                cellPhone: values.cellPhone?.toUpperCase(),
                cep: '',
                addressNumber: '',
                city: '',
                state: '',
                neighborhood: '',
                deliveryDate: ''
            };
            const res = await api.insertBudgetClient(values.paymentTerms, data);
            navigate(`/main/novo-orcamento/${res.data.id}/0`);
            toast.update(toastId, {
                render: 'Orçamento cadastrado com sucesso!',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
        } catch (error) {
            console.log('🚀 ~ file: DrawerInsert.js:86 ~ handleData ~ error:', error.message);
            toast.update(toastId, {
                render: 'Erro ao cadastrar orçamento!',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    };

    const [cep, setCep] = useState('');
    const [cepData, setCepData] = useState({});

    async function searchCep() {
        try {
            const res = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

            console.log('🚀 ~ searchCep ~ res:', res.data);
            setCepData(res?.data);
        } catch (error) {
            console.log('🚀 ~ searchCep ~ error:', error);
        }
    }

    useEffect(() => {
        if (cep.length === 8) {
            searchCep();
        }
    }, [cep]);

    useEffect(() => {
        setCepData({});
    }, []);

    return (
        <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
            <Formik
                initialValues={{
                    costumer: '',
                    seller: '',
                    representative: '',
                    commission: '',
                    freight: '',
                    paymentTerms: '',
                    description: '',
                    packaging: '',
                    construction: '',
                    manualClient: '',
                    vehicle: '',
                    color: '',
                    plate: '',
                    cellPhone: '',
                    addressNumber: '',
                    deliveryDate: ''
                }}
                validationSchema={Yup.object().shape({
                    paymentTerms: Yup.string().max(255).required('Obrigatório'),
                    manualClient: Yup.string().max(255).required('Obrigatório'),
                    vehicle: Yup.string().max(255).required('Obrigatório')
                })}
                onSubmit={async (values) => {
                    handleData(values);
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => (
                    <form
                        onSubmit={handleSubmit}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: '100%',
                            gap: '1rem',
                            padding: '1.25rem',
                            position: 'relative'
                        }}
                    >
                        <h2 style={{ textAlign: 'center' }}>Iniciar orçamento</h2>
                        <Divider />
                        {/* <img
                            src={Logo}
                            alt="logo"
                            style={{
                                width: '12.5rem',
                                height: 'auto',
                                position: 'absolute',
                                top: '12.5rem',
                                right: '13.125rem'
                            }}
                        /> */}
                        <div>
                            <FormControl fullWidth error={Boolean(touched.manualClient && errors.manualClient)}>
                                <TextField
                                    label="Cliente *"
                                    onChange={(e) => {
                                        handleChange({
                                            target: {
                                                name: 'manualClient',
                                                value: e.target.value
                                            }
                                        });
                                    }}
                                    variant="outlined"
                                    error={Boolean(touched.manualClient && errors.manualClient)}
                                    helperText={touched.manualClient && errors.manualClient}
                                />
                            </FormControl>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '20px' }}>
                            <div>
                                <FormControl fullWidth error={Boolean(touched.vehicle && errors.vehicle)}>
                                    <TextField
                                        label="Veiculo *"
                                        onChange={(e) => {
                                            handleChange({
                                                target: {
                                                    name: 'vehicle',
                                                    value: e.target.value
                                                }
                                            });
                                        }}
                                        variant="outlined"
                                        error={Boolean(touched.vehicle && errors.vehicle)}
                                        helperText={touched.vehicle && errors.vehicle}
                                    />
                                </FormControl>
                            </div>

                            <div>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-plate">Placa</InputLabel>
                                    <InputMask
                                        mask="***-****" // máscara para a placa
                                        value={values.plate}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            handleChange({
                                                target: {
                                                    name: 'plate',
                                                    value: e.target.value
                                                }
                                            });
                                        }}
                                    >
                                        {(inputProps) => (
                                            <OutlinedInput
                                                id="outlined-adornment-plate"
                                                type="text"
                                                name="plate"
                                                label="Placa" // Corrigido para "Placa"
                                                inputProps={inputProps}
                                            />
                                        )}
                                    </InputMask>
                                </FormControl>
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '.625rem' }}>
                            <div>
                                <TextField
                                    label="Cor"
                                    name="color"
                                    onChange={(e) => {
                                        handleChange({
                                            target: {
                                                name: 'color',
                                                value: e.target.value
                                            }
                                        });
                                    }}
                                />
                            </div>
                            <div>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-phone-register">Celular</InputLabel>
                                    <InputMask
                                        mask="(99) 99999-9999"
                                        value={values.cellPhone}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            handleChange({
                                                target: {
                                                    name: 'cellPhone',
                                                    value: e.target.value
                                                }
                                            });
                                        }}
                                    >
                                        {(inputProps) => (
                                            <OutlinedInput
                                                id="outlined-adornment-phone-register"
                                                type="text"
                                                name="cellPhone"
                                                label="Celular"
                                                inputProps={inputProps}
                                            />
                                        )}
                                    </InputMask>
                                </FormControl>
                            </div>
                        </div>
                        {/* <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: '.625rem'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '.9375rem',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}
                            >
                                <TextField label="CEP" value={cep} onChange={(e) => setCep(e.target.value)} />
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    label="Bairro"
                                    value={cepData.bairro}
                                    onChange={(e) => setCepData({ ...cepData, bairro: e.target.value })}
                                />
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    label="Cidade"
                                    value={cepData.localidade}
                                    onChange={(e) => setCepData({ ...cepData, localidade: e.target.value })}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '.625rem' }}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    label="Rua"
                                    value={cepData.logradouro}
                                    sx={{ width: '12.5rem' }}
                                    onChange={(e) => setCepData({ ...cepData, logradouro: e.target.value })}
                                />
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    label="Estado"
                                    value={cepData.uf}
                                    onChange={(e) => setCepData({ ...cepData, uf: e.target.value })}
                                />
                                <TextField
                                    label="Nº"
                                    name="addressNumber"
                                    onChange={(e) => {
                                        handleChange({
                                            target: {
                                                name: 'addressNumber',
                                                value: e.target.value
                                            }
                                        });
                                    }}
                                />
                            </Box>

                        </div> */}
                        <FormControl fullWidth error={Boolean(touched.paymentTerms && errors.paymentTerms)}>
                            <Autocomplete
                                id="paymentTerms"
                                name="paymentTerms"
                                options={paymentTerms}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, newValue) => {
                                    handleChange({
                                        target: {
                                            name: 'paymentTerms',
                                            value: newValue ? newValue.id : ''
                                        }
                                    });
                                }}
                                sx={{ width: '18.75rem' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Cond. Pagamentos *"
                                        variant="outlined"
                                        error={Boolean(touched.paymentTerms && errors.paymentTerms)}
                                        helperText={touched.paymentTerms && errors.paymentTerms}
                                    />
                                )}
                            />
                        </FormControl>

                        <div>
                            <TextField
                                sx={{ width: '100%' }}
                                id="observations"
                                label="Observaçães do orçamento"
                                multiline
                                rows={4}
                                value={values.description}
                                onChange={handleChange}
                                name="description"
                            />
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '3rem' }}>
                            <Button type="submit" sx={{ width: '18.75rem' }} variant="contained">
                                Cadastrar
                            </Button>
                        </div>
                    </form>
                )}
            </Formik>
        </Drawer>
    );
};

export default DrawerInsert;
