import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Switch,
    TextField
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import ActionButtons from './components/ActionButtons';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import BudgetFormation from './components/BudgetFormation';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import ConfirmModal from 'ui-component/ConfirmModal';
import { formatarData, formatarValor } from 'utils/regex';
import SkeletonTable from 'ui-component/skeletonTable';

import GeneretePdf from './components/GeneretePdf';
import { formatarValorIntl } from 'utils/formatValueIntl';
import GeneretePrevia from './components/GeneretePrevia';
import TransferList from './components/TransferList';

import ReactDOM from 'react-dom';
import { useBudgetPdf } from 'store/BudgetPdfContext';

const CreateNewBudget = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '100%',
        boxShadow: 24
    };

    const [openModalPdf, setOpenModalPdf] = useState(false);
    const [openModalPrevia, setOpenModalPrevia] = useState(false);
    const { setCurrentDataContext, setSumContext, setObservationArrayContext, setCurrentQuestionsContext } = useBudgetPdf();
    const { id, copyId } = useParams();
    const api = useApi();
    const navigate = useNavigate();
    //stats
    const [costumer, setCostumer] = useState([]);
    const [seller, setSeller] = useState([]);
    const [representative, setRepresentative] = useState([]);
    const [representativeSelected, setRepresentativeSelected] = useState([]);
    const [paymentTerms, setpaymentTerms] = useState([]);
    const [freightState, setFreightState] = useState([]);
    const [openBudgetFormation, setOpenBudgetFormation] = useState(false);
    const [formData, setFormData] = useState(null);
    const [getIdRepresentative, setGetIdRepresentative] = useState('');
    const [rows, setRows] = useState([]);
    // const [editBudget, setEditBudget] = useState();
    const [sum, setSum] = useState('');
    const [loading, setLoading] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [observationArray, setObservationArray] = useState([]);

    const todasQuestoes = localStorage.getItem('questions');
    const role = localStorage.getItem('role');
    const roleParse = JSON.parse(role);
    const currentQuestion = JSON.parse(todasQuestoes);

    const [openDialog, setOpenDialog] = useState(false);

    // informações da tabela
    const [currentQuizId, setCurrentQuizId] = useState('');

    const columns = [
        { field: 'desc', headerName: 'Descriçao', flex: 1 },
        { field: 'un', headerName: 'UN', flex: 1 },
        // {
        //     field: 'qnt',
        //     headerName: 'QTD',
        //     flex: 1
        // },
        {
            field: 'valorU',
            headerName: 'Valor Unitário',
            sortable: false,
            sortable: false,
            flex: 1,
            renderCell: (params) => ` ${formatarValorIntl(params.value)}`
        },
        {
            field: 'valorT',
            headerName: 'Valor Total',
            sortable: false,
            sortable: false,
            flex: 1,
            renderCell: (params) => ` ${formatarValorIntl(params.value)}`
        },

        {
            field: 'acao',
            headerName: 'Ações',
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                setCurrentQuizId(params.row.quizId);

                return (
                    <ActionButtons
                        row={params.row}
                        formData={formData}
                        getBudget={getBudget}
                        // currentIcms={currentIcms}
                        currentQuizId={currentQuizId}
                        setCurrentQuizId={setCurrentQuizId}
                    />
                );
            }
        }
    ];

    // get's

    const getPaymentTerms = async () => {
        try {
            const res = await api.getPaymentsTerms();
            setpaymentTerms(res.data);
        } catch (error) {
            console.log('🚀 ~ file: index.js:81 ~ getPaymentTerms ~ error:', error.message);
        }
    };

    const [currentMaterials, setCurrentMaterials] = useState([]);

    const getBudget = async () => {
        setLoading(true);
        try {
            const res = await api.getBudgetsOneV2(id);
            setFormData(res.data);
            setLoading(false);
            setRows(
                res?.data?.budgets?.map((item, index) => ({
                    ...item,
                    id: index + 1,
                    desc: item?.budget_Name,
                    un: item?.unitSize,
                    qnt: item?.quantity,
                    valorU: item?.unitPrice,
                    valorT: item?.totalPrice,
                    codgo: item?.id,
                    note: item?.note,
                    quizId: item?.quizId
                }))
            );
            const filterService = res?.data?.budgets.filter((item) => item.service === false);

            const totalMaterials = filterService?.flatMap((item) => item?.materials) || [];

            const materialGroups = totalMaterials.reduce((acc, material) => {
                const { name, quantity } = material;
                if (!acc[name]) {
                    acc[name] = { ...material };
                } else {
                    acc[name].quantity += quantity;
                }
                return acc;
            }, {});

            const unifiedMaterials = Object.values(materialGroups);

            setCurrentMaterials(unifiedMaterials);
        } catch (error) {
            console.log('🚀 ~ file: index.js:106 ~ getBudget ~ error:', error);
        }
    };

    const handleSum = () => {
        const soma = rows.reduce((total, { totalPrice }) => total + totalPrice, 0);

        setSum(soma);
    };

    useEffect(() => {
        if (rows) handleSum();
    }, [rows]);

    async function handleGetObservationById() {
        try {
            const { data } = await api.getObservationsByBudget(id);

            setObservationArray(data);
        } catch (error) {
            console.log('🚀 ~ handleGetObservationById ~ error:', error);
        }
    }

    useEffect(() => {
        getPaymentTerms();
        getBudget();
        handleGetObservationById();
    }, []);

    async function handleDuplicateBudget() {
        const toastId = toast.loading('Gerando nova revisão...');
        const payload = rows.map((item) => Number(item.codgo));

        try {
            await api.duplicateBudget(id, payload, formData.reviewOf !== 0 ? formData.reviewOf : formData.id);
            toast.update(toastId, {
                render: 'Revisão gerada com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
        } catch (error) {
            console.log('🚀 ~ handleDuplicateBudget ~ error:', error);
            toast.update(toastId, {
                render: 'Erro ao gerar revisão',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    async function handleFinishedBudget() {
        const toastId = toast.loading('Finalizando orçamento...');
        try {
            await api.finishedBudget(id);
            toast.update(toastId, {
                render: 'Orçamento finalizado com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            getBudget();
            setOpenDialog(false);
        } catch (error) {
            console.log('🚀 ~ handleFinishedBudget ~ error:', error);
            toast.update(toastId, {
                render: 'Erro ao finalizar orçamento',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    // funcao para enviar os dados do usuario para a simulação
    const handleData = async (values) => {
        console.log('chamou aqui');
        const query = {
            costumer: values.costumer ? values.costumer : formData?.client?.id,
            seller: values.seller ? values.seller : formData?.seller?.id,
            representative: values.representative ? values.representative : formData?.agent.id,
            paymentTerms: values.paymentTerms ? values.paymentTerms : formData?.paymentCondition?.id,
            packaging: values.packaging ? Number(values.packaging) : Number(formData?.packaging),
            icmsPercentage: values.icmsPercentage ? values.icmsPercentage : formData?.icmsPercentage
        };

        const data = {
            comission: values.commission ? Number(values.commission) : Number(formData?.comission),
            delivery: values.freight ? Number(values.freight) : Number(formData?.delivery),
            observation: values.description ? values.description : formData?.observation,
            createdAt: formData?.createdAt,
            packaging: values.packaging ? values.packaging : formData?.packaging,
            icmsPercentage: values.icmsPercentage ? values.icmsPercentage : formData?.icmsPercentage,
            construction: values.construction ? values.construction : formData?.construction,
            cif_fob: values?.cif_fob,
            deliveryTime: values.deliveryTime ? Number(values.deliveryTime) : Number(formData?.deliveryTime),
            manualClient: values.manualClient,
            vehicle: values.vehicle,
            color: values.color,
            plate: values.plate,
            cellPhone: values.cellPhone,
            addressNumber: formData.addressNumber,
            city: formData.localidade,
            state: formData.uf,
            neighborhood: values.address,
            deliveryDate: values.deliveryDate,
            emissionDate: values.emissionDate
        };

        try {
            await api.editBudgetClient(
                formData?.id,
                query?.costumer,
                query?.seller,
                query?.representative,
                query?.paymentTerms,
                query?.packaging,
                query?.icmsPercentage,
                data
            );
            getBudget();
            toast.success('Dados atualizados');
            setIsChanged(false);
        } catch (error) {
            console.log('🚀 ~ file: DrawerInsert.js:86 ~ handleData ~ error:', error.mesage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <MainCard title={`Orçamento - ${copyId != 0 ? copyId : id}`}>
            <BudgetFormation open={openBudgetFormation} setOpen={setOpenBudgetFormation} formData={formData} getBudget={getBudget} />

            <Grid container spacing={gridSpacing}>
                {formData !== null && (
                    <Formik
                        initialValues={{
                            costumer: formData?.client?.id ? formData?.client?.id : '',
                            seller: formData?.seller?.id ? formData?.seller?.id : '',
                            representative: formData?.agent?.id ? formData?.agent?.id : '',
                            commission: '',
                            freight: '',
                            paymentTerms: '',
                            description: formData?.observation || '',
                            createdAt: formData?.createdAt || '',
                            packaging: formData?.packaging,
                            icmsPercentage: formData?.client?.icmsId,
                            construction: formData?.construction || '',
                            cif_fob: formData?.cifFob || false,
                            deliveryTime: formData?.deliveryTime || '',
                            manualClient: formData?.manualClient || '',
                            vehicle: formData?.vehicle || '',
                            plate: formData?.plate || '',
                            color: formData?.color || '',
                            cellPhone: formData?.cellPhone || '',
                            deliveryDate: formData?.deliveryDate || '',
                            address: formData?.neighborhood || '',
                            emissionDate: formData?.emissionDate || ''
                        }}
                        validationSchema={Yup.object().shape({
                            costumer: Yup.string().max(255).required('Este campo deve ser preenchido'),
                            representative: Yup.string().max(255).required('Este campo deve ser preenchido')
                        })}
                        onSubmit={async (values) => {
                            console.log(values, ' meu values');
                            await handleData(values);
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => (
                            <form
                                onSubmit={handleSubmit}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    width: '100%',
                                    gap: '16px',
                                    marginLeft: '20px'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '20px',
                                        marginTop: '15px',
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <div>
                                        <TextField
                                            label="Cliente"
                                            value={values?.manualClient}
                                            InputLabelProps={{ shrink: true }}
                                            name="manualClient"
                                            onChange={(e) => {
                                                setIsChanged(true);
                                                handleChange(e);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            label="Veiculo"
                                            value={values?.vehicle}
                                            InputLabelProps={{ shrink: true }}
                                            name="vehicle"
                                            onChange={(e) => {
                                                setIsChanged(true);
                                                handleChange(e);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            label="Data de emissão"
                                            value={values?.emissionDate}
                                            InputLabelProps={{ shrink: true }}
                                            name="emissionDate"
                                            type="date"
                                            onChange={(e) => {
                                                setIsChanged(true);
                                                handleChange(e);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            label="Data de entrega"
                                            type="date"
                                            value={values?.deliveryDate}
                                            name="deliveryDate"
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(e) => {
                                                setIsChanged(true);
                                                handleChange(e);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            label="Placa"
                                            value={values?.plate}
                                            InputLabelProps={{ shrink: true }}
                                            name="plate"
                                            onChange={(e) => {
                                                setIsChanged(true);
                                                handleChange(e);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            label="Cor"
                                            value={values.color}
                                            InputLabelProps={{ shrink: true }}
                                            name="color"
                                            onChange={(e) => {
                                                setIsChanged(true);
                                                handleChange(e);
                                            }} // Adicionar onChange
                                        />
                                    </div>

                                    <div>
                                        <TextField
                                            label="Telefone"
                                            value={values?.cellPhone}
                                            InputLabelProps={{ shrink: true }}
                                            name="cellPhone"
                                            onChange={(e) => {
                                                setIsChanged(true);
                                                handleChange(e);
                                            }}
                                        />
                                    </div>

                                    <div>
                                        <FormControl fullWidth error={Boolean(touched.paymentTerms && errors.paymentTerms)}>
                                            <InputLabel htmlFor="paymentTerms">Cond. Pagamentos</InputLabel>
                                            <Select
                                                labelId="paymentTerms"
                                                id="paymentTerms"
                                                name="paymentTerms"
                                                label="Cond. Pagamentos"
                                                value={values.paymentTerms ? values.paymentTerms : formData?.paymentCondition?.id}
                                                onChange={(e) => {
                                                    setIsChanged(true);
                                                    handleChange(e);
                                                }}
                                                sx={{ width: '300px' }}
                                                disabled={formData?.finished}
                                            >
                                                {paymentTerms.map((item) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {touched.paymentTerms && errors.paymentTerms && (
                                                <FormHelperText error id="standard-weight-helper-text--register">
                                                    {errors.paymentTerms}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </div>
                                    {/* <div>
                                        <TextField
                                            label="Endereço"
                                            value={values?.address}
                                            InputLabelProps={{ shrink: true }}
                                            name="address"
                                            sx={{ minWidth: '400px' }}
                                            onChange={(e) => {
                                                setIsChanged(true);
                                                handleChange(e);
                                            }}
                                        />
                                    </div> */}
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '20px',
                                            margin: '20px'
                                        }}
                                    >
                                        <div style={{ margin: 0, marginTop: 15, display: 'flex', gap: '10px', alignItems: 'center' }}>
                                            <TransferList
                                                budgetId={id}
                                                isFinished={formData?.finished}
                                                observationArray={observationArray}
                                                handleGetObservationById={handleGetObservationById}
                                            />
                                        </div>
                                        <Button
                                            variant="contained"
                                            disabled={formData?.finished}
                                            onClick={() => {
                                                handleData(values);
                                            }}
                                        >
                                            Atualizar
                                        </Button>
                                    </div>
                                </div>
                                <div>
                                    <TextField
                                        sx={{ width: '100%' }}
                                        id="observations"
                                        label="Observaçães do orçamento"
                                        multiline
                                        rows={2}
                                        value={values.description}
                                        onChange={(e) => {
                                            setIsChanged(true);
                                            handleChange(e);
                                        }}
                                        name="description"
                                        disabled={formData?.finished}
                                    />
                                </div>

                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: '15px',
                                        marginBottom: '15px'
                                    }}
                                >
                                    <Button
                                        disabled={formData?.finished}
                                        variant="contained"
                                        sx={{ width: '150px' }}
                                        onClick={() => setOpenBudgetFormation(true)}
                                    >
                                        Incluir item
                                    </Button>
                                    <Button
                                        sx={{ width: '200px' }}
                                        variant="contained"
                                        onClick={formData?.finished ? handleDuplicateBudget : () => setOpenDialog(true)}
                                    >
                                        {formData?.finished ? 'Gerar revisão' : 'Finalizar orçamento'}
                                    </Button>
                                </Box>
                                <Box sx={{ width: '100%' }}>
                                    {loading ? (
                                        <SkeletonTable />
                                    ) : (
                                        rows.length > 0 && (
                                            <DataGrid
                                                columns={columns}
                                                hideFooter={true}
                                                rows={rows}
                                                columnVisibilityModel={{
                                                    codigo: false
                                                }}
                                                getRowId={(row) => row.id}
                                            />
                                        )
                                    )}
                                </Box>
                                {rows.length > 0 && (
                                    <Box sx={{ display: 'flex', justifyContent: 'end', gap: '10px' }}>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                // setOpenModalPdf(true);
                                                setCurrentDataContext(formData);
                                                setSumContext(formatarValorIntl(sum));
                                                setObservationArrayContext(observationArray);
                                                setCurrentQuestionsContext(currentQuestion);
                                                navigate('/main/orcamento-vendas-pdf');
                                            }}
                                            disabled={isChanged}
                                        >
                                            {isChanged ? 'Atualize o orçamento' : 'Gerar PDF'}
                                        </Button>

                                        <TextField
                                            label="Soma Total"
                                            value={`${formatarValorIntl(sum)}`}
                                            aria-readonly
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Box>
                                )}
                            </form>
                        )}
                    </Formik>
                )}
                <Dialog
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <h3 style={{ textAlign: 'center' }}>Finalizar orçamento</h3>
                    <DialogContent>
                        <DialogContentText variant="h4" id="alert-dialog-description">
                            Deseja realmente finalizar o orçamento?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => setOpenDialog(false)} color="error">
                            Cancelar
                        </Button>
                        <Button variant="contained" onClick={handleFinishedBudget}>
                            Finalizar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </MainCard>
    );
};

export default CreateNewBudget;
